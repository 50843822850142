import { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { MaskLeft, MaskRight, MasterSceneWrapper } from './MasterScene.styles';
import { Cloud } from './elemets/WordCloud/WordCloud';
import { getTechList } from '../../utils/getTechList';
import { useData } from '../../contexts/DataContext';
import { ArenaMovingGrid } from './elemets/ArenaMovingGrid/ArenaMovingGrid';
import { SpatialRings } from './elemets/Rings/SpatialRings';
import ParticlesWithCarousel from './elemets/ParticlesCarousel/ParticlesWithCarousel';
import {
  zDepthOffset,
  PITCH_STRENGTH,
  PITCH_OFFSET,
  MIN_PITCH,
  MAX_PITCH,
  YAW_STRENGTH,
  YAW_OFFSET,
  MIN_YAW,
  MAX_YAW,
  scrollMultiplierConstant,
  CAMERA_OFFSET_X,
  HEIGHT_OFFSET,
  CAMERA_LERP_FACTOR,
} from './MasterScene.config';
import styled from 'styled-components';

const DraggableArea = styled.div`
  position: absolute;
  height: 100vh;
  width: 70vw;
  background-color: transparent;
  touch-action: none; /* Prevent default touch actions */
`;

const ScrollableArea = styled.div`
  position: absolute;
  height: 100vh;
  width: 30vw;
  right: 0;
  background-color: transparent;
`;

export interface MasterSceneRef {
  updateMousePosition: (mouseX: number, mouseY: number) => void;
  updateScrollPosition: (scrollHeight: number) => void;
}

export interface MasterSceneProps {
  totalSections: number;
  globalHeight: number;
  sectionHeight: number;
  sectionNames: string[];
}

const MasterScene = forwardRef<MasterSceneRef, MasterSceneProps>(({ totalSections, globalHeight, sectionNames }, ref) => {
  const { data: techStackData } = useData('techstack');
  const techList = getTechList(techStackData);

  const mouseXRef = useRef(0);
  const mouseYRef = useRef(0);
  const scrollHeightRef = useRef(0);

  useImperativeHandle(ref, () => ({
    updateMousePosition: (mouseX: number, mouseY: number) => {
      mouseXRef.current = mouseX;
      mouseYRef.current = mouseY;
    },
    updateScrollPosition: (scrollHeight: number) => {
      scrollHeightRef.current = scrollHeight;
    },
  }));

  const [cameraZShift, setCameraZShift] = useState(0);

  const zTotalShiftExperimental = 517.19;
  const zSingleSectionShiftExperimental = zTotalShiftExperimental / totalSections;

  const zDepthPositionArray = sectionNames.map((name, index) => {
    const start = index * zSingleSectionShiftExperimental;
    const middle = start + zSingleSectionShiftExperimental / 2;
    const end = (index + 1) * zSingleSectionShiftExperimental;
    return { start: start - zDepthOffset, middle: middle - zDepthOffset, end: end - zDepthOffset, name };
  });

  const ringsDistancingStrength = 0.7;
  const scrollProportionsForRings = (cameraZShift - zDepthPositionArray[0].end / zSingleSectionShiftExperimental / 100) * ringsDistancingStrength;

  const cameraProgressBetweenTwoSections = (section1: number, section2: number) => {
    const start = zDepthPositionArray[section1].start;
    const end = zDepthPositionArray[section2].middle;
    const progress = (cameraZShift - start) / (end - start);
    const mappedProgress = THREE.MathUtils.mapLinear(progress, 0, 1, 0, 1);
    return mappedProgress;
  };

  const introDepth = 1;
  const midPointDepth = 250;
  const outroDepth = 1000;

  const translateProgressToDepth = (preMidProgress: number, postMidProgress: number) => {
    if (preMidProgress < 1) {
      return THREE.MathUtils.mapLinear(preMidProgress, 0, 1, introDepth, midPointDepth);
    } else {
      return THREE.MathUtils.mapLinear(preMidProgress, 1, 2, midPointDepth, outroDepth);
    }
  };

  const depthForParticles = translateProgressToDepth(cameraProgressBetweenTwoSections(0, 1), cameraProgressBetweenTwoSections(1, 2));
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const CameraControls = () => {
    const { camera } = useThree();
    const targetPosition = useRef(new THREE.Vector3());
    const targetRotation = useRef(new THREE.Euler());

    useFrame(() => {
      const mouseX = mouseXRef.current;
      const mouseY = mouseYRef.current;
      const scrollHeight = scrollHeightRef.current;

      const normalizedX = (mouseX - window.innerWidth / 2) / (window.innerWidth / 2);
      const normalizedY = (mouseY - window.innerHeight / 2) / (window.innerHeight / 2);

      const calculatedPitch = THREE.MathUtils.clamp(normalizedY * PITCH_STRENGTH + PITCH_OFFSET, MIN_PITCH, MAX_PITCH);
      const calculatedYaw = THREE.MathUtils.clamp(-normalizedX * YAW_STRENGTH + YAW_OFFSET, MIN_YAW, MAX_YAW);

      const scrollProportion = (scrollHeight / globalHeight) * scrollMultiplierConstant * 1000;

      targetPosition.current.set(CAMERA_OFFSET_X, (window.innerHeight - mouseY) * 0.002 + HEIGHT_OFFSET, scrollProportion);
      targetRotation.current.set(THREE.MathUtils.degToRad(calculatedPitch), THREE.MathUtils.degToRad(calculatedYaw), 0);

      camera.position.lerp(targetPosition.current, CAMERA_LERP_FACTOR);
      camera.rotation.x = THREE.MathUtils.lerp(camera.rotation.x, targetRotation.current.x, CAMERA_LERP_FACTOR);
      camera.rotation.y = THREE.MathUtils.lerp(camera.rotation.y, targetRotation.current.y, CAMERA_LERP_FACTOR);

      setCameraZShift(camera.position.z);
    });

    return null;
  };

  const handleDrag = (e: React.TouchEvent) => {
    e.preventDefault();
  };

  return (
    <MasterSceneWrapper>
      <DraggableArea onTouchMove={handleDrag}>
        <MaskLeft>
          <MaskRight>
            <Canvas style={{ background: 'transparent' }}>
              <CameraControls />

              <group position={[0, 0, 1000]}>
                <ArenaMovingGrid />
              </group>

              <fog attach="fog" color="#000" near={1} far={100} />

              <group position={[0, 20, zDepthPositionArray[0].middle + 1]}>
                <SpatialRings
                  subPosition={isMobile ? [-5, 16, -50] : [-10, 8, -40]}
                  mousePosition={{ x: mouseXRef.current, y: mouseYRef.current }}
                  onMaxScrollReached={() => {}}
                  scrollProportionsForRings={scrollProportionsForRings}
                />
              </group>

              <group position={[0, 0, zDepthPositionArray[1].middle]}>
                <ParticlesWithCarousel mouseXRef={mouseXRef} mouseYRef={mouseYRef} depthForParticles={depthForParticles} />
              </group>

              <group position={[0, 22, zDepthPositionArray[6].middle]}>
                <Cloud words={techList} isActive={true} />
              </group>
            </Canvas>
          </MaskRight>
        </MaskLeft>
      </DraggableArea>
      <ScrollableArea />
    </MasterSceneWrapper>
  );
});

export default MasterScene;
