import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGuide } from '../../contexts/GuideContext';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import Typography from '../atoms/Typography';
import { useData } from '../../contexts/DataContext';

const GuideWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(3, 3, 3, 0.5);
  color: white;
  padding: 18px 24px;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(3, 3, 3, 0.3);
  z-index: 1000;
  max-width: 80%;
  width: fit-content;
  min-width: max-content;
  mask-image: linear-gradient(to top, transparent 0%, black 35%, black 65%, transparent 100%);

  @media (max-width: 768px) {
    min-width: unset;
    width: 98vw;
  }
`;

const GuideHelper: React.FC = () => {
  const { guideMessage } = useGuide();
  const { error } = useData('personal');
  const [currentMessage, setCurrentMessage] = useState<string | null>(null);
  const [isAnimationReady, setIsAnimationReady] = useState(false);

  useEffect(() => {
    if (guideMessage) {
      setCurrentMessage(guideMessage);
      setIsAnimationReady(false);
    } else {
      setCurrentMessage(null);
    }
  }, [guideMessage]);

  useEffect(() => {
    if (currentMessage) {
      const timer = setTimeout(() => {
        setIsAnimationReady(true);
      }, 100); // Adjust the delay as needed
      return () => clearTimeout(timer);
    }
  }, [currentMessage]);

  if (!currentMessage || error || !isAnimationReady) return null;

  return (
    <GuideWrapper>
      <Typography align="center" animated animationType="write" variant={TypographyVariant.sectionDescription} highlight_level={HighlightLevel.neutral}>
        {currentMessage}
      </Typography>
    </GuideWrapper>
  );
};

export default GuideHelper;
