import React, { useState } from 'react';
import { TagWrapper } from '../sections/Docs/Docs.styles';
import Typography from './Typography';
import { HighlightLevel, TypographyVariant } from './Typography.autogen';

interface FilterButtonProps {
  FilterName: string;
  handleAddFilter: (filter: string) => void;
  isActive: boolean;
  index: number;
}

export default function FilterButton({ isActive, FilterName, index, handleAddFilter }: FilterButtonProps) {
  const handleHighlightLevel = isActive ? HighlightLevel.active : HighlightLevel.neutral;

  const [ishovered, setishovered] = useState(false);

  const handleMouseEnter = () => {
    if (!isActive) {
      setishovered(true);
    }
  };

  const handleMouseLeave = () => {
    setishovered(false);
  };

  return (
    <TagWrapper isActive={isActive} ishovered={ishovered} onClick={() => handleAddFilter(FilterName)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Typography animated animationType="fade" variant={TypographyVariant.sectionDescription} highlight_level={handleHighlightLevel}>
        {FilterName}
      </Typography>
    </TagWrapper>
  );
}
