import React, { useRef, useState, useCallback, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { MarkdownBlockWrapper, TileWrapper } from './MarkdownBlock.styles';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { usePreview } from '../../contexts/ImageGalleryPreviewContext';

// Custom hook for resizing
const useResize = (myRef: React.RefObject<HTMLDivElement>) => {
  const getWidth = useCallback(() => myRef?.current?.offsetWidth, [myRef]);

  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
    };

    if (myRef.current) {
      setWidth(getWidth());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, getWidth]);

  return width && width > 25 ? width - 25 : width;
};

// Custom renderers for ReactMarkdown
const renderers = (maxWidth: number | undefined, images: string[], openPreview: (images: string[], startIndex: number) => void) => ({
  img: ({ alt, src, title }: { alt?: string; src?: string; title?: string }) => (
    <ImageWrapper onClick={() => src && openPreview(images, images.indexOf(src || ''))}>
      <img alt={alt} src={src} title={title} style={{ maxWidth, display: 'block' }} />
      <FullscreenIcon>
        <BsArrowsFullscreen />
      </FullscreenIcon>
    </ImageWrapper>
  ),
});

// Styled components
const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  max-width: 100% !important;
`;

const FullscreenIcon = styled.div`
  position: absolute;
  top: 26px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  svg {
    color: white;
    font-size: 16px;
  }
`;

const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* Center the loader */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #ffffff;
  animation: spin 1s linear infinite;
  color: ${({ theme }) => theme.colors.whiteLight};

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => (
  <LoaderWrapper>
    <Spinner />
  </LoaderWrapper>
);

interface MarkdownBlockProps {
  markDownContent: string | null;
  extendedpadding?: boolean;
}

const MarkdownBlock: React.FC<MarkdownBlockProps> = ({ markDownContent, extendedpadding }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const maxWidth = useResize(divRef);
  const { openPreview } = usePreview();

  const [isLoading, setIsLoading] = useState(true);

  // Extract all image URLs from the markdown content
  const images = markDownContent ? markDownContent.match(/!\[.*?\]\((.*?)\)/g)?.map((img) => img.match(/\((.*?)\)/)?.[1] || '') || [] : [];

  useEffect(() => {
    if (!markDownContent) {
      setIsLoading(false);
      return;
    }

    // Wait until all images are loaded
    const imagePromises = images.map(
      (src) =>
        new Promise<void>((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve();
          img.onerror = () => resolve(); // You can handle errors differently if needed
        }),
    );

    const minLoadingTime = 300; // Minimum loading time in milliseconds
    const loadStartTime = Date.now();

    Promise.all(imagePromises).then(() => {
      const elapsed = Date.now() - loadStartTime;
      const remainingTime = Math.max(0, minLoadingTime - elapsed);
      setTimeout(() => {
        setIsLoading(false);
      }, remainingTime);
    });
  }, [markDownContent, images]);

  useEffect(() => {
    if (!isLoading) {
      // Set initial styles for animation
      gsap.set(divRef.current, { opacity: 0 });
      gsap.set('.markdown-content', { opacity: 0, y: 20 });

      // Fade in the TileWrapper
      gsap.to(divRef.current, {
        opacity: 1,
        duration: 1,
        ease: 'power2.out',
      });
      // Fade in and slide up the markdown content
      gsap.to('.markdown-content', {
        opacity: 1,
        y: 0, // Slide up to its final position
        duration: 1,
        delay: 0.5,
        ease: 'power2.out',
      });
    }
  }, [isLoading]);

  if (!markDownContent) {
    return null; // or return a loading state or placeholder
  }

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <TileWrapper extendedpadding={extendedpadding} is_row_section_active={true} ref={divRef}>
          <MarkdownBlockWrapper className="markdown-content">
            <ReactMarkdown className={'hoverable'} children={markDownContent} remarkPlugins={[remarkGfm]} components={renderers(maxWidth, images, openPreview)} />
          </MarkdownBlockWrapper>
        </TileWrapper>
      )}
    </>
  );
};

export default MarkdownBlock;
