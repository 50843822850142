import { useState, useEffect } from 'react';
import Typography from '../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import { OpenToWorkData } from './OpenToWork.data';
import UsePersonalData from '../../hooks/usePersonalData';
import { SliderContainer, SliderContent, SliderItem } from './OpenToWorkSlider.styles';

export default function OpenToWorkSlider() {
  const { isOpenToWork } = UsePersonalData();
  const [opacity, setOpacity] = useState(0.4); // Default opacity

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const mouseY = event.clientY;
      const windowHeight = window.innerHeight;
      const top20Percent = windowHeight * 0.2;

      if (mouseY <= top20Percent) {
        const newOpacity = 0.8 - (mouseY / top20Percent) * 0.4;
        setOpacity(newOpacity);
      } else {
        setOpacity(0.4);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Convert translations object to an array of strings
  const translations = Object.values(OpenToWorkData.translations);

  if (!isOpenToWork) return null;

  return (
    <SliderContainer>
      <SliderContent opacity={opacity}>
        {/* Repeat the content to ensure seamless looping */}
        {translations.concat(translations).map((text, index) => (
          <SliderItem key={index}>
            <Typography animated animationType="fade" highlight_level={HighlightLevel.inactive} variant={TypographyVariant.sectionSubHeader} hoverColor={'#94F576A3'}>
              {text}
              {' • '}
            </Typography>
          </SliderItem>
        ))}
        {translations.concat(translations).map((text, index) => (
          <SliderItem key={index + translations.length}>
            <Typography animated animationType="fade" highlight_level={HighlightLevel.inactive} variant={TypographyVariant.sectionSubHeader} hoverColor={'#94F576A3'}>
              {text}
              {' • '}
            </Typography>
          </SliderItem>
        ))}
      </SliderContent>
    </SliderContainer>
  );
}
