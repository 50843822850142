import React from "react";
import { ArrowContainer, ArrowHead, Line } from "./Arrow.styles";

const Arrow: React.FC = () => {
  return (
    <ArrowContainer>
      <ArrowHead />
      <Line />
    </ArrowContainer>
  );
};

export default Arrow;
