// withMagneticEffect.tsx
import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import useMagneticEffect from './useMagneticEffect';

const withMagneticEffect = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  return forwardRef<HTMLDivElement, P>((props, ref) => {
    const innerRef = useRef<HTMLDivElement>(null);
    useMagneticEffect(innerRef);

    // This ensures that the ref passed to the HOC will be forwarded to the wrapped component
    useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

    return <Component ref={innerRef} {...props} />;
  });
};

export default withMagneticEffect;
