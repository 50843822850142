import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  SSGallerySectionWrapper,
  StyledImage,
  GradientOverlayLeft,
  ArrowLeft,
  GradientOverlayRight,
  ArrowRight,
  FullscreenIconWrapper,
  MiddleClickOverlay,
} from './SSGallerySection.styles';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { usePreview } from '../../contexts/ImageGalleryPreviewContext';
import { useGuide } from '../../contexts/GuideContext';

interface SSGallerySectionProps {
  images: string[];
  startTime: number;
}

const SSGallerySection: React.FC<SSGallerySectionProps> = ({ images, startTime }) => {
  const { openPreview } = usePreview();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [zoomPosition, setZoomPosition] = useState({ x: 50, y: 50 });
  const [zoomScale, setZoomScale] = useState(1);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const frameId = useRef<number | null>(null);
  const { fadeOutGuide } = useGuide();

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  // Limit the zoom position within the image bounds
  const limitZoomPosition = (pos: number, scale: number) => {
    const maxTranslate = (scale - 1) * 40;
    return Math.max(40 - maxTranslate, Math.min(40 + maxTranslate, pos));
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (frameId.current) {
      cancelAnimationFrame(frameId.current);
    }

    frameId.current = requestAnimationFrame(() => {
      if (wrapperRef.current) {
        const { left, top, width, height } = wrapperRef.current.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;

        // Limit the zoom position based on the zoom scale
        const limitedX = limitZoomPosition(x, zoomScale);
        const limitedY = limitZoomPosition(y, zoomScale);

        setZoomPosition({ x: limitedX, y: limitedY });
      }
    });
  };

  const calculateTranslate = (position: number) => {
    const maxTranslate = (zoomScale - 1) * 50;
    const translate = 50 - position;
    return Math.max(-maxTranslate, Math.min(translate, maxTranslate));
  };

  const startImageSequence = () => {
    const intervalDuration = 10000;
    intervalRef.current = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, intervalDuration);
  };

  useEffect(() => {
    if (!images || images.length === 0) return;

    const delay = startTime;

    const startTimeout = setTimeout(() => {
      startImageSequence();
    }, delay);

    return () => {
      clearTimeout(startTimeout);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (frameId.current) {
        cancelAnimationFrame(frameId.current);
      }
    };
  }, [images, startTime]);

  if (!images || images.length === 0) {
    return null; // Or render a placeholder
  }

  const isScreenBigEnough = window.innerWidth > 1800;

  return (
    <SSGallerySectionWrapper
      ref={wrapperRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => {
        setZoomPosition({ x: 50, y: 50 });
        setZoomScale(1);
      }}
      onMouseEnter={() => {
        setZoomScale(1.8);
      }}
    >
      <AnimatePresence>
        <motion.div
          key={currentImageIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <StyledImage
            src={images[currentImageIndex]}
            alt={`Image ${currentImageIndex}`}
            style={{
              transform: !isScreenBigEnough ? `scale(${zoomScale}) translate(${calculateTranslate(zoomPosition.x)}%, ${calculateTranslate(zoomPosition.y)}%)` : '',
              transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
              transition: 'transform 0.5s ease',
            }}
          />
        </motion.div>
      </AnimatePresence>

      {/* Middle area overlay */}
      <MiddleClickOverlay
        onClick={() => {
          openPreview(images, currentImageIndex);
          fadeOutGuide();
        }}
      >
        <FullscreenIconWrapper>
          <BsArrowsFullscreen />
        </FullscreenIconWrapper>
      </MiddleClickOverlay>

      {/* Left and Right Overlays */}
      <GradientOverlayLeft
        onClick={(e) => {
          e.stopPropagation();
          prevImage();
        }}
      >
        <ArrowLeft />
      </GradientOverlayLeft>
      <GradientOverlayRight
        onClick={(e) => {
          e.stopPropagation();
          nextImage();
        }}
      >
        <ArrowRight />
      </GradientOverlayRight>
    </SSGallerySectionWrapper>
  );
};

export default SSGallerySection;
