import emailjs from 'emailjs-com';

/**
 * Sends an email using EmailJS service.
 * @param {Record<string, unknown>} templateParams - The parameters to pass to the email template.
 * @returns {Promise<any>} - Returns a promise that resolves when the email is sent successfully.
 */

export const sendEmail = async (templateParams: Record<string, unknown>): Promise<any> => {
  try {
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    if (!serviceID || !templateID || !userID) {
      throw new Error('Missing EmailJS configuration in environment variables.');
    }

    const result = await emailjs.send(serviceID, templateID, templateParams, userID);

    return result;
  } catch (error) {
    console.error('Failed to send email:', error);
    throw error;
  }
};
