import styled from "styled-components";

export const FlippingWrapper = styled.div<{ flipped: boolean }>`
  transform: scaleX(${(props) => (props.flipped ? -1 : 1)});
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
`;

export const CollapseArrowWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: block;
  top: 0;
  right: 0;

  svg {
    scale: 1.1;
    color: ${({ theme }) => theme.colors.whitePrePrimary};
    &:hover {
      color: ${({ theme }) => theme.colors.whitePrimary};
      transition: color 0.3s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    display: none;
    height: 100%;
  }
`;

