import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import Typography from '../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import { FormFieldWrapper, LabelWrapper, ShortFieldsWrapper, TransparentInput, LongFieldWrapper, TransparentTextarea, InfoLabelWrapper } from './FormFiels.style';

interface FormFieldProps {
  fieldName: string;
  control: any;
  name: string;
  info?: string;
  rules?: object;
  index: number;
  fieldType: 'shortField' | 'longField';
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export default function FormField({ fieldName, control, name, rules, index, info, fieldType, onFocus, onBlur, onChange }: FormFieldProps) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) onFocus();
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur) onBlur();
  };

  return (
    <FormFieldWrapper>
      <LabelWrapper>
        <Typography align="left" animated animationType="fade" variant={TypographyVariant.micro} highlight_level={isFocused ? HighlightLevel.active : HighlightLevel.neutral}>
          {fieldName}
        </Typography>
      </LabelWrapper>
      {fieldType === 'shortField' ? (
        <ShortFieldsWrapper>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <TransparentInput 
                {...field} 
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => {
                  field.onChange(e);
                  if (onChange) onChange(e);
                }}
              />
            )}
          />
        </ShortFieldsWrapper>
      ) : (
        <LongFieldWrapper>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <TransparentTextarea 
                {...field} 
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            )}
          />
        </LongFieldWrapper>
      )}
      <InfoLabelWrapper>
        {info && (
          <Typography align="left" animated animationType="fade" variant={TypographyVariant.micro} highlight_level={HighlightLevel.neutral}>
            {info}
          </Typography>
        )}
      </InfoLabelWrapper>
    </FormFieldWrapper>
  );
}
