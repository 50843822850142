import React, { useEffect, useRef } from 'react';
import { TbLetterX } from 'react-icons/tb';
import gsap from 'gsap';
import FilterButton from '../atoms/FilterButton';
import { FiltersWrapper, TagWrapper } from '../sections/Docs/Docs.styles';

interface DocFiltersProps {
  FilterList: string[];
  activeFilters: string[];
  handleFilter: (filter: string) => void;
  hideFilters: boolean;
}

const DocFilters: React.FC<DocFiltersProps> = ({ FilterList, activeFilters, handleFilter, hideFilters }) => {
  const filtersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (filtersRef.current) {
      if (hideFilters) {
        gsap.to(filtersRef.current, {
          height: 0,
          opacity: 0,
          duration: 0.5,
          ease: 'power2.inOut',
        });
      } else {
        gsap.to(filtersRef.current, {
          height: 'auto',
          opacity: 1,
          duration: 0.5,
          ease: 'power2.inOut',
        //   clearProps: 'height',
        });
      }
    }
  }, [hideFilters]);

  return (
    <FiltersWrapper ref={filtersRef}>
      {FilterList.map((filter, index) => (
        <FilterButton
          key={index}
          isActive={activeFilters.includes(filter)}
          FilterName={filter}
          index={index}
          handleAddFilter={() => {
            handleFilter(filter);
          }}
        />
      ))}
    </FiltersWrapper>
  );
};

export default DocFilters;
