import styled from 'styled-components';

export const ExternalWrapper = styled.div<{ height: string }>`
  display: flex;
  min-width: 550px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: ${({ height }) => height};
  scale: 0.9;

  @media (max-width: 1400px) and (min-width: 1000px) {
    transform: scale(0.8);
    margin-top: -40px;
    top: 0;
  }
  @media (max-width: 1200px) and (min-width: 900px) {
    margin-bottom: auto;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    transform: scale(0.9);
    margin-top: -25px;
    margin-bottom: auto;
  }
  @media (max-width: 900px) and (min-width: 768px) {
    transform: scale(0.8);
    margin-top: -35px;
  }
  @media (max-width: 768px) {
    min-width: 98vw;
    max-width: 98vw;
    transform: scale(0.85);
    margin-top: -60px;
  }

  // for height below 900 scale 0.6
  @media (max-height: 900px) {
    transform: scale(0.8);
    margin-top: -20px;
  }
  @media (max-height: 700px) {
    transform: scale(0.7);
    margin-top: -40px;
  }
`;

export const BracketsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  padding: 6px;
`;

export const BracketLeft = styled.div`
  width: 20px;
  height: 100%;
  border: 5px solid ${({ theme }) => theme.colors.greyPrimary25};
  border-right: none;
  margin-right: -8px;
`;

export const BracketRight = styled.div`
  width: 20px;
  height: 100%;
  border: 5px solid ${({ theme }) => theme.colors.greyPrimary25};
  border-left: none;
`;

export const InternalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin-top: 10px;
`;

export const ArrayItemWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  height: 26px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.greyPrimary20};
  transition: background-color 0.8s ease-in-out;
  padding: 3px;
  padding-left: 6px;
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 3px;
  ${({ isActive, theme }) =>
    isActive &&
    `
        background-color: ${theme.colors.greyPrimary40};
        transition: background-color 0.8s ease-in-out;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.warning};
    transition: background-color 0.6s ease-in-out;
    color: white;
  }
`;
