// AboutMe.tsx
import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { gsap } from 'gsap';
import Section from '../../organisms/Section';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { ParagraphWrapper } from '../../atoms/Typography.styles';
import Typography from '../../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../../atoms/Typography.autogen';
import {
  SectionWrapper,
  TopWrapper,
  StyledTileWrapper,
  TileContent,
  MobileContentWrapper,
  MobileWrapper,
  GradientOverlayLeft,
  ArrowLeft,
  GradientOverlayRight,
  ArrowRight,
} from './AboutMe.styles';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import MobileCarousel from '../../organisms/MobileCarousel';
import { useGuide } from '../../../contexts/GuideContext';
import { useOptimizer } from '../../../contexts/OptimizerContext';
import { useAboutMeTiles } from '../../../contexts/UseAboutTilesContext';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';

export interface TileData {
  tile_name: string;
  middle_priority: number;
  tile_content: string;
  tile_image?: {
    data: Array<{
      attributes: {
        url: string;
      };
    }>;
  };
}

const DRAG_EFFECT_STRENGTH = 3.6;
const DRAG_BOUNDARY_X = 40; // Maximum distance the tile can be dragged
const DRAG_BOUNDARY_Y = 20; // Maximum distance the tile can be dragged

const AboutMe: React.FC = () => {
  const { performanceLevel } = useOptimizer();
  const { activeSection } = useActiveSection();
  const { fadeOutGuide, setGuideMessage } = useGuide();
  const tileWrapperRef = useRef<HTMLDivElement>(null);
  const tileContentRef = useRef<HTMLDivElement>(null);

  const { loading, error, selectedTileData, handleNavigate } = useAboutMeTiles();
  const topWrapperRef = useRef<HTMLDivElement>(null);

  const isMobile = window.innerWidth <= 768;
  const [zoom, setZoom] = useState(1);
  const [tiltX, setTiltX] = useState(0);
  const [tiltY, setTiltY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fadeOutGuide();
    if (activeSection === 'ABOUT_ME' && !isMobile) {
      setGuideMessage('Scroll horizontally / use  ← → WASD / click on the words / click description tile arrows.', 1000);
    }
    return () => setGuideMessage('');
  }, [activeSection]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (topWrapperRef.current) {
      const rect = topWrapperRef.current.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      const x = (mouseX - rect.width / 2) / DRAG_EFFECT_STRENGTH;
      const y = (mouseY - rect.height / 2) / DRAG_EFFECT_STRENGTH;

      // Apply boundary limits
      const boundedX = Math.max(-DRAG_BOUNDARY_X, Math.min(DRAG_BOUNDARY_X, x));
      const boundedY = Math.max(-DRAG_BOUNDARY_Y, Math.min(DRAG_BOUNDARY_Y, y));

      gsap.to(tileWrapperRef.current, {
        x: boundedX,
        y: boundedY,
        duration: 0.3,
        ease: 'power2.out',
      });
    }
  };

  const updateHeight = useCallback(() => {
    if (tileWrapperRef.current && tileContentRef.current) {
      const contentHeight = tileContentRef.current?.offsetHeight || 0;
      gsap.to(tileWrapperRef.current, {
        height: contentHeight + 40, // Add padding
        duration: 0.5,
        ease: 'power2.out',
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (tileWrapperRef.current && tileContentRef.current) {
      updateHeight();

      const resizeObserver = new ResizeObserver(updateHeight);
      resizeObserver.observe(tileContentRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [updateHeight]);

  // Add this useEffect to update height on selectedTileData change
  useEffect(() => {
    updateHeight();
  }, [selectedTileData, key, updateHeight]);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  if (loading) {
    return <LoadingPage isFullPage={false} isLoading={loading} />;
  }

  if (error) {
    return <ErrorPage isFullPage={false} sourceOfError="about me" />;
  }

  return (
    <Section title="About Me">
      <SectionWrapper>
        {isMobile ? (
          <MobileWrapper>
            <MobileContentWrapper>
              <MobileCarousel />
            </MobileContentWrapper>
          </MobileWrapper>
        ) : (
          <>
            <TopWrapper ref={topWrapperRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp}>
              <StyledTileWrapper
                ref={tileWrapperRef}
                style={{
                  transform: `rotateX(${(tiltX * 180) / Math.PI}deg) rotateY(${(tiltY * 180) / Math.PI}deg)`,
                  cursor: isDragging ? 'grabbing' : 'grab',
                }}
                onMouseDown={handleMouseDown}
              >
                <GradientOverlayLeft onClick={() => handleNavigate('left')}>
                  <ArrowLeft />
                </GradientOverlayLeft>
                <GradientOverlayRight onClick={() => handleNavigate('right')}>
                  <ArrowRight />
                </GradientOverlayRight>
                <ParagraphWrapper>
                  <TileContent ref={tileContentRef}>
                    {selectedTileData ? (
                      <>
                        <Typography key={`title-${key}`} animated animationType="fade" highlight_level={HighlightLevel.highlighted} variant={TypographyVariant.sectionHeader}>
                          {selectedTileData.tile_name}
                        </Typography>
                        <Typography
                          key={`content-${key}`}
                          animated
                          animationType="fade"
                          highlight_level={HighlightLevel.highlighted}
                          variant={TypographyVariant.sectionDescription}
                        >
                          {selectedTileData.tile_content}
                        </Typography>
                        {selectedTileData.tile_name.toLowerCase() === 'about this site' && (
                          <>
                            <div style={{ height: '1px', width: '100%', backgroundColor: 'white', margin: '20px 0' }} className="hoverable" />
                            <Typography animated animationType="write" highlight_level={HighlightLevel.highlighted} variant={TypographyVariant.sectionDescription}>
                              When you hover over active elements, cursor circle is growing like when you hover over navigation menu buttons. It means you can click it!
                            </Typography>
                          </>
                        )}
                      </>
                    ) : (
                      <Typography animated animationType="fade" highlight_level={HighlightLevel.highlighted} variant={TypographyVariant.sectionDescription}>
                        No data available
                      </Typography>
                    )}
                  </TileContent>
                </ParagraphWrapper>
              </StyledTileWrapper>
            </TopWrapper>
          </>
        )}
      </SectionWrapper>
    </Section>
  );
};

export default AboutMe;
