import React, { useEffect, useRef, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { createStartTimeScheduler } from './createStartTimeScheduler';
import { Subscription } from 'rxjs';
import Section from '../../organisms/Section';
import {
  ExpandingWrapper,
  EntityRow,
  GeneralAboutSection,
  ImageWrapper,
  MarkdownBlockWrapper,
  SectionWrapper,
  TileWrapper,
  GeneralAboutSectionWrapper,
  ArrowWrapper,
} from './Experience.styles';
import Typography from '../../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../../atoms/Typography.autogen';
import { useData } from '../../../contexts/DataContext';
import { ParagraphWrapper } from '../../atoms/Typography.styles';
import ArrowSection from '../../organisms/ArrowSection';
import SSGallerySection from '../../organisms/SSGallerySection';
import useMagnifyingEffect from '../../../hooks/useMagnifyingEffect';
import withMagneticEffect from '../../../hooks/withMagneticEffect';
import { getMediaUrlList } from '../../../utils/getMediaUrl';
import MarkdownBlock from '../../organisms/MarkdownBlock';
import AnimatedCursor from 'react-animated-cursor';
import { extractJsonEntitiesWithDescription } from '../../../utils/getTechAndDescription';
import TechDescBlock from '../../organisms/TechDescBlock';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { useGuide } from '../../../contexts/GuideContext';
import NavArrow from '../../molecules/NavArrow';
import { useOptimizer } from '../../../contexts/OptimizerContext';
import { ArrowSectionWrapper } from '../AboutMe/AboutMe.styles';
import HoverBlock from '../../molecules/HoverBlock';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import { useHoverBlock } from '../../../contexts/HoverBlockContext';

const EntityRowWithMagneticEffect = withMagneticEffect(EntityRow);

const Experience: React.FC = () => {
  const { data, loading, error } = useData('experience');
  const { setGuideMessage, fadeOutGuide } = useGuide();
  const [expandedSection, setExpandedSection] = useState<number | null>(null);
  const sectionWrapperRef = useRef<HTMLDivElement>(null);
  const [sectionWrapperHeight, setSectionWrapperHeight] = useState<number>(0);
  const [startTimes, setStartTimes] = useState<number[]>([]);
  const startTimeSubscription = useRef<Subscription | null>(null);
  const { performanceLevel } = useOptimizer();

  const generalAboutRefs = useRef<(HTMLDivElement | null)[]>([]);
  const imageWrapperRefs = useRef<(HTMLDivElement | null)[]>([]);

  const arrowSectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const expandingWrapperRefs = useRef<(HTMLDivElement | null)[]>([]);

  useLayoutEffect(() => {
    if (sectionWrapperRef.current) {
      setSectionWrapperHeight(sectionWrapperRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      startTimeSubscription.current = createStartTimeScheduler(data.length).subscribe({
        next: (times: number[]) => {
          setStartTimes(times);
        },
        error: (err) => {
          console.error(err);
        },
      });
    }

    return () => {
      startTimeSubscription.current?.unsubscribe();
    };
  }, [data]);

  const sections = useMemo(() => data || [], [data]);

  const numSections = sections.length;
  const { hoveredSection, handleMouseEnter, handleMouseLeave } = useMagnifyingEffect(numSections);
  const [imagePreviewHovered, setImagePreviewHovered] = useState(false);
  const { activeSection } = useActiveSection();


  useLayoutEffect(() => {
    fadeOutGuide();
    if (activeSection === 'EXPERIENCE') {
      if (expandedSection === null) {
        fadeOutGuide();
        setGuideMessage('Click on a section to see more details.', 3000);
      } else {
        fadeOutGuide();
        setGuideMessage('Hovering over Tech Stack will explain more about the technologies use cases', 1000);
      }
    }
    return () => setGuideMessage('');
  }, [activeSection, expandedSection]);

  function getHighlightLevel(index: number) {
    if (index === expandedSection) {
      return HighlightLevel.active;
    } else if (index === hoveredSection) {
      return HighlightLevel.highlighted;
    } else {
      return HighlightLevel.neutral;
    }
  }

  useEffect(() => {
    if (sectionWrapperRef.current) {
      const rows = sectionWrapperRef.current.querySelectorAll('.entity-row');

      if (activeSection === 'EXPERIENCE' && performanceLevel !== 'low') {
        gsap.fromTo(
          rows,
          { autoAlpha: 0, y: 50, opacity: 0 },
          {
            duration: 0.8,
            autoAlpha: 1,
            opacity: 1,
            y: 0,
            x: '0%',
            stagger: 0.2,
            ease: 'power2.out',
          },
        );
      } else if (activeSection === 'EXPERIENCE' && performanceLevel === 'low') {
        gsap.set(rows, { autoAlpha: 1, y: 0, opacity: 1, x: '0%' });
      } else {
        gsap.set(rows, { autoAlpha: 0, y: -50, opacity: 0 });
      }
    }
  }, [activeSection, sections, performanceLevel]);

  const handleRowClick = (index: number) => {
    if (expandedSection === index) {
      setExpandedSection(null);
      updateSectionVisibility(null);
    } else {
      setExpandedSection(index);
      updateSectionVisibility(index);
    }
  };

  const updateSectionVisibility = (activeIndex: number | null) => {
    const spacing = 10; // Adjust the spacing between rows as needed
    const sectionWrapperHeight = sectionWrapperRef.current?.clientHeight || 0;
    const expandedHeight = sectionWrapperHeight - spacing * (sections.length - 1);

    sections.forEach((_: any, index: number) => {
      const generalAboutSection = generalAboutRefs.current[index];
      const imageWrapper = imageWrapperRefs.current[index];
      const arrowSection = arrowSectionRefs.current[index];
      const expandingWrapper = expandingWrapperRefs.current[index];

      if (generalAboutSection && imageWrapper && arrowSection && expandingWrapper) {
        if (activeIndex === null) {
          // Reset all sections
          gsap.to(expandingWrapper, {
            duration: 0.8,
            height: sectionWrapperHeight / sections.length - spacing,
            marginBottom: spacing,
            ease: 'power2',
          });
          gsap.to([generalAboutSection, imageWrapper, arrowSection], {
            duration: 0.8,
            opacity: 1,
            ease: 'power2',
          });
        } else if (activeIndex === index) {
          // Expand the active section and fade out its imageWrapper
          gsap.to(expandingWrapper, {
            duration: 0.8,
            height: expandedHeight,
            marginBottom: spacing,
            ease: 'power2',
          });
          gsap.to([generalAboutSection, arrowSection], {
            duration: 0.3,
            opacity: 1,
            ease: 'power2',
          });
          gsap.to(imageWrapper, {
            duration: 0.2,
            opacity: 0,
            ease: 'power2',
          });
        } else {
          // Fade out inactive sections
          gsap.to(expandingWrapper, {
            duration: 0.8,
            height: 0,
            marginBottom: spacing,
            ease: 'power2',
          });
          gsap.to([generalAboutSection, imageWrapper, arrowSection], {
            duration: 0.2,
            opacity: 0,
            ease: 'power2',
          });
        }
      }
    });
  };

  if (loading) {
    return <LoadingPage isFullPage={false} isLoading={loading} />;
  }

  if (error) {
    return <ErrorPage isFullPage={false} sourceOfError="experience" />;
  }

  const isMobile = window.innerWidth < 768;
  const rowHeight = sectionWrapperHeight / sections.length;

  return (
    <Section title="Experience">
      <SectionWrapper ref={sectionWrapperRef}>
        <ArrowWrapper>
          {expandedSection !== null && <NavArrow mounted isFlipped={expandedSection !== null ? true : false} onClick={() => handleRowClick(expandedSection)} />}
        </ArrowWrapper>
        {sections.map((item: any, index: number) => (
          <ExpandingWrapper ref={(el) => (expandingWrapperRefs.current[index] = el)} key={`expanding-wrapper-${index}`} is_row_section_active={expandedSection === index}>
            <EntityRowWithMagneticEffect
              className={`entity-row entity-row-${index}`}
              height={rowHeight}
              key={`entity-row-${index}`}
              style={{ zIndex: hoveredSection === index ? 1 : 0 }} // Ensure the hovered element is on top
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <ArrowSectionWrapper className="hoverable" onClick={() => handleRowClick(index)}>
                <ArrowSection
                  isActive={expandedSection === index}
                  ref={(el) => (arrowSectionRefs.current[index] = el)}
                  key={`arrow-section-${index}`}
                  index={index}
                  item={item}
                  getHighlightLevel={getHighlightLevel}
                />
              </ArrowSectionWrapper>

              <GeneralAboutSectionWrapper ref={(el) => (generalAboutRefs.current[index] = el)}>
                <GeneralAboutSection onClick={() => handleRowClick(index)} className="hoverable">
                  <TileWrapper centerOnBigScreen>
                    <ParagraphWrapper>
                      <Typography animated animationType="fade" variant={TypographyVariant.sectionDescription} highlight_level={getHighlightLevel(index)}>
                        {item.general_about}
                      </Typography>
                    </ParagraphWrapper>
                  </TileWrapper>
                  {expandedSection === index && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '10px', marginTop: '-10px', marginBottom: '-10px' }}>
                      {isMobile && <span>&#8594;</span>}
                    </div>
                  )}
                  {expandedSection === index && <TechDescBlock techDescArray={item.technologies_about ? extractJsonEntitiesWithDescription(item.technologies_about) : []} />}
                </GeneralAboutSection>
              </GeneralAboutSectionWrapper>

              {expandedSection === index ? (
                <MarkdownBlockWrapper className="hoverable" ref={(el) => (imageWrapperRefs.current[index] = el)}>
                  <MarkdownBlock markDownContent={item.work_demo_md} />
                </MarkdownBlockWrapper>
              ) : (
                <ImageWrapper
                  onMouseEnter={() => setImagePreviewHovered(true)}
                  onMouseLeave={() => setImagePreviewHovered(false)}
                  display={!isMobile}
                  height={rowHeight}
                  className={'image-wrapper hoverable'}
                  ref={(el) => (imageWrapperRefs.current[index] = el)}
                >
                  <SSGallerySection images={item.ss_gallery ? getMediaUrlList(item.ss_gallery) : []} startTime={startTimes[index]} key={`ss-gallery-section-${index}`} />
                </ImageWrapper>
              )}
            </EntityRowWithMagneticEffect>
          </ExpandingWrapper>
        ))}
      </SectionWrapper>
      <HoverBlock
        blockAction={hoveredSection !== null && imagePreviewHovered ? 'Open image gallery preview' : 'Expand section'}
        isVisible={!isMobile && hoveredSection !== null && expandedSection === null}
      />
      <AnimatedCursor />
    </Section>
  );
};

export default Experience;
