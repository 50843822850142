import React, { createContext, useState, useContext, ReactNode } from 'react';

interface PreviewContextProps {
  isPreviewOpen: boolean;
  images: string[];
  startIndex: number;
  openPreview: (images: string[], startIndex: number) => void;
  closePreview: () => void;
}

const PreviewContext = createContext<PreviewContextProps | undefined>(undefined);

export const usePreview = () => {
  const context = useContext(PreviewContext);
  if (!context) {
    throw new Error('usePreview must be used within a PreviewProvider');
  }
  return context;
};

export const PreviewProvider = ({ children }: { children: ReactNode }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [startIndex, setStartIndex] = useState(0);

  const openPreview = (images: string[], startIndex: number) => {
    setImages(images);
    setStartIndex(startIndex);
    setIsPreviewOpen(true);
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
  };

  return <PreviewContext.Provider value={{ isPreviewOpen, images, startIndex, openPreview, closePreview }}>{children}</PreviewContext.Provider>;
};
