import styled from "styled-components";

import { EntityRow, TileWrapper } from '../Experience/Experience.styles';

export const EduTilesWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-items: center;
    width: 96%;
    margin-left: 6%;
    height: unset;
  }

  @media (min-height: 1280px) {
    height: 70vh;
    max-height: 1050px;
    display: flex;
    margin-top: 10vh;
  }
`;

export const SectionWrapper = styled.div<{ width?: string; marginRight?: string; height?: string }>`
  height: ${(props) => props.height};

  width: ${(props) => props.width};
  margin-right: ${(props) => props.marginRight};
  position: relative; /* Ensure the container is positioned relative */
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
    justify-content: center;
    width: 90vw;
  }
`;

export const UniLogo = styled.img<{ src: string; alt?: string; magnified?: any }>`
  position: absolute; /* Position the logo absolutely within the SectionWrapper */
  top: -26px;
  left: -15px;
  height: 100px;
  width: 100px;
  border-radius: 10px;
  z-index: 99; /* Ensure the logo is on top of TileWrapper */

  @media (max-width: 900px) {
    max-height: 54px;
    max-width: 54px;
    top: -20px;
    left: -28px;
  }

  @media (max-height: 880px) {
    max-height: 74px;
    max-width: 74px;
    // top: -16px;
    // left: -28px;
  }
`;

export const StyledTileWrapper = styled(TileWrapper)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 12px;
  overflow: hidden;

`;

export const AcronymWrapper = styled.div`
  padding-top: 6px;
  border-radius: 10px;
  z-index: 99;

    @media (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media (max-width: 1100px) {
    padding-bottom: 4px;
  }
  @media (max-width: 900px) {
    padding-bottom: 6px;
    padding-top: 0px;
  }
`;

export const UniDetailsWrapper = styled.div`
  top: 56px;
  display: flex;
  height: 100%;
  width: 300px;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 10px;
  background-color: '#ffffff';

  @media (max-width: 900px) {
    gap: 6px;
    top: 0px;
    // width: all available
    width: 100%;
    min-width: 90px;
  }

  @media (min-height: 1080px) {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }
`;

export const DescWrapper = styled.div`
  width: 50%;
  height: 100%;
  overflow-y: auto;
  @media (max-width: 900px) {
    width: 90%;
    min-width: 180px;
  }

  @media (min-height: 1080px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
    
  // scroll bar exemplary style
  padding-right: 6px;
  &::-webkit-scrollbar {
    margin: 3px;
    background-color: ${({ theme }) => theme.colors.greyPrimary25};
    width: 10px;
    border-radius: 10px;
    &-thumb {
      background-color: ${({ theme }) => theme.colors.greyPrimary40};
      border-radius: 10px;
    }
  }
`;

export const SeparationLine = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.whiteMedium};
  margin: 0 1px;
`;