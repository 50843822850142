import React from 'react';
import styled from 'styled-components';

const PaginationListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
`;

const PaginationButton = styled.button<{ isActive: boolean }>`
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.greyPrimary35 : theme.colors.greyPrimary10)};
  width: 40px;
  height: 40px;
  padding: 4px;
  font-size: 22px;
  color: red;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  margin: 0;
  transition: background-color 0.3s ease-in-out; /* Added transition for background-color */

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyPrimary20};
  }

  color: ${({ theme }) => theme.colors.whiteMedium};
`;

const ButtonsListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
`;

export const Arrow = styled.div`
  border: solid ${({ theme }) => theme.colors.greyPrimary35};
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyPrimary20};
    border-color: ${({ theme }) => theme.colors.whiteMedium};
  }

  animation: fade 0.3s ease-in-out;

  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
`;

export const ArrowRight = styled(Arrow)`
  margin-left: 20px;
  margin-right: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

export const ArrowLeft = styled(Arrow)`
  margin-left: 20px;
  margin-right: 20px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

export interface PaginationListProps {
  pageAmount: number;
  activePage: number;
  handlePageChange: (pageNumber: number) => void;
}

export default function PaginationList({ pageAmount, activePage, handlePageChange }: PaginationListProps) {
  return (
    <PaginationListWrapper>
      <ArrowLeft className="hoverable" onClick={() => activePage > 1 && handlePageChange(activePage - 1)} />
      <ButtonsListWrapper>
        {[...Array(pageAmount)].map((_, index) => (
          <PaginationButton isActive={index + 1 === activePage} onClick={() => handlePageChange(index + 1)} className="hoverable" key={index}>
            {index + 1}
          </PaginationButton>
        ))}
      </ButtonsListWrapper>
      <ArrowRight className="hoverable" onClick={() => activePage < pageAmount && handlePageChange(activePage + 1)} />
    </PaginationListWrapper>
  );
}
