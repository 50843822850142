import styled from 'styled-components';

export const Section = styled.div<{ fullWidth?: boolean }>`
  max-width: ${(props) => (props.fullWidth ? 'none' : '2440px')};
  // min-width: 1050px;
  width: ${(props) => (props.fullWidth ? '100%' : '100vw')};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: white;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;

  /* For screens wider than 1600px (large desktops) */
  @media (min-width: 1601px) {
    font-size: 3.5rem;
    max-width: ${(props) => (props.fullWidth ? 'none' : '2600px')};
  }

  /* For screens between 1400px and 1600px (desktops) */
  @media (max-width: 1600px) and (min-width: 1201px) {
    font-size: 3rem;
    max-width: ${(props) => (props.fullWidth ? 'none' : '1200px')};
  }

  /* For screens between 1400px and 1600px (desktops) */
  @media (max-width: 1400px) and (min-width: 1200px) {
    font-size: 3rem;
    // max-width: 900px;
    min-width: 768px;
  }

  /* For screens between 768px and 1200px (tablets and small desktops) */
  @media (max-width: 1400px) and (min-width: 1200px) {
    font-size: 2.5rem;
    // max-width: 900px;
    min-width: 768px;
  }

  /* For screens between 768px and 1200px (tablets and small desktops) */
  @media (max-width: 1200px) and (min-width: 769px) {
    font-size: 2.5rem;
    // max-width: 800px;
    min-width: 768px;
  }

  /* For screens between 576px and 768px (landscape phones and small tablets) */
  @media (max-width: 768px) and (min-width: 577px) {
    font-size: 2rem;
    max-width: ${(props) => (props.fullWidth ? 'none' : '768px')};
    min-width: ${(props) => (props.fullWidth ? 'none' : '576px')};
    width: ${(props) => (props.fullWidth ? 'none' : '94vw')};
  }

  /* For screens smaller than 576px (phones) */
  @media (max-width: 576px) {
    font-size: 1.5rem;
    max-width: ${(props) => (props.fullWidth ? 'none' : '100%')};
    min-width: ${(props) => (props.fullWidth ? 'none' : '100%')};
  }
`;
