import { keyframes, styled } from "styled-components";

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const LoadingPageWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s forwards;
`;

export const TextMiddle = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: calc(50% - 400px);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 9998;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s forwards;

  @media (max-width: 768px) and (max-height: 900px) {
    bottom: 0%;
  }
  @media (max-width: 768px) and (max-height: 800px) {
    bottom: -14%;
  }
  @media (max-width: 768px) and (min-height: 800px) {
    bottom: 6%;
  }
`;


export const BackgroundContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 80vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000000;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.4s forwards;
`;

export const LoadingZoneWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -10vh;
  position: relative;
  margin-left:-8px;
  @media (max-width: 768px) {

  }
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  img {
    border-radius: 50%;
    width: 420px;
    height: 420px;
    margin-left: -16px;
  }

  @media (max-height: 900px) {
    margin-top: -15vh;
  }
`;


export const LoadingZone = styled.div`
  width: 420px;
  height: 420px;
  border-radius: 50%;
  box-shadow: 0px 0px 87px 100px rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;

  @media (max-width: 768px) {
  transform: scale(0.6);
  }
`;

export const AnimatingDots = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center; 
`;

