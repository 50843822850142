import React, { useEffect, useRef } from 'react';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import Typography from '../atoms/Typography';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import { gsap } from 'gsap';
import { ArrayItemWrapper, BracketLeft, BracketRight, BracketsWrapper, ExternalWrapper, InternalWrapper } from './WhoAmIArray.styles';
import { useOptimizer } from '../../contexts/OptimizerContext';

export interface WhoAmIArrayProps {
  WhoAmIArray: string[];
  Active_Index: number;
}


export default function WhoAmIArray({ WhoAmIArray, Active_Index }: WhoAmIArrayProps) {

  const arrayLength = WhoAmIArray.length;
  const { activeSection } = useActiveSection();
  const { performanceLevel } = useOptimizer();
  const internalWrapperRef = useRef<HTMLDivElement | null>(null);
  const bracketLeftRef = useRef<HTMLDivElement | null>(null);
  const bracketRightRef = useRef<HTMLDivElement | null>(null);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  useEffect(() => {
    if (isSafari) {
      // For Safari, just set the elements to be visible without animations
      gsap.set([internalWrapperRef.current?.children, bracketLeftRef.current, bracketRightRef.current], {
        y: '0%',
        x: '0%',
        autoAlpha: 1,
        opacity: 1,
      });
    } else if (performanceLevel === 'low') {
      // For low performance, just set the elements to be visible without animations
      gsap.set([internalWrapperRef.current?.children, bracketLeftRef.current, bracketRightRef.current], {
        y: '0%',
        x: '0%',
        autoAlpha: 1,
        opacity: 1,
      });
    } else if (activeSection === 'PERSONAL') {
      // Convert HTMLCollection to array for GSAP
      const galleryItems = Array.from(internalWrapperRef.current?.children || []);

      // Animate internal wrapper (array items)
      gsap.fromTo(
        galleryItems,
        { y: '20px', autoAlpha: 0, opacity: 0 },
        {
          duration: 1.2,
          y: '0%',
          autoAlpha: 1,
          opacity: 1,
          stagger: 0.1,
          ease: 'power2.out',
        },
      );

      // Animate brackets
      gsap.fromTo(
        bracketLeftRef.current,
        { x: '-20px', autoAlpha: 0, opacity: 0 },
        {
          duration: 1.2,
          x: '0%',
          autoAlpha: 1,
          opacity: 1,
          ease: 'power2.out',
        },
      );

      gsap.fromTo(
        bracketRightRef.current,
        { x: '20px', autoAlpha: 0, opacity: 0 },
        {
          duration: 1.2,
          x: '0%',
          autoAlpha: 1,
          opacity: 1,
          ease: 'power2.out',
        },
      );
    } else {
      // Convert HTMLCollection to array for GSAP
      const galleryItems = Array.from(internalWrapperRef.current?.children || []);

      // Revert animations when section is not PERSONAL
      gsap.to(galleryItems, {
        y: '20px',
        autoAlpha: 0,
        opacity: 0,
        duration: 0.8,
        ease: 'power2.in',
      });

      gsap.to([bracketLeftRef.current, bracketRightRef.current], {
        x: '0px',
        autoAlpha: 0,
        opacity: 0,
        duration: 0.8,
        ease: 'power2.in',
      });
    }
  }, [activeSection, performanceLevel, isSafari]);

  const regex = /^(\w+\s+){1}(.*)$/;
  const result = WhoAmIArray.map((item) => item.replace(regex, '$2'));

  return (
    <ExternalWrapper height={`${arrayLength * 38}px`}>
      <BracketLeft ref={bracketLeftRef} />
      <BracketsWrapper>
        <InternalWrapper ref={internalWrapperRef}>
          {result.map((item, index) => (
            <ArrayItemWrapper key={index} isActive={index === Active_Index}>
              <Typography
                key={index}
                animated
                animationType="fade"
                highlight_level={index === Active_Index ? HighlightLevel.highlighted : HighlightLevel.inactive}
                variant={TypographyVariant.technical}
              >
                {item}
              </Typography>
            </ArrayItemWrapper>
          ))}
        </InternalWrapper>
      </BracketsWrapper>
      <BracketRight ref={bracketRightRef} />
    </ExternalWrapper>
  );
}
