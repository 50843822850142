import React, { useRef, useMemo, useEffect } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { gsap } from 'gsap';
import { TextureLoader } from 'three';
import { PARAMETERS } from './Particles.config';
import { useAboutMeTiles } from '../../../../contexts/UseAboutTilesContext';
import { useOptimizer } from '../../../../contexts/OptimizerContext';

interface ParticlesProps {
  depthForParticles: number;
}

// Control variables
const ZOOM_AMOUNT = 1.07; // 7% zoom increase
const KEYBOARD_ROTATION_ACCELERATION = 0.0003; // Acceleration per frame
const MAX_KEYBOARD_ROTATION_SPEED = 0.015; // Max rotation speed from keyboard input
const DAMPING_FACTOR = 0.95; // Damping factor for inertia
const MAX_ROTATION_X = Math.PI / 2; // 90 degrees
const MIN_ROTATION_X = -Math.PI / 2; // -90 degrees
const MAX_ROTATION_Y = Math.PI / 2; // 90 degrees
const MIN_ROTATION_Y = -Math.PI / 2; // -90 degrees
const MAX_MOUSE_TILT_ANGLE = Math.PI / 48; // Max tilt angle from mouse (about 11.25 degrees)
const MOUSE_TILT_RADIUS = 400; // Pixels from center where tilt reaches maximum

const Particles: React.FC<ParticlesProps> = ({ depthForParticles }) => {
  const { performanceLevel } = useOptimizer();
  const isLowPerformance = performanceLevel === 'low';
  const { selectedTileDataImg } = useAboutMeTiles();
  performance.mark('Particles');
  const particleSystemRef = useRef<THREE.Points>(null);
  const { size } = useThree();

  const isMobile = size.width <= 768;
  const isBigScreen = size.width > 1800;

  const defaultImageDataURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAE0lEQVR42mNk+M9QzwAEGgICAGH+B9xGf9QpAAAAAElFTkSuQmCC';
  const imageUrl = selectedTileDataImg || defaultImageDataURL;

  const texture = useLoader(TextureLoader, imageUrl);

  const { positions, colors } = useMemo(() => {
    if (!texture || !texture.image) {
      return { positions: null, colors: null };
    }

    const image = texture.image as HTMLImageElement;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      return { positions: null, colors: null };
    }

    let imageWidth = image.width;
    let imageHeight = image.height;

    const imageScale = isMobile ? PARAMETERS.IMAGE_SCALE_MOBILE : isBigScreen ? PARAMETERS.IMAGE_SCALE_BIG_SCREEN : PARAMETERS.IMAGE_SCALE;

    if (isMobile) {
      imageWidth = Math.floor(size.width * imageScale);
      imageHeight = Math.floor(imageWidth / PARAMETERS.IMAGE_ASPECT_RATIO_MOBILE);
    } else {
      imageWidth = Math.floor(size.width * imageScale);
      imageHeight = Math.floor(size.height * imageScale);
    }

    canvas.width = imageWidth;
    canvas.height = imageHeight;

    context.drawImage(image, 0, 0, imageWidth, imageHeight);

    const imageData = context.getImageData(0, 0, imageWidth, imageHeight).data;
    const positions: number[] = [];
    const colors: number[] = [];
    const weights = [0.2126, 0.7152, 0.0722];
    const PARTICLE_DENSITY = isMobile ? PARAMETERS.PARTICLE_DENSITY_MOBILE : PARAMETERS.PARTICLE_DENSITY;

    for (let i = 0; i < imageHeight; i += PARTICLE_DENSITY) {
      for (let j = 0; j < imageWidth; j += PARTICLE_DENSITY) {
        const index = (i * imageWidth + j) * 4;
        const r = imageData[index] / 255;
        const g = imageData[index + 1] / 255;
        const b = imageData[index + 2] / 255;
        const weight = r * weights[0] + g * weights[1] + b * weights[2];

        if (weight > PARAMETERS.BLACK_POINT && weight < PARAMETERS.WHITE_POINT) {
          const x = (j - imageWidth / 2) * 0.5;
          const y = (imageHeight / 2 - i) * 0.5;

          const z = isLowPerformance ? -PARAMETERS.DEPTH_RANGE * 0.8 + PARAMETERS.DEPTH_RANGE * weight : -depthForParticles * 0.8 + depthForParticles * weight;

          positions.push(x, y, z);
          colors.push(r * PARAMETERS.COLOR_INTENSITY, g * PARAMETERS.COLOR_INTENSITY, b * PARAMETERS.COLOR_INTENSITY);
        }
      }
    }
    const positionsArray = new Float32Array(positions);
    const colorsArray = new Float32Array(colors);

    return { positions: positionsArray, colors: colorsArray };
  }, [texture, size.width, size.height, isMobile, depthForParticles]);

  useEffect(() => {
    if (particleSystemRef.current) {
      const geometry = particleSystemRef.current.geometry;
      if (positions && colors) {
        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
      }
    }
  }, [positions, colors]);

  // Zoom-in effect when selectedTileDataImg changes
  useEffect(() => {
    if (particleSystemRef.current) {
      gsap.to(particleSystemRef.current.scale, {
        x: ZOOM_AMOUNT,
        y: ZOOM_AMOUNT,
        z: ZOOM_AMOUNT,
        duration: 0.3,
        ease: 'power2.inOut',
        onComplete: () => {
          // Scale back to normal
          if (particleSystemRef.current) {
            gsap.to(particleSystemRef.current.scale, {
              x: 1,
              y: 1,
              z: 1,
              duration: 0.3,
              ease: 'power2.inOut',
            });
          }
        },
      });
    }
  }, [selectedTileDataImg]);

  // Smooth transition effect when image changes
  useEffect(() => {
    if (particleSystemRef.current) {
      gsap.fromTo(particleSystemRef.current.material, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'power2.inOut' });
    }
  }, [selectedTileDataImg]);

  // Mouse movement control
  const mouseRotationX = useRef(0);
  const mouseRotationY = useRef(0);

  useEffect(() => {
    const handleMouseMove = (event: PointerEvent) => {
      const { innerWidth, innerHeight } = window;
      const centerX = innerWidth / 2;
      const centerY = innerHeight / 2;

      const dx = event.clientX - centerX;
      const dy = event.clientY - centerY;

      const distance = Math.sqrt(dx * dx + dy * dy);
      const maxDistance = MOUSE_TILT_RADIUS;

      // Clamp distance
      const clampedDistance = Math.min(distance, maxDistance);

      // Avoid division by zero
      const effectiveDistance = clampedDistance > 0 ? clampedDistance : 1;

      // Normalize dx and dy
      const normDX = dx / effectiveDistance;
      const normDY = dy / effectiveDistance;

      // Calculate tilt factors
      const tiltX = -normDY * (clampedDistance / maxDistance) * MAX_MOUSE_TILT_ANGLE;
      const tiltY = normDX * (clampedDistance / maxDistance) * MAX_MOUSE_TILT_ANGLE;

      // Set mouse rotation refs
      mouseRotationX.current = tiltX;
      mouseRotationY.current = tiltY;
    };

    window.addEventListener('pointermove', handleMouseMove);

    return () => {
      window.removeEventListener('pointermove', handleMouseMove);
    };
  }, []);

  // Keyboard controls with inertia
  const pressedKeys = useRef({ w: false, a: false, s: false, d: false, c: false });
  const keyboardVelocityX = useRef(0);
  const keyboardVelocityY = useRef(0);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key) {
        const key = event.key.toLowerCase();
        if (['w', 'a', 's', 'd', 'c'].includes(key)) {
          pressedKeys.current[key as keyof typeof pressedKeys.current] = true;
        }
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key) {
        const key = event.key.toLowerCase();
        if (['w', 'a', 's', 'd', 'c'].includes(key)) {
          pressedKeys.current[key as keyof typeof pressedKeys.current] = false;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Rotation state
  const rotationX = useRef(0);
  const rotationY = useRef(0);

  useFrame(() => {
    // Handle keyboard input with acceleration
    if (pressedKeys.current.w) {
      keyboardVelocityX.current -= KEYBOARD_ROTATION_ACCELERATION;
    }
    if (pressedKeys.current.s) {
      keyboardVelocityX.current += KEYBOARD_ROTATION_ACCELERATION;
    }
    if (pressedKeys.current.a) {
      keyboardVelocityY.current -= KEYBOARD_ROTATION_ACCELERATION;
    }
    if (pressedKeys.current.d) {
      keyboardVelocityY.current += KEYBOARD_ROTATION_ACCELERATION;
    }

    // Apply damping for inertia
    keyboardVelocityX.current *= DAMPING_FACTOR;
    keyboardVelocityY.current *= DAMPING_FACTOR;

    // Limit keyboard velocities
    keyboardVelocityX.current = THREE.MathUtils.clamp(keyboardVelocityX.current, -MAX_KEYBOARD_ROTATION_SPEED, MAX_KEYBOARD_ROTATION_SPEED);
    keyboardVelocityY.current = THREE.MathUtils.clamp(keyboardVelocityY.current, -MAX_KEYBOARD_ROTATION_SPEED, MAX_KEYBOARD_ROTATION_SPEED);

    // Update keyboard rotations
    rotationX.current += keyboardVelocityX.current;
    rotationY.current += keyboardVelocityY.current;

    // Handle 'C' key to centralize particles
    if (pressedKeys.current.c) {
      rotationX.current = 0;
      rotationY.current = 0;
      keyboardVelocityX.current = 0;
      keyboardVelocityY.current = 0;
    }

    // Calculate total rotations including mouse
    let totalRotationX = rotationX.current + mouseRotationX.current;
    let totalRotationY = rotationY.current + mouseRotationY.current;

    // Apply limits to rotations
    totalRotationX = THREE.MathUtils.clamp(totalRotationX, MIN_ROTATION_X, MAX_ROTATION_X);
    totalRotationY = THREE.MathUtils.clamp(totalRotationY, MIN_ROTATION_Y, MAX_ROTATION_Y);

    // Apply the rotations to the particle system
    if (particleSystemRef.current) {
      particleSystemRef.current.rotation.x = totalRotationX;
      particleSystemRef.current.rotation.y = totalRotationY;
    }
  });

  return positions && colors ? (
    <points ref={particleSystemRef} key={selectedTileDataImg}>
      <bufferGeometry>
        <bufferAttribute attach="attributes-position" array={positions} itemSize={3} count={positions.length / 3} />
        <bufferAttribute attach="attributes-color" array={colors} itemSize={3} count={colors.length / 3} />
      </bufferGeometry>
      <pointsMaterial vertexColors size={PARAMETERS.PARTICLE_SIZE} transparent opacity={1} />
    </points>
  ) : null;
};

export default Particles;
