import styled from 'styled-components';

export const ArrowSectionWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;


  z-index: 999;
`;
export const PeriodLogoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 100%;
  min-width: 70px;

  @media (max-width: 768px) {
    min-width: 40px;
    max-width: 50px;
    align-content: right:

  }
`;

export const CompanyLogo = styled.img<{ src: string; alt?: string; magnified?: any }>`
  max-height: 60px;
  max-width: 60px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.whiteMidPrimary};
  transform: scale(${({ magnified }) => (magnified ? 1.25 : 1)});
  transition: transform 0.3s ease-in-out;

  @media (min-width: 768px) {
  &:hover {
    transform: scale(${({ magnified }) => (magnified ? 1.25 : 1)}) translateX(${({ magnified }) => (magnified ? '-10px' : '0')});
  }
  }

  @media (max-width: 768px) {
    max-height: 32px;
    max-width: 32px;
  }
`;

export const ArrowContainer = styled.div`
  height: 100%;
`;

export const NameInArrowSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: fit-content;
  height: 100%;
  width: 100%;
  gap: 6px;

  @media (max-width: 768px) {
    max-width: 110px;
    min-width: 110px;
  }
`;
