import React, { useState, useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DOMPurify from 'dompurify';
import { gsap } from 'gsap';
import FormField from '../molecules/FormField';
import SendButton from '../atoms/SendButton';
import { ShortFieldsWrapper, MessageWrapper, ButtonAreaWrapper, SingleFIeldWrapper } from './ContactForm.styles';
import { sendEmail } from '../../utils/emailJsService';
import Typography from '../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import { useContextInfo } from '../sections/Contact/Contact.utils';
import GibberishDetector from 'gibberish-detective';
import * as yup from 'yup';

interface ContactFormValues {
  name: string;
  email: string;
  phone?: string;
  message: string;
}
// Initialize gibberish detector with a less strict threshold
const detector = new GibberishDetector();

// Fixing the error by removing the argument from the GibberishDetector initialization
const schema = yup.object().shape({
  name: yup
    .string()
    .required('What is your name?')
    .test('not-null', 'Name cannot be null', (value) => value !== 'null' && value !== 'undefined')
    // .test('not-gibberish', 'Enter meaningful information.', (value) => {
    //   if (!value || value.length < 2) return true; // Accept short names
    //   const isGibberish = detector.detect(value || '');
    //   return !isGibberish; // If it's gibberish, validation fails
    // })
    .trim(),
  email: yup
    .string()
    .required('Let me know your email')
    .email('Enter a valid email address')
    .test('not-null', 'Email cannot be null', (value) => value !== 'null' && value !== 'undefined')
    // .test('not-gibberish', 'Enter meaningful information.', (value) => {
    //   const isGibberish = detector.detect(value || '');
    //   return !isGibberish; // If it's gibberish, validation fails
    // })
    .trim(),
  phone: yup
    .string()
    .optional()
    .test('not-null', 'Message cannot be null', (value) => value !== 'null' && value !== 'undefined')
    .min(9, 'Phone number must be at least 9 digits')
    .matches(/^\d+$/, 'Phone number must contain only digits')
    .test('not-null', 'Phone number cannot be null', (value) => value !== 'null' && value !== 'undefined')
    .trim(),
  message: yup
    .string()
    .required('What is your message?')
    .test('not-null', 'Message cannot be null', (value) => value !== 'null' && value !== 'undefined')
    // .test('not-gibberish', 'Enter meaningful information.', (value) => {
    //   const isGibberish = detector.detect(value || '');
    //   return !isGibberish; // If it's gibberish, validation fails
    // })
    .trim(),
});

interface ContactFormProps {
  onWritingStateChange: (isWriting: boolean) => void;
  onNameChange: (name: string) => void;
  setIsGibberish: (isGibberish: boolean) => void;
  onMessageSent: () => void;
}

export default function ContactForm({ onWritingStateChange, onNameChange, setIsGibberish, onMessageSent }: ContactFormProps) {
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [buttonStatus, setButtonStatus] = useState<'idle' | 'sending' | 'success' | 'failed' | 'needToCorrect'>('idle');
  const { activeSection } = useActiveSection();
  const formRef = useRef<HTMLFormElement>(null);

  const [honeypot, setHoneypot] = useState<string>('');
  const [formLoadTime] = useState<number>(Date.now());
  const [currentName, setCurrentName] = useState('');
  const nameTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
  } = useForm<ContactFormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { updateContextInfo } = useContextInfo();

  const debouncedNameChange = useCallback(
    debounce((name: string) => {
      if (name.trim() !== '') {
        onNameChange(name);
      }
    }, 3000),
    [onNameChange],
  );

  const handleFieldFocus = useCallback(
    (fieldName: string) => {
      onWritingStateChange(true);
      if (fieldName !== 'name' && currentName.trim() !== '') {
        if (nameTimeoutRef.current) {
          clearTimeout(nameTimeoutRef.current);
        }
        onNameChange(currentName);
      }
    },
    [onWritingStateChange, onNameChange, currentName],
  );

  const handleFieldBlur = () => {
    onWritingStateChange(false);
  };

  // Existing handleNameChange function remains the same
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.value;
    setCurrentName(name);

    if (nameTimeoutRef.current) {
      clearTimeout(nameTimeoutRef.current);
    }

    if (name.trim() !== '') {
      nameTimeoutRef.current = setTimeout(() => {
        onNameChange(name);
      }, 3000);
    }

    // Check for gibberish in name field
    if (name.trim() !== '') {
      const isGibberish = detector.detect(name);
      if (isGibberish && name.length >= 2) {
        updateContextInfo('Oops, that seems like gibberish! Please try again with real words.');
        setIsGibberish(true);
      } else {
        // If the name is valid, reset the context info
        updateContextInfo(`Hello ${name}, I'm really looking forward to your thoughts. Whatever you've got to share.`);
        setIsGibberish(false);
      }
    } else {
      // If the field is empty, reset the context info
      updateContextInfo('The contact form works just great for getting in touch.');
      setIsGibberish(false);
    }

    debouncedNameChange(name);
  };

  // Add this function to handle changes in other fields
  const handleFieldChange = (fieldName: string, value: string) => {
    if (value.trim() !== '') {
      const isGibberish = detector.detect(value);
      if (isGibberish && value.length >= 2) {
        updateContextInfo('Oops, that seems like gibberish! Please try again with real words.');
        setIsGibberish(true);
      } else {
        // If the field is valid, reset the context info
        updateContextInfo("Great! I'm excited to hear what you have to say. Your message is important to me!");
        setIsGibberish(false);
      }
    } else {
      // If the field is empty, reset the context info
      updateContextInfo('The contact form works just great for getting in touch.');
      setIsGibberish(false);
    }
  };

  const onSubmit: SubmitHandler<ContactFormValues> = async (data) => {
    const isValid = await trigger();

    if (honeypot) {
      return;
    }

    const currentTime = Date.now();
    const timeDifference = currentTime - formLoadTime;
    const minimumSubmitTime = 5000;

    if (timeDifference < minimumSubmitTime) {
      setStatusMessage('You are submitting the form too quickly. Please take your time.');
      setButtonStatus('failed');
      return;
    }

    if (!isValid) {
      setButtonStatus('needToCorrect');
      setStatusMessage('Please correct the highlighted fields.');
      return;
    }

    // Sanitize inputs
    const sanitizedData = {
      name: DOMPurify.sanitize(data.name),
      email: DOMPurify.sanitize(data.email),
      phone: data.phone ? DOMPurify.sanitize(data.phone) : 'N/A',
      message: DOMPurify.sanitize(data.message),
    };

    // Check for injection patterns
    const injectionPatterns = [/<script\b[^>]*>([\s\S]*?)<\/script>/gi, /\b(null|undefined)\b/gi];

    const hasInjection = Object.values(sanitizedData).some((value) => injectionPatterns.some((pattern) => pattern.test(value)));

    // Check for gibberish using gibberish-detective
    const isGibberishDetected = Object.values(sanitizedData).some((value) => {
      // const isGibberish = detector.detect(value || '');
      // return isGibberish;
      // TODO: fix this 
      return false;
    });
    if (hasInjection || isGibberishDetected) {
      setButtonStatus('failed');
      setStatusMessage('Provide meaningful information.');
      // updateContextInfo('Oops, that seems like gibberish! Please try again with real words. 😅');

      // Set gibberish state using the prop
      // setIsGibberish(true);
      return;
    } else {
      updateContextInfo("Great! I'm excited to hear what you have to say. Your message is important to me!");
    }

    setButtonStatus('sending');
    try {
      await sendEmail({
        from_name: sanitizedData.name,
        reply_to: sanitizedData.email,
        phone: sanitizedData.phone,
        message: sanitizedData.message,
      });
      setButtonStatus('success');
      setStatusMessage('Message sent successfully!');
      onMessageSent();
    } catch (error) {
      console.error('Error sending email:', error);
      setButtonStatus('failed');
      setStatusMessage('Failed to send message. Please try again later.');
    }
  };

  useEffect(() => {
    if (activeSection === 'CONTACT' && formRef.current) {
      const elements = formRef.current.querySelectorAll('.animate');

      gsap.fromTo(
        elements,
        { autoAlpha: 0, y: 50 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.6,
          stagger: 0.2,
          ease: 'power3.out',
          delay: 0.5,
        },
      );
    } else if (formRef.current) {
      const elements = formRef.current.querySelectorAll('.animate');
      gsap.to(elements, {
        autoAlpha: 0,
        y: 50,
        duration: 0.6,
        stagger: 0.2,
        ease: 'power3.in',
      });
    }
  }, [activeSection]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setButtonStatus('needToCorrect');
    } else {
      setButtonStatus('idle');
    }
  }, [errors]);

  const handleFormChange = () => {
    if (statusMessage === 'Message sent successfully!') {
      setStatusMessage(null);
    }
    setButtonStatus('idle');
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
      <ShortFieldsWrapper>
        <SingleFIeldWrapper style={{ width: '100%' }} className="animate hoverable">
          {/* Name field remains the same */}
          <FormField
            fieldName="Name"
            control={control}
            name="name"
            index={0}
            fieldType="shortField"
            info={errors.name?.message}
            onChange={handleNameChange}
            onFocus={() => handleFieldFocus('name')}
            onBlur={handleFieldBlur}
          />
        </SingleFIeldWrapper>
        {/* Add onChange handlers to other fields */}
        <SingleFIeldWrapper style={{ width: '100%' }} className="animate hoverable">
          <FormField
            fieldName="Email"
            control={control}
            name="email"
            index={1}
            fieldType="shortField"
            info={errors.email?.message}
            onChange={(e) => handleFieldChange('email', e.target.value)}
            onFocus={() => handleFieldFocus('email')}
            onBlur={handleFieldBlur}
          />
        </SingleFIeldWrapper>
        <SingleFIeldWrapper style={{ width: '100%' }} className="animate hoverable">
          <FormField
            fieldName="Phone number (optional)"
            control={control}
            name="phone"
            index={2}
            fieldType="shortField"
            info={errors.phone?.message}
            onChange={(e) => handleFieldChange('phone', e.target.value)}
            onFocus={() => handleFieldFocus('phone')}
            onBlur={handleFieldBlur}
          />
        </SingleFIeldWrapper>
      </ShortFieldsWrapper>
      <MessageWrapper className="animate hoverable">
        <FormField
          fieldName="Message"
          control={control}
          name="message"
          index={3}
          fieldType="longField"
          info={errors.message?.message}
          onChange={(e) => handleFieldChange('message', e.target.value)}
          onFocus={() => handleFieldFocus('message')}
          onBlur={handleFieldBlur}
        />

        <input type="text" name="honeypot" value={honeypot} onChange={(e) => setHoneypot(e.target.value)} style={{ display: 'none' }} aria-hidden="true" />

        <ButtonAreaWrapper className="animate">
          <SendButton onClick={handleSubmit(onSubmit)} status={buttonStatus} />
          {statusMessage && (
            <div className="animate">
              <Typography animated animationType="write" highlight_level={HighlightLevel.neutral} variant={TypographyVariant.sectionHeader}>
                {statusMessage}
              </Typography>
            </div>
          )}
        </ButtonAreaWrapper>
      </MessageWrapper>
    </form>
  );
}
