import styled from "styled-components";
import { motion } from 'framer-motion';

export const SectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width: 100vw;
  margin-left: -0.2rem;
    
  }
`;

export const TopWrapper = styled.div`
  width: 100vw;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  overflow: visible; // Change this from 'hidden' to 'visible'
  @media (max-width: 1400px) {
    margin-left: -100px;
  }
  @media (max-width: 1200px) {
    margin-left: -200px;
  }
  @media (max-width: 1000px) {
    margin-left: -300px;
  }

`;

export const ImageWrapper = styled.div`
  width: 90%;
  height: 80%; // Reduced from 90%
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  background-color: #1557f339; // Mockup color
`;

export const StyledTileWrapper = styled.div`
  width: 400px;
  height: auto;
  min-height: 150px;
  max-width: 50%;
  position: absolute;
  top: 25%;
  left: 50%; // Center horizontally
  transform: translateX(-50%); // Adjust for center alignment
  z-index: 3;
  // border-radius: 10px;
  border: 1px solid #d6d6d6ff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;

  background-color: #000000CB;
  box-shadow: 0 0 40px 20px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    // background-color: #111111E7;
    box-shadow: 0 0 50px 25px rgba(0, 0, 0, 0.8);
  }
`;

export const BottomWrapper = styled.div`
  position: absolute;
  top: 150vh;
  left: 0;
  width: 100vw;
  height: 50%;
  z-index: 3; // Add this line
`;

export const TileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; // Ensures the image covers the entire container

  
`;

export const TileContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ParticlesWrapper = styled.div`
  position: absolute;
  top: -20%; // Extend above the TopWrapper
  left: -20%; // Extend to the left
  width: 140%; // Increase width to extend beyond the sides
  height: 140%; // Increase height to extend beyond top and bottom
  z-index: 1;
`;

export const MobileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 50%
  }
`;

export const MobileParticlesWrapper = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  top: 10%;
`;

export const MobileContentWrapper = styled.div`
  width: 100%;
  height: 56vh;
  position: absolute;
  bottom: 5%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gradient mask from top to bottom and bottom to top
  mask-image: linear-gradient(to bottom, transparent, black 20px, black calc(100% - 20px), transparent);
`;

export const GradientOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid rgba(255, 255, 255, 0.8);
  display: inline-block;
  transition: opacity 0.3s;
`;

export const GradientOverlayLeft = styled(GradientOverlay)`
  left: -84px; // Extend beyond the left edge
  background: linear-gradient(to right, rgba(25, 25, 25, 0.3) 0%, rgba(25, 25, 25, 0) 100%);
`;

export const GradientOverlayRight = styled(GradientOverlay)`
  right: -84px; // Extend beyond the right edge
  background: linear-gradient(to left, rgba(25, 25, 25, 0.3) 0%, rgba(25, 25, 25, 0) 100%);
`;

export const ArrowLeft = styled(Arrow)`
  margin-left: -22px; // Push the arrow to the right within the overlay
`;

export const ArrowRight = styled(Arrow)`
  transform: rotate(180deg);
  margin-right: -22px; // Push the arrow to the left within the overlay
`;

export const ArrowSectionWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  z-index: 999;
`;