// WordCloud.tsx
import React, { useRef, useState, useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { Billboard, Text } from '@react-three/drei';
import { gsap } from 'gsap';
import { useOptimizer } from '../../../../contexts/OptimizerContext';

// Ensure that PlaneGeometry is recognized by React Three Fiber
extend({ PlaneGeometry: THREE.PlaneGeometry });

const PIVOT_FACTOR = 0.003;
const MAX_TILT = Math.PI / 12;
const MIN_TILT = -Math.PI / 12;
const MAX_CAMERA_Z = 34;
const MIN_CAMERA_Z = 1;

interface WordProps {
  children: React.ReactNode;
  position: THREE.Vector3;
  onClick: () => void;
  onPointerOver: () => void;
  onPointerOut: () => void;
  hovered: boolean;
  active: boolean;
}

const Word: React.FC<WordProps> = ({ position, children, onClick, onPointerOver, onPointerOut, hovered, active }) => {
  const textRef = useRef<any>(null);
  const [planeSize, setPlaneSize] = useState<[number, number]>([1, 1]);
  const colorTween = useRef<gsap.core.Tween | null>(null);

  // Update the text color based on hover and active states
  useEffect(() => {
    if (textRef.current) {
      const material = textRef.current.material as THREE.MeshBasicMaterial;

      if (colorTween.current) {
        colorTween.current.kill();
      }

      let targetColor = new THREE.Color(1, 1, 1); // Default white

      if (active) {
        targetColor.setRGB(0.16, 0.68, 0.93); // Active color (e.g., blue)
      } else if (hovered) {
        targetColor.setRGB(245 / 255, 16 / 255, 16 / 255); // Hover color (e.g., red)
      }

      colorTween.current = gsap.to(material.color, {
        r: targetColor.r,
        g: targetColor.g,
        b: targetColor.b,
        duration: 0.3,
        overwrite: true,
      });
    }
  }, [hovered, active]);

  // Compute the size of the plane based on the text size
  useEffect(() => {
    if (textRef.current) {
      const size = new THREE.Vector3();
      textRef.current.geometry.computeBoundingBox();
      if (textRef.current.geometry.boundingBox) {
        textRef.current.geometry.boundingBox.getSize(size);
        setPlaneSize([size.x * 1.2, size.y * 1.5]); // Add padding
      }
    }
  }, [children]);

  return (
    <Billboard position={position}>
      <group>
        <Text
          ref={textRef}
          fontSize={0.9}
          color="white"
          anchorX="center"
          anchorY="middle"
          onSync={() => {
            // Update plane size when text is synced
            const size = new THREE.Vector3();
            textRef.current.geometry.computeBoundingBox();
            if (textRef.current.geometry.boundingBox) {
              textRef.current.geometry.boundingBox.getSize(size);
              setPlaneSize([size.x * 1.2, size.y * 1.5]); // Add padding
            }
          }}
        >
          {children}
        </Text>
        <mesh onPointerOver={onPointerOver} onPointerOut={onPointerOut} onClick={onClick} position={[0, 0, 0]}>
          <planeGeometry args={planeSize} />
          <meshBasicMaterial transparent opacity={0} depthWrite={false} />
        </mesh>
      </group>
    </Billboard>
  );
};

interface CloudProps {
  words: string[];
  radius?: number;
  isActive: boolean;
}

export const Cloud: React.FC<CloudProps> = ({ words, radius = words.length / 6.7, isActive }) => {
  const groupRef = useRef<THREE.Group>(null);
  const { camera } = useThree();
  const { performanceLevel } = useOptimizer();

  // Rotation control variables
  const rotationSpeed = 0.0065;
  const dragStart = useRef({ x: 0, y: 0 });
  const isUserInteracting = useRef(false);

  // Rotation velocity for inertia
  const rotationVelocity = useRef({ x: 0, y: 0 });
  const rotationDamping = 0.95; // Damping factor for inertia

  // Target rotation quaternion
  const [targetQuaternion, setTargetQuaternion] = useState<THREE.Quaternion | null>(null);

  // Hover state
  const [hoveredWordIndex, setHoveredWordIndex] = useState<number | null>(null);

  // Active word state
  const [activeWordIndex, setActiveWordIndex] = useState<number | null>(null);

  // Keyboard controls
  const pressedKeys = useRef<{ [key: string]: boolean }>({});

  // Handle pointer events for rotation
  useEffect(() => {
    const handlePointerDown = (event: PointerEvent) => {
      isUserInteracting.current = true;
      dragStart.current = { x: event.clientX, y: event.clientY };
    };

    const handlePointerMove = (event: PointerEvent) => {
      if (isUserInteracting.current && groupRef.current) {
        const deltaX = (event.clientX - dragStart.current.x) * rotationSpeed;
        const deltaY = (event.clientY - dragStart.current.y) * rotationSpeed;
        rotationVelocity.current.x = deltaY;
        rotationVelocity.current.y = deltaX;
        groupRef.current.rotation.y += deltaX;
        groupRef.current.rotation.x += deltaY;
        dragStart.current = { x: event.clientX, y: event.clientY };
      }
    };

    const handlePointerUp = () => {
      isUserInteracting.current = false;
    };

    window.addEventListener('pointerdown', handlePointerDown);
    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('pointerup', handlePointerUp);

    return () => {
      window.removeEventListener('pointerdown', handlePointerDown);
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, []);

  // Handle keyboard controls (WASD)
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key && typeof event.key === 'string' && ['w', 'a', 's', 'd', 'W', 'A', 'S', 'D'].includes(event.key)) {
        pressedKeys.current[event.key.toLowerCase()] = true;
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key && typeof event.key === 'string' && ['w', 'a', 's', 'd', 'W', 'A', 'S', 'D'].includes(event.key)) {
        pressedKeys.current[event.key.toLowerCase()] = false;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Handle word click to rotate the sphere and set active word
  function handleWordClick(position: THREE.Vector3, index: number) {
    if (!groupRef.current) return;

    // Set the active word index
    setActiveWordIndex(index);

    // Apply the group's current rotation to the word's position to get world coordinates
    const wordPositionWorld = position.clone().applyQuaternion(groupRef.current.quaternion);

    // Normalize the word's direction vector
    const vFrom = wordPositionWorld.clone().normalize();

    // Determine if the camera is inside or outside the sphere
    const cameraPosition = camera.position.clone();
    const distanceToCenter = cameraPosition.length();
    const isInsideSphere = distanceToCenter < radius;

    // The desired direction is along the negative or positive Z-axis
    const vTo = isInsideSphere ? new THREE.Vector3(0, 0, 1) : new THREE.Vector3(0, 0, -1);

    // Compute the angle between vFrom and vTo
    const angle = vFrom.angleTo(vTo);

    // If angle > 90 degrees (π/2), invert vTo to ensure shortest rotation
    if (angle > Math.PI / 2) {
      vTo.negate();
    }

    // Compute the rotation quaternion that rotates vFrom to vTo
    const rotationQuaternion = new THREE.Quaternion().setFromUnitVectors(vFrom, vTo);

    // Compute the new target quaternion by combining the rotation with the group's current rotation
    const targetQuaternion = rotationQuaternion.multiply(groupRef.current.quaternion.clone());

    setTargetQuaternion(targetQuaternion);
  }

  // Handle rotation towards target and keyboard controls
  useFrame(() => {
    if (groupRef.current) {
      // Apply keyboard rotations
      const rotationStep = 0.0005; // Reduced rotation step to slow down spinning

      if (pressedKeys.current['w']) {
        rotationVelocity.current.x -= rotationStep;
      }
      if (pressedKeys.current['s']) {
        rotationVelocity.current.x += rotationStep;
      }
      if (pressedKeys.current['a']) {
        rotationVelocity.current.y -= rotationStep;
      }
      if (pressedKeys.current['d']) {
        rotationVelocity.current.y += rotationStep;
      }

      // Apply inertia if not interacting and no target rotation
      if (!isUserInteracting.current && !targetQuaternion) {
        // Apply rotation velocity
        groupRef.current.rotation.x += rotationVelocity.current.x;
        groupRef.current.rotation.y += rotationVelocity.current.y;

        // Apply damping to slow down over time
        rotationVelocity.current.x *= rotationDamping;
        rotationVelocity.current.y *= rotationDamping;

        // Stop the rotation if velocity is very low
        if (Math.abs(rotationVelocity.current.x) < 0.0001) rotationVelocity.current.x = 0;
        if (Math.abs(rotationVelocity.current.y) < 0.0001) rotationVelocity.current.y = 0;
      }

      // Handle rotation towards target if not interacting
      if (targetQuaternion && !isUserInteracting.current) {
        groupRef.current.quaternion.slerp(targetQuaternion, 0.1);

        // Stop the interpolation when close enough to the target
        if (groupRef.current.quaternion.angleTo(targetQuaternion) < 0.001) {
          groupRef.current.quaternion.copy(targetQuaternion);
          setTargetQuaternion(null);
        }

        // Reset rotation velocity when rotating towards a target
        rotationVelocity.current.x = 0;
        rotationVelocity.current.y = 0;
      }

      // Lock other auto movements during mouse dragging
      if (isUserInteracting.current) {
        // Reset rotation velocity during interaction
        rotationVelocity.current.x = 0;
        rotationVelocity.current.y = 0;
      }
    }
    camera.position.z = THREE.MathUtils.clamp(camera.position.z, MIN_CAMERA_Z, MAX_CAMERA_Z);
  });

  const wordPositions = useMemo(() => {
    const temp: { position: THREE.Vector3; word: string }[] = [];
    const N = words.length;
    const goldenRatio = (1 + Math.sqrt(5)) / 2;
    const angleIncrement = Math.PI * 2 * goldenRatio;

    for (let i = 0; i < N; i++) {
      const t = (i + 0.5) / N; // Adding 0.5 for better distribution
      const inclination = Math.acos(1 - 2 * t);
      const azimuth = angleIncrement * i;

      const x = Math.sin(inclination) * Math.cos(azimuth) * radius;
      const y = Math.sin(inclination) * Math.sin(azimuth) * radius;
      const z = Math.cos(inclination) * radius;

      const position = new THREE.Vector3(x, y, z);
      temp.push({ position, word: words[i] });
    }

    return temp;
  }, [words, radius]);

  return (
    <group ref={groupRef} scale={[isActive ? 1 : 0, isActive ? 1 : 0, isActive ? 1 : 0]} rotation={[0, 0, 0]}>
      {wordPositions.map(({ position, word }, index) => (
        <Word
          key={index}
          position={position}
          onClick={() => handleWordClick(position, index)}
          onPointerOver={() => setHoveredWordIndex(index)}
          onPointerOut={() => setHoveredWordIndex(null)}
          hovered={hoveredWordIndex === index}
          active={activeWordIndex === index}
        >
          {word}
        </Word>
      ))}
    </group>
  );
};

export default Cloud;
