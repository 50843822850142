import React, { useEffect, useRef, useCallback } from 'react';
import { segmentDetails, SegmentKey } from '../../../types/segments';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import { getSectionComponent, scrollToSection, handleScroll } from './MainContainer.utils';
import { Section } from './MainContainer.styles';
import styled from 'styled-components';
import MasterScene, { MasterSceneRef } from '../../scene/MasterScene';
import { useAnalytics } from '../../../contexts/AnalyticsContext';
import { ParagraphWrapper } from '../../atoms/Typography.styles';

const GlobalSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
`;

const MainContainer: React.FC = () => {
  const { activeSection, setActiveSection } = useActiveSection();
  const { trackPageView, trackEvent } = useAnalytics();

  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  const masterSceneRef = useRef<MasterSceneRef>(null); // Ref for MasterScene

  // Refs to track section time
  const enterTimestamp = useRef<{ [key: string]: number }>({});
  const timeSpent = useRef<{ [key: string]: number }>({});
  const currentSection = useRef<string | null>(null);

  // Refs to track paragraph hover time
  const paragraphEnterTimestamp = useRef<{ [key: string]: number }>({});
  const paragraphTimeSpent = useRef<{ [key: string]: number }>({});
  const currentParagraph = useRef<string | null>(null);

  const handleInitialLoad = useCallback(() => {
    const path = window.location.pathname.slice(1); // Remove leading slash
    const segments = path.split('/');
    let mainSegment = segments[0] as SegmentKey;

    // Set default to 'PERSONAL' if no valid segment is provided
    if (!segmentDetails[mainSegment]) {
      mainSegment = 'PERSONAL' as SegmentKey;
    }

    setActiveSection(mainSegment);
    const currentIndex = Object.keys(segmentDetails).indexOf(mainSegment);
    setTimeout(() => {
      scrollToSection(currentIndex, sectionRefs, setActiveSection);
    }, 100);

    // Preserve the hash if it's the DOCS section
    if (mainSegment !== 'DOCS') {
      window.history.replaceState({}, '', `/${mainSegment}`);
    }
  }, [setActiveSection]);

  useEffect(() => {
    handleInitialLoad();
    const onScroll = () => {
      handleScroll(sectionRefs, setActiveSection, scrollTimeout);
      if (masterSceneRef.current) {
        masterSceneRef.current.updateScrollPosition(window.scrollY); // Update scrollHeight via state
      }
    };
    window.addEventListener('scroll', onScroll);

    const onMouseMove = (e: MouseEvent) => {
      if (masterSceneRef.current) {
        masterSceneRef.current.updateMousePosition(e.clientX, e.clientY);
      }
    };
    window.addEventListener('mousemove', onMouseMove);

    const handleBeforeUnload = () => {
      markExitSection();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [setActiveSection, handleInitialLoad]);

  useEffect(() => {
    // Calculate the time spent in the previous section
    if (currentSection.current) {
      const previousSection = currentSection.current;
      const timeSpentOnSection = Date.now() - enterTimestamp.current[previousSection];
      timeSpent.current[previousSection] = (timeSpent.current[previousSection] || 0) + timeSpentOnSection;

      // Track time spent event
      trackEvent('Time Spent', `Section: ${previousSection}`, `${timeSpentOnSection / 1000} seconds`);

      // Log the time spent for debugging purposes
      console.log(`Time spent on section ${previousSection}: ${timeSpentOnSection / 1000} seconds`);
    }


      // Set the enter timestamp for the new section
      enterTimestamp.current[activeSection] = Date.now();
      currentSection.current = activeSection;

      // Track page view for the new section
      trackPageView(`Section: ${activeSection}`);

      // Scroll to the section
      const currentIndex = Object.keys(segmentDetails).indexOf(activeSection);
      scrollToSection(currentIndex, sectionRefs, setActiveSection);
      window.history.pushState({}, '', `/${activeSection}`);

      // Modify the history push to preserve the hash for DOCS
      if (activeSection === 'DOCS') {
        const currentHash = window.location.hash;
        window.history.pushState({}, '', `/${activeSection}${currentHash}`);
      } else {
        window.history.pushState({}, '', `/${activeSection}`);
      }
  }, [activeSection, trackEvent, trackPageView, setActiveSection]);

  const markExitSection = () => {
    if (currentSection.current) {
      const section = currentSection.current;
      const timeSpentOnSection = Date.now() - enterTimestamp.current[section];
      timeSpent.current[section] = (timeSpent.current[section] || 0) + timeSpentOnSection;

      // Track exit event
      trackEvent('Exit Section', `Section: ${section}`, `Exited after ${timeSpentOnSection / 1000} seconds`);

      // Log the exit event for debugging purposes
      console.log(`User exited section ${section} after ${timeSpentOnSection / 1000} seconds`);
    }
  };

  // Current scroll state
  const totalSections = Object.keys(segmentDetails).length;
  const sectionHeight = window.innerHeight;
  const globalHeight = totalSections * sectionHeight;

  return (
    <GlobalSectionWrapper>
      <MasterScene sectionNames={Object.keys(segmentDetails)} ref={masterSceneRef} totalSections={totalSections} globalHeight={globalHeight} sectionHeight={sectionHeight} />
      {Object.entries(segmentDetails).map(([key, { name }], index) => {
        const SectionComponent = getSectionComponent(key as SegmentKey);
        return (
          <Section
            fullWidth={key === 'PERSONAL'}
            key={key}
            data-segment-key={key}
            ref={(el) => {
              if (el) sectionRefs.current[index] = el;
            }}
          >
            <SectionComponent />
          </Section>
        );
      })}
    </GlobalSectionWrapper>
  );
};

export default MainContainer;
