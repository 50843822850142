import styled, { css } from 'styled-components';
import { TileWrapper } from '../Experience/Experience.styles';

// Styled components
export const SectionWrapper = styled.div`
  height: 96%;
  display: flex;
  flex-direction: row;
  gap: 18px;
  margin-left: -10px;

  @media (min-width: 1000px) {
    margin-left: -71px;
  }
  // 768 to 900px
  @media (max-width: 900px) {
    flex-direction: row;
  }

  @media (max-width: 1000px) and (min-width: 901px) {
    margin-left: -90px;
  }

  @media (max-width: 769px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }

  @media (min-width: 1800px) {
    margin-left: -8vw;
  }
`;

export const DocsGalleryWrapper = styled.div`
  margin-top: -35px;

  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1200px) and (min-width: 769px) {
    min-width: 250px;
  }

  @media (max-width: 900px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    max-height: 220px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    transform: translateX(-20px);
    overflow-y: hidden;

    mask-image: linear-gradient(to right, transparent, black 35px, black calc(100% - 50px), transparent);
    -webkit-mask-image: linear-gradient(to right, transparent, black 35px, black calc(100% - 50px), transparent);
    width: 96vw;
  }

  @media (min-width: 768px) {
    min-width: 30%;
    padding-bottom: 65px;
    mask-image: linear-gradient(to bottom, transparent, black 35px, black calc(100% - 50px), transparent);
    -webkit-mask-image: linear-gradient(to bottom, transparent, black 35px, black calc(100% - 50px), transparent);
  }
`;

export const MarkdownBlockWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    width: 96%;
    height: 48vh;
  }
`;

export const DocPreviewWrapper = styled.div`
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: calc(100% - 50px);
  max-height: calc(100% - 50px);
  height: calc(100% - 50px);

  border-radius: 10px;
  * {
    max-width: unset !important;
  }

  @media (min-width: 768px) {
    min-width: 42vw;
    max-width: 42vw;
  }

  * img {
    width: 100%;
    height: auto;
    height: auto;
  }

  @media (max-width: 900px) and (min-width: 769px) {
    min-width: 45vw;
    max-width: 45vw;
  }

  @media (max-width: 768px) {
    margin-top: -10px;
    max-width: 90vw;
    min-height: 60vh;
    max-height: 60vh;

    width: 96%;
    overflow-y: auto; /* Changed from hidden to auto */
    overflow-x: hidden; /* Ensure no horizontal scrolling */
  }

  @media (max-width: 900px) {
    display: flex;
    // justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: 100%;
  height: 35px;
`;

export const ExpandableOnHover = styled.div``;

export const TagWrapper = styled.div<{ isActive: boolean; ishovered: boolean }>`
  display: flex;
  padding: 3px 6px 3px 6px;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.greyPrimary25};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 70%;
    color: ${({ theme }) => theme.colors.whiteMedium};
    // flatten in x
    transform: scaleY(0.8);
  }

  ${({ ishovered, isActive }) =>
    ishovered &&
    css`
      background-color: ${({ theme }) => theme.colors.whiteLight};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.whiteMedium};
    `}
`;

export const DocInfoWrapper = styled.div`
  width: 100%;
  //   min-height: 180px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (max-width: 768px) {
    height: 100%;
    border-radius: 10px;
    max-height: 218px;
  }
`;

export const StyledTileWrapper = styled(TileWrapper)`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px !important;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 230px;
    overflowy: auto;
    width: 170px;
    // border-radius: 10px;
  }
`;

export const Spacer = styled.div`
  display: block;
  width: 100%;
  min-height: 17px;
`;
