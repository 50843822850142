import styled from "styled-components";

export const MenuContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 3px;
  margin-right: 12px;
  z-index: 50;
  @media (max-width: 1220px) {
    transform: scale(0.85) translateX(15%) translateY(-55%);
  }
  @media (max-width: 1120px) {
    transform: scale(0.7) translateX(29%) translateY(-70%);
  }
`;

export const MenuItemWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const HoverRectangle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 184px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.greyPrimary25};
  border-radius: 10px;
  z-index: -1;
  transition: 0.3s;
`;

export const BurgerMenuContainer = styled.div`
  .bm-burger-button {
    position: fixed;
    width: 28px;
    height: 28px;
    right: 18px;
    top: 14px;
    background-color: ${({ theme }) => theme.colors.greyMidPrimary};
    z-index: 9999; /* Ensure it stays on top of the ::after element */
  }

  .bm-burger-button::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    background: ${({ theme }) => theme.colors.greyDark};
    border-radius: 5px; /* Half of height for full rounding */
    z-index: -1; /* Place behind the main button */
    top: 50%; /* Center vertically relative to .bm-burger-button */
    left: 50%; /* Center horizontally relative to .bm-burger-button */
    transform: translate(-50%, -50%); /* Ensure the pseudo-element is centered */
    drop-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a shadow */
  }
  .bm-burger-bars {
    background: ${({ theme }) => theme.colors.greyMidPrimary};
  }

  .bm-menu {
    background: ${({ theme }) => theme.colors.greyDark};
    padding: 0em 0.8em 0.8em 0.8em;
    font-size: 1.15em;
  }

  .bm-item-list {
    width: 80%;
    display: flex;
    flex-direction: column;
    scale: 0.95;
  }

  .bm-item {
    display: inline-block;
    margin-bottom: 4px;
    color: #c70000;
    text-decoration: none;
    transition: color 0.2s;
  }

  .bm-cross-button {
    width: 36px;
    height: 30px;
    right: 56px;
    top: 56px;
    transform: scale(3) translate(-4px, 4px);
  }

  .bm-cross {
    background: #bdc3c7;
  }

  .bm-overlay {
    background: rgba(217, 217, 217, 0.8);
  }
`;
