import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';
import { TileWrapper } from '../sections/Experience/Experience.styles';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import { TechDescBlockWrapper, EntityWrapper, TechContainer, LogoWrapper, TechLogoImage, DescTechWrapper, TechWrapper, LineConnector } from './TechDescBlock.styles';

const TypographyContainer = styled.div<{ variant: TypographyVariant; highlight_level: HighlightLevel }>`
  font-size: ${({ variant }) => (variant === TypographyVariant.sectionHeaderThin ? '24px' : '16px')};
  color: ${({ highlight_level }) => (highlight_level === HighlightLevel.active ? 'white' : 'gray')};
  text-align: left;
  flex-shrink: 0;
  max-width: 94%;
  padding: 12px 10px;
  opacity: 0; /* Start with opacity 0 for animation */
  transition: opacity 0.5s ease; /* Smooth fade transition */

  @media (max-width: 768px) {
    padding: 3px 10px;
    font-size: 14px;
  }
`;

// Function to replace *text* with <b>text</b>
const processChildren = (children: React.ReactNode): React.ReactNode => {
  if (typeof children === 'string') {
    return children.split(/(\*[^*]+\*)/g).map((part, index) => {
      if (part.startsWith('*') && part.endsWith('*')) {
        return <b key={index}>{part.slice(1, -1)}</b>;
      }
      return part;
    });
  }
  return children;
};

const Typography: React.FC<{
  variant: TypographyVariant;
  highlight_level?: HighlightLevel;
  capitalize?: boolean;
  children: React.ReactNode;
  className?: string; // Allow className as a prop
}> = ({ variant, highlight_level = HighlightLevel.neutral, capitalize, children, className }) => {
  return (
    <TypographyContainer variant={variant} highlight_level={highlight_level} className={className}>
      {capitalize ? processChildren(children)?.toString()?.toUpperCase() : processChildren(children)}
    </TypographyContainer>
  );
};

// Main Component
type TechDescBlockProps = {
  techDescArray: { tech: string; desc: string; url?: string }[];
};

const TechDescBlock: React.FC<TechDescBlockProps> = ({ techDescArray }) => {
  const [ishovered, setishovered] = useState(false);
  const techRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // Initial animation for lines and text
    gsap.to(containerRefs.current, {
      opacity: 1,
      duration: 0.5,
      delay: 0.5,
      stagger: 0.2,
    });
    gsap.to('.line-connector', {
      width: '100%',
      duration: 1,
      delay: 0.5,
      stagger: 0.2,
      ease: 'power2.out',
    });
    gsap.to('.tech-text', {
      opacity: 1,
      duration: 0.5,
      delay: 1,
      stagger: 0.2,
      ease: 'power2.out',
    });
  }, []);

  const isMobile = window.innerWidth < 1200;
  useEffect(() => {
    techRefs.current.forEach((ref, index) => {
      if (ref) {
        if (isMobile) {
          // On mobile, set the height to auto and ensure content is fully visible
          ref.style.height = 'fit-content';
          ref.style.opacity = '1';
        } else {
          // On non-mobile, adjust height and opacity based on hover state
          const height = ishovered ? ref.scrollHeight : 30;
          gsap.to(ref, { height, opacity: ishovered ? 1 : 0.7, duration: 0.5 });
          gsap.to(ref, { autoAlpha: ishovered ? 1 : 0.7, duration: 0.5 });
        }
      }
    });
  }, [ishovered, isMobile]);
  return (
    <TileWrapper>
      <TechDescBlockWrapper>
        <EntityWrapper onMouseEnter={() => setishovered(true)} onMouseLeave={() => setishovered(false)}>
          {techDescArray.map((techItem, index) => (
            <TechContainer key={index} ref={(el) => (containerRefs.current[index] = el)}>
              <LogoWrapper>{techItem.url && <TechLogoImage src={techItem.url} alt="Tech logo" />}</LogoWrapper>
              <DescTechWrapper condensed={ishovered && !isMobile}>
                <TechWrapper ref={(el) => (techRefs.current[index] = el)}>
                  {!ishovered && !isMobile && <LineConnector className="line-connector" />}
                  <Typography
                    variant={ishovered || isMobile ? TypographyVariant.sectionDescription : TypographyVariant.sectionHeaderThin}
                    highlight_level={HighlightLevel.active}
                    className="tech-text"
                  >
                    {ishovered || isMobile ? techItem.desc : techItem.tech}
                  </Typography>
                </TechWrapper>
              </DescTechWrapper>
            </TechContainer>
          ))}
        </EntityWrapper>
      </TechDescBlockWrapper>
    </TileWrapper>
  );
};

export default TechDescBlock;
