import styled from 'styled-components';

export const StyledSection = styled.div`
  height: 100% !important;
  width: 100% !important;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  // height: 100vh !important;
  // width: 100vw !important;
`;

export const RingsWrapper = styled.div`
  min-width: 800px;
  height: 100%;
  margin-top: -12vh;
  margin-left: 6vw;

  @media (max-width: 1800px) and (min-width: 1601px) {
    margin-left: -2vw;
  }

  @media (max-width: 1600px) and (min-width: 1401px) {
    margin-left: -6vw;
  }

  @media (max-width: 1400px) and (min-width: 1201px) {
    height: 80vh;
    margin-top: -10vh;
  }

  @media (max-width: 1200px) and (min-width: 901px) {
    height: 76vh;
    margin-top: -12vh;
    margin-left: -8vw;
  }

  @media (max-width: 900px) and (min-width: 769px) {
    margin-top: -10vh;
    margin-left: -140px;
    height: 70vh;
  }
  @media (max-width: 768px) {
    position: absolute;
    margin-top: -5vh;
    top: 0;
    right: 50%;
    height: 100vw;
  }

  position: relative;

  // TODO old place for rings background shadows
  // z-index: 1;
  // background: linear-gradient(90deg, rgba(2, 0, 33, 0) 0%, rgba(2, 2, 2, 0.9066001400560224) 25%, rgba(2, 2, 2, 0) 70%);

  // mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
  // -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);

  width: 40%;
`;

export const RyjWrapper = styled.div`
  margin-top: -6vh;
  margin-left: auto;
  position: relative;
  z-index: 2;
  width: 40%
  height: 110vh;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);

  @media (min-width: 1601px) {
    left: 0%;
  }
 @media (max-width: 1600px) and (min-width: 1401px) {
    left: 10%
  }
  @media (max-width: 1400px) and (min-width: 1000px) {
    left: -10%
  }
  @media (max-width: 1200px) and (min-width: 900px) {
    left: -12%
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    left: -40%
  }
  @media (max-width: 900px) and (min-width: 768px) {
    left: -35%
    }
    @media (max-width: 768px) {
      left: -20%
      position: absolute;
      left: 25%;
  }

`;

export const LetsGoWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 10%;
  bottom: 4vh;
  right: 1vw;
`;

export const IAmWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 15vh;
  position: absolute;

  bottom: 0;
  left: 42%;
  z-index: 10;
  transform: translate(2vw, 0vh);

  white-space: nowrap;
  min-width: 550px;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1400px) {
    margin-left: -20%;
    transform: translate(0vw, 8vh) scale(0.85);
  }
  @media (max-width: 1400px) and (min-width: 1000px) {
    margin-left: -20%;
  }
  @media (max-width: 1200px) and (min-width: 900px) {
    margin-left: -22%;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
    margin-left: -30%;
  }
  @media (max-width: 900px) and (min-width: 768px) {
    margin-left: -15%;
  }
  @media (max-width: 768px) {
    max-width: 50vw;
    white-space: wrap;
    min-width: 350px
    max-width: 350px;
    position: absolute;
    bottom: 70px;
    left: 10%;
  }
`;

export const Image = styled.img`
  height: 100%;
  object-fit: contain;
  position: relative;
  left: 0;
`;
