import styled, { css } from "styled-components";

export const CircleButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.whiteLight};
  font-size: 24px;
  text-decoration: none;
  border: 2px solid ${({ theme }) => theme.colors.whiteLight};
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  background-color: transparent;
`;

export const ButtonContainer = styled.div<{ locationTop: boolean; top: number; right: number }>`

  display: flex;
  flex-direction: column;
  position: fixed;
  ${({ locationTop, top }) => (
    locationTop ? `top: ${top}px; right: 0px;` : 'bottom: -8dvh; left: 100%; position: fixed; transform: translate(0, 0)')}

  scale: ${({ locationTop }) => (locationTop ? '1' : '0.75')};

  @media (max-width: 768px) {
    scale: ${({ locationTop }) => (locationTop ? '1' : '0.75')};
    margin-bottom: 0px;
  }

  @media (max-height: 900px) {
    ${({ locationTop }) =>
      locationTop && `
       display: none;
    `}
  }

  ${({ locationTop }) =>
    locationTop &&
    css`
      @media (max-height: 900px) {
        display: none;
      }
    `}

  @media (max-height: 900px) {
    scale: 0.75;
  }

  @media (min-width: 900px) {
    scale: 1;
    top: 8px;
    right: 8px;
  }
  z-index: 10;
`;

export const BigCirclesButton = styled(CircleButton)<{ status?: 'idle' | 'sending' | 'success' | 'failed' | 'needToCorrect' }>`
  width: 80px;
  height: 80px;

  svg {
    width: 38px;
    height: 38px;
  }

  @media (max-width: 900px) {
    width: 60px;
    height: 60px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: none !important;

  ${({ status = 'idle', theme }) => {
    switch (status) {
      case 'success':
        return `
          color: ${theme.colors.accentGreen};
          border-color: ${theme.colors.accentGreen};
          box-shadow: 0px 0px 31px 5px ${theme.colors.accentGreen};
        `;
      case 'failed':
        return `
          color: ${theme.colors.warning};
          border-color: ${theme.colors.warning};
          box-shadow: 0px 0px 31px 5px ${theme.colors.warning};
        `;
      case 'needToCorrect':
        return `
          border-color: ${theme.colors.warning};
          color: ${theme.colors.whiteLight}; // Default color for the icon
        `;
      case 'idle':
      default:
        return `
          color: ${theme.colors.whiteLight};
          border-color: ${theme.colors.whiteLight};
        `;
    }
  }}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: #812222ab;
  }
`;
export const BigCircleButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 900px) {
    gap: 6px;
  }
`;

export const ButtonBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  gap: 22px;
  
`;