import styled from 'styled-components';

export const SectionWrapper = styled.div`
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: auto;
  margin-left: auto;
  gap: 16px;
  opacity: 0; /* Initially hide the entire section */

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin-top: -50px;
    gap: 0px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  // height: 500px;
  
`;

