import React, { useState } from "react";
import { SectionContainer } from "./Section.styles";

interface SectionProps {
  children: React.ReactNode;
  title: string;
}

const Section: React.FC<SectionProps> = ({ title, children }) => {
  const isPersonalSection = title === "PERSONAL";
  return (
    <SectionContainer fullPageWidth={isPersonalSection}>
      {children}
    </SectionContainer>
  );
};

export default Section;
