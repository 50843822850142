export const getMediaUrlList = (gallery: { data?: { attributes?: { url?: string } }[] } | null | undefined) => {
  try {
    if (!gallery?.data) {
      return [];
    }
    return gallery.data.map((image) => `${image?.attributes?.url ?? ''}`).filter(Boolean);
  } catch (error) {
    console.error('Error processing gallery data:', error);
    return [];
  }
};
