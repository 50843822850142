import React, { forwardRef } from 'react';
import { format } from 'date-fns';
import { ArrowSectionWrapper, PeriodLogoSection, CompanyLogo, NameInArrowSection } from './ArrowSection.styles';
import Arrow from '../atoms/Arrow';
import Typography from '../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';

interface CompanyLogoAttributes {
  url: string;
}

interface CompanyLogoData {
  attributes: CompanyLogoAttributes;
}

interface ArrowSectionProps {
  index: number;
  isActive: boolean;
  getHighlightLevel: (index: number) => HighlightLevel;
  item: {
    start_date?: string;
    end_date?: string;
    company_logo?: {
      data: CompanyLogoData[];
    };
    company_name?: string;
    project_name?: string;
    position_name?: string;
  };
}

const ArrowSection = forwardRef<HTMLDivElement, ArrowSectionProps>(({ index, item, isActive, getHighlightLevel }, ref) => {
  const logoUrl = item.company_logo?.data?.[0]?.attributes?.url;
  const isMobile = window.innerWidth < 768;

  return (
    <ArrowSectionWrapper ref={ref} className="hoverable">
      <PeriodLogoSection>
        {item.end_date && (
          <Typography align={isMobile ? 'right' : 'left'} animated animationType="fade" variant={TypographyVariant.micro} highlight_level={getHighlightLevel(index)} capitalize>
            {format(new Date(item.end_date), 'MMM yyyy')}
          </Typography>
        )}
        {logoUrl && <CompanyLogo src={logoUrl} alt="Company logo" magnified={isActive.toString()} />}
        {item.start_date && (
          <Typography align={isMobile ? 'right' : 'left'} animated animationType="fade" variant={TypographyVariant.micro} highlight_level={getHighlightLevel(index)} capitalize>
            {format(new Date(item.start_date), 'MMM yyyy')}
          </Typography>
        )}
      </PeriodLogoSection>
      <Arrow />
      <NameInArrowSection>
        {item.company_name && (
          <Typography animated animationType="fade" highlight_level={getHighlightLevel(index)} variant={TypographyVariant.sectionHeader}>
            {item.company_name}
          </Typography>
        )}
        {item.project_name && (
          <Typography animated animationType="fade" variant={TypographyVariant.sectionSubHeader} highlight_level={getHighlightLevel(index)}>
            {item.project_name}
          </Typography>
        )}
        {item.position_name && (
          <Typography animated animationType="fade" variant={TypographyVariant.sectionSubHeader} highlight_level={getHighlightLevel(index)}>
            {item.position_name}
          </Typography>
        )}
      </NameInArrowSection>
    </ArrowSectionWrapper>
  );
});

export default ArrowSection;
