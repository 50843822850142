// PARTICLES
// MOST UNUSABLE to DUMP

import { useMemo } from "react";

export const PARAMETERS = {
  PARTICLE_SIZE: 0.03,
  PARTICLE_DENSITY: 1,
  PARTICLE_DENSITY_MOBILE: 1,
  DEPTH_RANGE: 200,
  WHITE_POINT: 1.5,
  BLACK_POINT: 0.15,
  COLOR_INTENSITY: 0.8,
  CAMERA_FOV: 68,
  CAMERA_FOV_MOBILE: 65,
  CAMERA_NEAR: 0.1,
  CAMERA_FAR: 1400,
  CAMERA_POSITION_Z: 300,
  IMAGE_SCALE: 0.9,
  IMAGE_SCALE_MOBILE: 1,
  IMAGE_ASPECT_RATIO_MOBILE: 1.6,
  ROTATION_SPEED: 1.0,
  ZOOM_SPEED: 1.2,
  PAN_SPEED: 1.8,
  MOUSE_SENSITIVITY: 0.002,
  TILT_LIMIT: 0.2,
  LERP_FACTOR: 0.1,
  WARP_RADIUS: 150,
  WARP_STRENGTH: 35,
  WARP_FALLOFF: 1,
  RESET_SPEED: 1,
  INITIAL_CAMERA_Z: 700,
  FINAL_CAMERA_Z: 820,
  FINAL_CAMERA_Z_MOBILE: 240,
  FINAL_CAMERA_Z_BIG_SCREEN: 600,
  INITIAL_ANIMATION_DURATION: 1.5,
  MOUSE_ZOOM_FACTOR: 0.1,
  ZOOM_SMOOTHING: 0.3,
  TILT_SMOOTHING: 0.1,
  INITIAL_PARTICLE_SIZE: 0.5,
  FINAL_PARTICLE_SIZE: 1,
  OUTRO_CAMERA_Z: 600,
  OUTRO_ANIMATION_DURATION: 1,
  WHEEL_ZOOM_FACTOR: 0.001,
  MIN_ZOOM: 1,
  MAX_ZOOM: 1.3,
};

export interface TileData {
  tile_name: string;
  middle_priority: number;
  tile_content: string;
  tile_image?: {
    data: Array<{
      attributes: {
        url: string;
      };
    }>;
  };
}