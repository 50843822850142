import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

export const createStartTimeScheduler = (
  sectionCount: number,
  initialDelay = 0,
  interval = 6000,
  subInterval = 600,
): Observable<number[]> => {
  return new Observable<number[]>((subscriber) => {
    const startTimes = Array(sectionCount)
      .fill(initialDelay)
      .map((_, index) => initialDelay + index * subInterval);

    timer(0, interval).subscribe(() => {
      subscriber.next(startTimes);
    });
  });
};
