import React, { createContext, useContext, useEffect, useRef, useCallback } from 'react';
import ReactGA from 'react-ga4';

interface AnalyticsContextType {
  trackPageView: (page: string) => void;
  trackEvent: (category: string, action: string, label?: string) => void;
  startTiming: (category: string, variable: string) => void;
  endTiming: (category: string, variable: string, label?: string) => void;
  startSection: (section: string) => void;
  endSection: (section: string) => void;
  getAverageTimeOnSection: () => void;
  markExitSection: () => void;
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const timings = useRef<{ [key: string]: number }>({});
  const sectionTimeSpent = useRef<{ [key: string]: { totalTime: number; visitCount: number } }>({});
  const enterTimestamp = useRef<{ [key: string]: number }>({});
  const currentSection = useRef<string | null>(null);

  useEffect(() => {
    ReactGA.initialize('G-V0YNLCE0B7');

    // Track page view on initial load
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

    // Track exit sections
    const handleBeforeUnload = () => {
      markExitSection();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const trackPageView = useCallback((page: string) => {
    ReactGA.send({ hitType: 'pageview', page });
  }, []);

  const trackEvent = useCallback((category: string, action: string, label?: string) => {
    ReactGA.event({ category, action, label });
  }, []);

  const startTiming = useCallback((category: string, variable: string) => {
    timings.current[`${category}-${variable}`] = Date.now();
  }, []);

  const endTiming = useCallback((category: string, variable: string, label?: string) => {
    const startTime = timings.current[`${category}-${variable}`];
    if (startTime) {
      const duration = Date.now() - startTime;
      ReactGA.send({ hitType: 'timing', timingCategory: category, timingVar: variable, timingValue: duration, timingLabel: label });
      delete timings.current[`${category}-${variable}`];
    }
  }, []);

  // Start tracking time for a specific section
  const startSection = useCallback((section: string) => {
    console.log('startSection');
    markExitSection(); // Record the end of any previous section
    enterTimestamp.current[section] = Date.now();
    currentSection.current = section;
  }, []);

  // End tracking time for a specific section
  const endSection = useCallback((section: string) => {
    console.log('endSection');
    if (enterTimestamp.current[section]) {
      const duration = Date.now() - enterTimestamp.current[section];
      if (!sectionTimeSpent.current[section]) {
        sectionTimeSpent.current[section] = { totalTime: 0, visitCount: 0 };
      }
      sectionTimeSpent.current[section].totalTime += duration;
      sectionTimeSpent.current[section].visitCount += 1;

      // Log event for average time on the section
      ReactGA.send({ hitType: 'timing', timingCategory: 'Section Engagement', timingVar: 'Time Spent', timingValue: duration, timingLabel: section });
      delete enterTimestamp.current[section];
    }
  }, []);

  // Mark the section that the user was in before leaving the page
  const markExitSection = useCallback(() => {
    console.log('markExitSection');
    if (currentSection.current) {
      endSection(currentSection.current);
      ReactGA.event({ category: 'Section Exit', action: `User exited`, label: currentSection.current });
    }
  }, [endSection]);

  const getAverageTimeOnSection = useCallback(() => {
    Object.entries(sectionTimeSpent.current).forEach(([section, data]) => {
      const avgTime = data.totalTime / data.visitCount;
      console.log(`Average time spent on ${section}: ${avgTime / 1000} seconds`);
    });
  }, []);

  return (
    <AnalyticsContext.Provider value={{ trackPageView, trackEvent, startTiming, endTiming, startSection, endSection, getAverageTimeOnSection, markExitSection }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};
