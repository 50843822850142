import styled from 'styled-components';

export const SectionContainer = styled.div<{ fullPageWidth?: boolean }>`
  /* Default styling for larger screens */
  width: ${(props) => (props.fullPageWidth ? '100%' : '40%')};
  height: 88%;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;

  /* For screens wider than 1600px (large desktops) */
  @media (max-width: 2000px) and (min-width: 1601px) {
    width: ${(props) => (props.fullPageWidth ? '100%' : '55%')};
    height: 92%;
  }

  /* For screens between 1400px and 1600px (desktops) */
  @media (max-width: 1600px) and (min-width: 1401px) {
    width: ${(props) => (props.fullPageWidth ? '100%' : '70%')};
    height: 90%;
  }

  /* For screens between 1200px and 1400px (desktops) */
  @media (max-width: 1400px) and (min-width: 1201px) {
    width: ${(props) => (props.fullPageWidth ? '100%' : '68%')};
    height: 90%;
  }

  /* For screens between 768px and 1200px (tablets and small desktops) */
  @media (max-width: 1200px) and (min-width: 769px) {
    width: ${(props) => (props.fullPageWidth ? '100%' : '68%')};
    height: 88%;
  }

  /* For screens between 576px and 768px (landscape phones and small tablets) */
  @media (max-width: 768px) and (min-width: 577px) {
    width: ${(props) => (props.fullPageWidth ? '100%' : '98%')};
    height: 94%;
    padding-top: 60px;
    margin-right: auto;
    margin-left: auto;
  }

  /* For screens smaller than 576px (phones) */
  @media (max-width: 576px) {
    width: ${(props) => (props.fullPageWidth ? '100%' : '98%')};
    height: 95%;
    padding: 0.8rem; /* Add padding for better spacing */
    padding-top: 60px;
    margin-right: auto;
    margin-left: auto;
  }
`;

export {};
