// MainContainer.utils.ts
import { SegmentKey } from '../../../types/segments';
import * as Sections from '../../sections';
import { RefObject } from 'react';
import { segmentDetails } from '../../../types/segments';
import React from 'react';

// Define getSectionComponent, scrollToSection, and handleScroll functions

export const getSectionComponent = (key: SegmentKey) => {
  switch (key) {
    case SegmentKey.PERSONAL:
      return Sections.Welcome;
    case SegmentKey.ABOUT_ME:
      return Sections.AboutMe;
    case SegmentKey.EXPERIENCE:
      return Sections.Experience;
    case SegmentKey.EDUCATION:
      return Sections.Education;
    // case SegmentKey.PROJECTS:
    //   return Sections.Projects;
    case SegmentKey.DOCS:
      return Sections.Docs;
    case SegmentKey.CERTIFICATIONS:
      return Sections.Certifications;
    case SegmentKey.TECH_STACK:
      return Sections.TechStack;
    case SegmentKey.CONTACT:
      return Sections.Contact;
    default:
      return () => <div>Unknown Section</div>;
  }
};

export const scrollToSection = (index: number, sectionRefs: RefObject<HTMLDivElement[]>, setActiveSection: (segmentKey: SegmentKey) => void) => {
  const section = sectionRefs.current ? sectionRefs.current[index] : null;
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setActiveSection(Object.keys(segmentDetails)[index] as SegmentKey);
  }
};

export const handleScroll = (sectionRefs: RefObject<HTMLDivElement[]>, setActiveSection: (segmentKey: SegmentKey) => void, scrollTimeout: { current: NodeJS.Timeout | null }) => {
  if (scrollTimeout.current) {
    clearTimeout(scrollTimeout.current);
  }

  scrollTimeout.current = setTimeout(() => {
    const sectionIndex = sectionRefs.current?.findIndex((section) => {
      const rect = section?.getBoundingClientRect();
      return rect?.top < window.innerHeight / 2 && rect?.bottom > window.innerHeight / 2;
    });

    if (sectionIndex !== -1 && sectionIndex !== undefined) {
      setActiveSection(Object.keys(segmentDetails)[sectionIndex] as SegmentKey);
    } else {
      const midSectionIndex = Math.round(window.scrollY / window.innerHeight);
      scrollToSection(midSectionIndex, sectionRefs, setActiveSection);
    }
  }, 100); // Increase the delay to reduce scroll jank
};
