import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import throttle from 'lodash/throttle';

import offset from './../../../assets/bg-layers/offset-2.png';
import offsetLight from './../../../assets/bg-layers/offset-2-light.jpeg';
import offNTreeLayer from './../../../assets/bg-layers/no-tree-overlay.png';
import tree from './../../../assets/bg-layers/tree-n-bg-transparent.png';
import MRIImageDisplay from '../../organisms/MRIImageDisplay';
import { useOptimizer } from '../../../contexts/OptimizerContext';
import { useLoading } from '../../../contexts/LoadingContext';
import MotionDot from '../../atoms/MotionDot';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
  background-color: #060606;
`;

const BackgroundImage = styled.div<{ backgroundLoaded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${offset});
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  z-index: 1;
  opacity: ${(props) => (props.backgroundLoaded ? 1 : 0)};
  transition: opacity 0.5s ease-in;

  /* Media query for small screens */
  @media (max-width: 767px) {
    background-image: url(${offsetLight});
  }
`;

const OverlayImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${offNTreeLayer});
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  z-index: 3;
  pointer-events: none;
  mask: url(#soft-mask);
  mask-size: cover;
  filter: url(#magnify-effect);
`;

const Tree = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${tree});
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  z-index: 4;
  pointer-events: none;
  opacity: 0.05;
`;

const SvgMask = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
`;

export default function SimpleBackgroundEffect() {
  const circleRef = useRef<SVGCircleElement>(null);
  const treeRef = useRef<HTMLDivElement>(null);

  const { performanceLevel } = useOptimizer();

  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const userAgent = navigator.userAgent.toLowerCase();
  const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('android');
  const lastPosition = useRef({ x: 0, y: 0 });
  const lastTime = useRef(Date.now());
  const isTouchDevice = useRef(false);

  const { setLoadingContext } = useLoading(); // Get setLoading from context
  
  useEffect(() => {
    isTouchDevice.current = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setLoadingContext(true); // Set loading to true before heavy loads
    const images = [offset, offsetLight];
    let loadedCount = 0;

    const handleImageLoad = () => {
      loadedCount += 1;
      if (loadedCount === images.length) {
        setBackgroundLoaded(true);
        setLoadingContext(false); // Set loading to false when all images are loaded
      }
    };

    images.forEach((src) => {
      const img = new window.Image(); // Use window.Image to avoid conflicts
      img.onload = handleImageLoad;
      if (img.complete) {
        handleImageLoad();
      } else {
        img.src = src;
      }
    });
  }, [setLoadingContext]);


  const throttledMouseMove = useCallback(
    throttle((mouseX: number, mouseY: number) => {
      const currentTime = Date.now();
      const deltaX = mouseX - lastPosition.current.x;
      const deltaY = mouseY - lastPosition.current.y;
      const distance = Math.sqrt(Math.pow(mouseX - window.innerWidth / 2, 2) + Math.pow(mouseY - window.innerHeight / 2, 2));
      const timeElapsed = currentTime - lastTime.current;
      const speed = Math.sqrt(deltaX * deltaX + deltaY * deltaY) / timeElapsed;

      const maxRadius = 600;
      const minRadius = 300;
      const radius = Math.max(minRadius, Math.min(maxRadius, speed));

      gsap.to(circleRef.current, {
        attr: { cx: mouseX, cy: mouseY, r: radius },
        duration: 3.5,
        ease: 'power2.out',
      });

      const calculateOpacity = (distance: number) => {
        const leftEdgeX = 0;
        const leftEdgeY = window.innerHeight / 2;
        const distanceToLeftEdge = Math.sqrt(Math.pow(mouseX - leftEdgeX, 2) + Math.pow(mouseY - leftEdgeY, 2));
        return Math.max(0.05, Math.min(0.7, 0.7 - (distanceToLeftEdge / 370) * 0.55));
      };
      const newOpacity = calculateOpacity(distance);
      gsap.to(treeRef.current, {
        opacity: newOpacity,
        duration: 3.5,
        ease: 'power2.out',
      });

      lastPosition.current = { x: mouseX, y: mouseY };
      lastTime.current = currentTime;

    }, 100),
    [],
  );


  useEffect(() => {
    if (isTouchDevice.current || performanceLevel === 'low') return;

    const onMouseMove = (e: MouseEvent) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      throttledMouseMove(mouseX, mouseY);
    };

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [performanceLevel, throttledMouseMove]);

  return (
    <Wrapper>
      <BackgroundImage backgroundLoaded={backgroundLoaded} />
      <MotionDot/>
      {!isTouchDevice.current && !isSafari && performanceLevel !== 'low' && (
        <>
          <OverlayImage />
          <Tree ref={treeRef} />

          <SvgMask>
            <defs>
              <filter id="magnify-effect">
                <feTurbulence type="fractalNoise" baseFrequency="0.01" numOctaves="1" result="warp" />
                <feDisplacementMap in="SourceGraphic" in2="warp" scale="30" />
              </filter>
              <mask id="soft-mask">
                <radialGradient id="grad">
                  <stop offset="35%" stopColor="white" />
                  <stop offset="100%" stopColor="black" />
                </radialGradient>
                <circle ref={circleRef} cx="0" cy="0" r="350" fill="url(#grad)" />
              </mask>
            </defs>
          </SvgMask>

          <MRIImageDisplay imagesSet={'side'} />
        </>
      )}
    </Wrapper>
  );
}
