// Contact.utils.ts

import { useCallback, useState, useRef } from 'react';

export const HasJob = true;

export const OffsetMessages = [
  `Looking for someone to bring ideas to life? You've found them.`,
  `Always interested in exploring creative ideas and building something impactful together.`,
  `Curious minds make great things. Drop me a line if you've got one.`,
  `Built this site myself—yes, every pixel and line of code. Feel free to share your thoughts!`,
  `Ideas are better when shared. Got one? I'm all ears.`,
  `You've made it this far, why not say hello?`,
  `I'm always up for a good conversation. Got something in mind? Let's talk.`,
  `Feel free to reach out for collaboration, feedback, or just to chat about the future of tech.`,
  `I build things to make life easier. Let's see how I can help with yours.`,
  `Whether it's feedback or a fresh idea, I'm all about hearing what's next. Let's connect.`,
];

export const ContextInfoSet = [
  { item: 'email', info: 'Drop me an email from whichever client suits you best.' },
  { item: 'linkedin', info: "Let's connect on LinkedIn—who knows what we could collaborate on?" },
  { item: 'github', info: "Curious about my code? You'll find some of my work here." },
  { item: 'resume', info: 'Want to share info about me? Download my CV as a PDF here.' },
  { item: 'form', info: 'The contact form works just great for getting in touch.' },
  {
    item: 'iswriting',
    info: "Great! I'm excited to hear what you have to say. Your message is important to me!",
  },
  { item: 'gibberish', info: 'Oops, that seems like gibberish! Please try again with real words. 😅' },
];

export const useOffsetMessages = () => {
  const [displayedMessages, setDisplayedMessages] = useState<Set<string>>(new Set());

  const updateOffsetMessage = useCallback(() => {
    let newMessage: string;
    let attempts = 0;
    const maxAttempts = OffsetMessages.length;

    do {
      const randomIndex = Math.floor(Math.random() * OffsetMessages.length);
      newMessage = OffsetMessages[randomIndex];
      attempts++;

      if (attempts >= maxAttempts) {
        setDisplayedMessages(new Set());
        break;
      }
    } while (displayedMessages.has(newMessage));

    setDisplayedMessages((prev) => new Set(prev).add(newMessage));
    return newMessage;
  }, [displayedMessages]);

  return updateOffsetMessage;
};

export const useContextInfo = () => {
  const [lastInfo, setLastInfo] = useState('');
  const [contextInfo, setContextInfo] = useState(OffsetMessages[0]);
  const [key, setKey] = useState(0);

  const updateContextInfo = useCallback(
    (newInfo: string) => {
      if (newInfo && newInfo !== lastInfo) {
        setContextInfo(newInfo);
        setLastInfo(newInfo);
        setKey((prevKey) => prevKey + 1);
      }
    },
    [lastInfo],
  );

  return { contextInfo, key, updateContextInfo };
};

export const useMessageChange = (
  updateOffsetMessage: () => string,
  updateContextInfo: (newInfo: string) => void,
  isNonOffsetHovered: boolean,
  hasProvidedName: boolean,
  formFieldActive: boolean,
) => {
  const changeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const initialLoadTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const scheduleNextChange = useCallback(() => {
    if (changeTimeoutRef.current) {
      clearTimeout(changeTimeoutRef.current);
    }
    const nextChangeTime = Math.floor(Math.random() * (5000 - 4000 + 1) + 4000);
    changeTimeoutRef.current = setTimeout(() => {
      if (!isNonOffsetHovered && !hasProvidedName && !formFieldActive) {
        const newInfo = updateOffsetMessage();
        updateContextInfo(newInfo);
      }
      scheduleNextChange();
    }, nextChangeTime);
  }, [isNonOffsetHovered, hasProvidedName, formFieldActive, updateContextInfo, updateOffsetMessage]);

  const startInitialTimeout = useCallback(() => {
    if (initialLoadTimeoutRef.current) {
      clearTimeout(initialLoadTimeoutRef.current);
    }
    initialLoadTimeoutRef.current = setTimeout(() => {
      scheduleNextChange();
    }, 5000);
  }, [scheduleNextChange]);

  const startHoverTimeout = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    if (changeTimeoutRef.current) {
      clearTimeout(changeTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      scheduleNextChange();
    }, 5000);
  }, [scheduleNextChange]);

  return {
    scheduleNextChange,
    startInitialTimeout,
    startHoverTimeout,
    changeTimeoutRef,
    initialLoadTimeoutRef,
    hoverTimeoutRef,
  };
};
