import React from 'react';
import styled from 'styled-components';
import { SegmentKey, segmentDetails } from '../../types/segments';

interface MenuItemProps {
  segmentKey: SegmentKey;
  isActive: boolean; // Transient prop
}

const MenuItem: React.FC<MenuItemProps> = ({ segmentKey, isActive }) => {
  const { name, icon: Icon } = segmentDetails[segmentKey];
  return (
    <MenuItemTile $isActive={isActive}>
      <MenuItemIcon as={Icon} className="menu-item-icon" />
      <MenuItemTitle className="menu-item-title">{name}</MenuItemTitle>
    </MenuItemTile>
  );
};

export default MenuItem;

const MenuItemTile = styled.div<{ $isActive: boolean }>`
  min-width: 160px;
  height: 50px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.greyPrimary35 : theme.colors.greyPrimary15};
  border-radius: 10px;
  text-align: center;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 12px;
  gap: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyPrimary35};

    .menu-item-icon {
      fill: ${({ theme }) => theme.colors.whitePrePrimary};
    }

    .menu-item-title {
      color: ${({ theme }) => theme.colors.whitePrePrimary};
    }
  }
`;

const MenuItemIcon = styled.svg`
  width: 40px;
  height: 40px;
  fill: ${({ theme }) =>
    theme.colors.whiteLight}; /* Ensure the icon color matches the theme */
`;

const MenuItemTitle = styled.h3`
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.menu};
  font-weight: 100;
  color: ${({ theme }) => theme.colors.whiteLight};
`;
