// AboutMeTilesContext.tsx
import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useData } from './DataContext';
import { TileData } from '../components/scene/MasterScene.config';
import { ORDERED_TILES } from '../components/scene/elemets/ParticlesCarousel/Particles.config';

interface AboutMeTilesContextProps {
  data: TileData[];
  loading: boolean;
  error: any;
  selectedTileData: TileData | null;
  selectedTileDataImg: string | undefined;
  tileIndex: number;
  handleTileSelect: (tileName: string) => void;
  handleNavigate: (direction: 'left' | 'right') => void;
}

const AboutMeTilesContext = createContext<AboutMeTilesContextProps | undefined>(undefined);

export const AboutMeTilesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data, loading, error } = useData('about_me');
  const [selectedTileData, setSelectedTileData] = useState<TileData | null>(null);
  const [tileIndex, setTileIndex] = useState(0);

  const sortedData = useMemo(() => {
    if (!data) return [];
    const orderedData = ORDERED_TILES.map((tileName) => data.find((item: TileData) => item.tile_name === tileName)).filter(Boolean) as TileData[];
    const remainingData = data.filter((item: TileData) => !ORDERED_TILES.includes(item.tile_name));
    return [...orderedData, ...remainingData];
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0) {
      const aboutMeTile = sortedData.find((item: TileData) => item.tile_name.toLowerCase() === 'about me');
      setSelectedTileData(aboutMeTile || sortedData[0]);
      setTileIndex(sortedData.indexOf(aboutMeTile || sortedData[0]));
    }
  }, [sortedData]);

  const handleTileSelect = useCallback(
    (tileName: string) => {
      const selectedTile = sortedData.find((item: TileData) => item.tile_name === tileName);
      if (selectedTile && selectedTile !== selectedTileData) {
        const newIndex = sortedData.findIndex((item: TileData) => item.tile_name === tileName);
        setSelectedTileData(selectedTile);
        setTileIndex(newIndex);
      }
    },
    [sortedData, selectedTileData],
  );

  const handleNavigate = (direction: 'left' | 'right') => {
    const newIndex = direction === 'left' ? (tileIndex + 1) % sortedData.length : (tileIndex - 1 + sortedData.length) % sortedData.length;
    setSelectedTileData(sortedData[newIndex]);
    setTileIndex(newIndex);
  };

  // handle left and right arrow keys
  const handleArrowKeys = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      handleNavigate('left');
    } else if (event.key === 'ArrowRight') {
      handleNavigate('right');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleArrowKeys);
    return () => {
      window.removeEventListener('keydown', handleArrowKeys);
    };
  }, [handleArrowKeys]);

  const selectedTileDataImg = selectedTileData?.tile_image?.data?.[0]?.attributes?.url;

  return (
    <AboutMeTilesContext.Provider
      value={{
        data: sortedData,
        loading,
        error,
        selectedTileData,
        selectedTileDataImg,
        tileIndex,
        handleTileSelect,
        handleNavigate,
      }}
    >
      {children}
    </AboutMeTilesContext.Provider>
  );
};

export const useAboutMeTiles = () => {
  const context = useContext(AboutMeTilesContext);
  if (context === undefined) {
    throw new Error('useAboutMeTiles must be used within an AboutMeTilesProvider');
  }
  return context;
};
