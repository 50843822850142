import styled from 'styled-components';

export const MarkdownBlockWrapper = styled.div`
  width: 100%;
  height: 100%;
  word-break: break-word;
  white-space: pre-wrap;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  transform: translateY(20px);
  opacity: 0;
  img {
    max-width: 100% !important;
  }
  /* Optionally, reset margin for all nested children elements */
  & * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-block-start: -8px !important;
    margin-block-end: -2px !important;
    gap: 0 !important;
    & img {
      border-radius: 10px;
      margin-top: 12px !important;
      margin-bottom: 5px !important;
    }
  }
`;

export const TileWrapper = styled.div<{ is_row_section_active?: boolean; extendedpadding?: boolean }>`
  height: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.greyPrimary15};
  position: relative;
  padding: ${({ extendedpadding }) => (extendedpadding ? '24px' : '12px')};
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0; /* Start with opacity 0 for animation */

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyPrimary20};
    transition: background-color 0.3s ease-in-out;
  }

  &::-webkit-scrollbar {
    margin: 3px;
    background-color: ${({ theme }) => theme.colors.greyPrimary25};
    width: 10px;
    border-radius: 5px;

    &-thumb {
      background-color: ${({ theme }) => theme.colors.greyPrimary40};
      border-radius: 5px;
    }
  }
`;
