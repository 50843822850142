export const endpoints = {
  // TODO api boilerplate expand here
  experience: '/api/experience',
  education: '/api/education',
  techstack: '/api/techstack',
  docs: '/api/docs',
  projects: '/api/projects',
  certificates: '/api/certificates',
  about_me: '/api/about-me-collection',
  personal: '/api/personal',
};
