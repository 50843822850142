import React from 'react';
import { FaEnvelope, FaLinkedin, FaGithub, FaFileDownload } from 'react-icons/fa';
import CV from '../../../assets/cv/CV_Michał_Madejski.pdf';
import UsePersonalData from '../../../hooks/usePersonalData';

// Create a function to generate the CircleLinksData
export interface CircleLink {
  item: string;
  icon: JSX.Element;
  href: string;
  info: string;
  download?: string;
}

export const GetCircleLinksData = (): CircleLink[] => {
  const { githubLink, linkedinLink, email, current_cv } = UsePersonalData();

  return [
    {
      item: 'email',
      icon: <FaEnvelope />,
      href: `mailto:${email}`,
      info: 'Write me an email',
    },
    {
      item: 'linkedin',
      icon: <FaLinkedin />,
      href: linkedinLink,
      info: 'Connect with me on LinkedIn',
    },
    {
      item: 'github',
      icon: <FaGithub />,
      href: githubLink,
      info: 'Check out my GitHub',
    },
    {
      item: 'resume',
      icon: <FaFileDownload />,
      href: current_cv,
      download: 'Michał_Madejski_CV.pdf',
      info: 'Download my CV',
    },
  ];
};
