import React, { useRef, useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import gsap from 'gsap';
import styled from 'styled-components';
import { CollapseArrowWrapper, FlippingWrapper } from './NavArrow.styles';
import { TileWrapper } from '../sections/Experience/Experience.styles';

interface NavArrowProps {
  mounted: boolean;
  isFlipped: boolean;
  onClick: () => void;
  onMouseEnter?: () => void; // Add onMouseEnter prop
}

const NavArrow: React.FC<NavArrowProps> = ({ mounted, isFlipped, onClick, onMouseEnter }) => {
  const arrowWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (mounted && !isVisible) {
      setIsVisible(true);
      if (arrowWrapperRef.current) {
        gsap.fromTo(
          arrowWrapperRef.current,
          { autoAlpha: 0, x: '-100%' },
          {
            duration: 1.2,
            autoAlpha: 1,
            x: '0%',
            ease: 'power2.out',
          }
        );
      }
    } else if (!mounted && isVisible) {
      if (arrowWrapperRef.current) {
        gsap.to(arrowWrapperRef.current, {
          duration: 0.6,
          autoAlpha: 0,
          x: '-100%',
          ease: 'power2.in',
          onComplete: () => setIsVisible(false),
        });
      }
    }
  }, [mounted, isVisible]);

  if (!isVisible) return null;

  return (
    <CollapseArrowWrapper
      ref={arrowWrapperRef}
      className="hoverable"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <TileWrapper>
        <FlippingWrapper flipped={isFlipped}>
          <IoIosArrowForward size={60} />
        </FlippingWrapper>
      </TileWrapper>
    </CollapseArrowWrapper>
  );
};

export default NavArrow;
