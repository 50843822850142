import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

// Tooltip container that wraps the target element
const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

// The tooltip box that appears on the left or right
const TooltipBox = styled.div<{ maxWidth?: string; alwaysVisible?: boolean; position?: 'left' | 'right' }>`
  width: max-content;
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  background-color: ${({ alwaysVisible, theme }) => (alwaysVisible ? theme.colors.transparent : theme.colors.greyPrimary10)};
  color: ${({ alwaysVisible, theme }) => (alwaysVisible ? theme.colors.whiteLight : theme.colors.whiteMidPrimary)};
  text-align: center;
  padding: ${({ alwaysVisible }) => (alwaysVisible ? '3px 6px' : '5px 10px')};
  font-size: ${({ alwaysVisible }) => (alwaysVisible ? '0.8em' : '1em')};
  border-radius: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: ${({ alwaysVisible }) => (alwaysVisible ? 1 : 0)};
  pointer-events: none;
  left: ${({ position }) => (position === 'right' ? 'auto' : '0')};
  right: ${({ position }) => (position === 'right' ? '0' : 'auto')};

  @media (max-width: 768px) {
    transform: translateX(${({ position }) => (position === 'right' ? '-100%' : '100%')}) translateY(-50%);
  }
`;

interface ToolTipProps {
  info: string;
  children: React.ReactNode;
  maxWidth?: string;
  alwaysVisible?: boolean;
  position?: 'left' | 'right';
}

export default function ToolTip({ info, children, maxWidth, alwaysVisible = false, position = 'left' }: ToolTipProps) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tooltipRef.current && containerRef.current) {
      const tooltipWidth = tooltipRef.current.offsetWidth;
      const shiftAmount = alwaysVisible ? 105 : 6;

      // Position the tooltip based on the 'position' prop
      gsap.set(tooltipRef.current, {
        left: position === 'right' ? 'auto' : `-${tooltipWidth + shiftAmount}px`,
        right: position === 'right' ? `-${tooltipWidth + shiftAmount}px` : 'auto',
      });

      if (!alwaysVisible) {
        gsap.set(tooltipRef.current, { opacity: 0 }); // Ensure tooltip starts hidden
      }
    }
  }, [alwaysVisible, position]);

  const showTooltip = () => {
    if (!alwaysVisible) {
      gsap.to(tooltipRef.current, { x: 0, opacity: 1, duration: 0.3, ease: 'power2.out' });
    }
  };

  const hideTooltip = () => {
    if (!alwaysVisible) {
      gsap.to(tooltipRef.current, { x: 20, opacity: 0, duration: 0.3, ease: 'power2.in' });
    }
  };

  return (
    <TooltipContainer ref={containerRef} onMouseEnter={!alwaysVisible ? showTooltip : undefined} onMouseLeave={!alwaysVisible ? hideTooltip : undefined}>
      {children}
      <TooltipBox ref={tooltipRef} maxWidth={maxWidth} alwaysVisible={alwaysVisible} position={position}>
        {info}
      </TooltipBox>
    </TooltipContainer>
  );
}
