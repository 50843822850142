import React, { useEffect, useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { gsap } from 'gsap';
import loadingAnimation from '../../../assets/animations/TreeGrowAnimation.gif';
import Typography from '../../atoms/Typography';
import { TypographyVariant } from '../../atoms/Typography.autogen';
import { useLoading } from '../../../contexts/LoadingContext';
import { LoadingPageWrapper, TextMiddle, BackgroundContainer, LoadingZoneWrapper, LoadingZone, AnimatingDots } from './LoadingPage.styles';
import { useGuide } from '../../../contexts/GuideContext';
import { FullPageWrapper } from '../ErrorPage/ErrorPage.styles';
import { StyledSection } from '../../sections/Welcome/Welcome.styles';

interface LoadingPageProps {
  isFullPage?: boolean;
  isLoading?: boolean;
  isWelcomePage?: boolean;
}

const CalmingInfoMessage =
  "If the page takes a little longer to load, don't worry. It means that the CMS backend is just warming up. Give it a moment - free Web Service tier is taking approximately 60 seconds to wake up – it's worth the wait.";

const LoadingPage: React.FC<LoadingPageProps> = ({ isFullPage, isLoading, isWelcomePage }) => {
  const { isLoadingContext } = useLoading();
  const [isVisible, setIsVisible] = useState(isLoading || isLoadingContext);
  const { fadeOutGuide } = useGuide();
  const [hasLoaded, setHasLoaded] = useState(false);
  const loadingZoneWrapperRef = React.useRef<HTMLDivElement>(null);
  const [calmingInfo, setCalmingInfo] = useState(false);

  const handleSetCalmingInfo = useCallback(() => {
    setCalmingInfo(true);
  }, [fadeOutGuide]);

  const [canHide, setCanHide] = useState(false);

  useEffect(() => {
    let minimumDisplayTime = 4260; // Minimum display time in milliseconds (4.26 seconds)
    let fadeOutDelay = 300; // Fade out duration in milliseconds
    let debounceTime = 1500; // Minimum time before allowing hide (1.5 seconds)

    if (isLoading) {
      setIsVisible(true);
      setCanHide(false);

      const displayTimer = setTimeout(() => {
        setHasLoaded(true);
      }, minimumDisplayTime);

      const debounceTimer = setTimeout(() => {
        setCanHide(true);
      }, debounceTime);

      return () => {
        clearTimeout(displayTimer);
        clearTimeout(debounceTimer);
      };
    } else if (hasLoaded && canHide) {
      if (loadingZoneWrapperRef.current) {
        gsap.to(loadingZoneWrapperRef.current, {
          opacity: 0,
          duration: fadeOutDelay / 1000,
          onComplete: () => setIsVisible(false),
        });
      }
    }
  }, [isLoading, hasLoaded, canHide]);

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(handleSetCalmingInfo, 7000);
      setTimeout(() => {
        fadeOutGuide();
      }, 1200);
      return () => clearTimeout(timer);
    }
  }, [isLoading, handleSetCalmingInfo]);

  if (!isVisible) return null;

  return (
    <StyledSection>
      {isFullPage ? (
        <FullPageWrapper>
          <LoadingPageWrapper isVisible={isLoading ?? false}>
            <img src={loadingAnimation} alt="loading..." />
          </LoadingPageWrapper>
          <TextMiddle isVisible={isLoading ?? false}>
            <Typography animated animationType="write" variant={TypographyVariant.megaHeader} align={'center'}>
              Loading
            </Typography>
            {calmingInfo && (
              <Typography animated animationType="write" variant={TypographyVariant.sectionSubHeader} align={'center'}>
                {CalmingInfoMessage}
              </Typography>
            )}
            <Typography animated animationType="write" variant={TypographyVariant.sectionHeader} align={'center'}>
              Loading
            </Typography>
            {calmingInfo && (
              <Typography animated animationType="write" variant={TypographyVariant.sectionSubHeader} align={'center'}>
                {CalmingInfoMessage}
              </Typography>
            )}
          </TextMiddle>
          <BackgroundContainer isVisible={isLoading ?? false} />
        </FullPageWrapper>
      ) : (
        <LoadingZoneWrapper ref={loadingZoneWrapperRef}>
          <LoadingZone>
            <img src={loadingAnimation} alt="loading..." />
          </LoadingZone>
          <TextMiddle isVisible={isLoading ?? false}>
            <Typography animated animationType="write" variant={TypographyVariant.megaHeader} align={'center'}>
              {isWelcomePage ? 'Welcome' : 'Loading'}
            </Typography>
            {calmingInfo ? (
              <Typography key={calmingInfo ? 'calming' : 'loading'} animated animationType="write" variant={TypographyVariant.sectionDescription} align={'center'}>
                {CalmingInfoMessage}
              </Typography>
            ) : (
              <Typography animated animationType="write" variant={TypographyVariant.sectionDescription} align={'center'}>
                ...
              </Typography>
            )}
          </TextMiddle>
        </LoadingZoneWrapper>
      )}
    </StyledSection>
  );
};

export default LoadingPage;
