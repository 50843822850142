import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_CMS_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CMS_API_TOKEN}`,
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Modify the URL to append the query parameter
    if (config.url) {
      config.url += '/?populate=*';
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default apiClient;
