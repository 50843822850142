import React, { useEffect, useState, useRef, useReducer } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { gsap } from 'gsap';
import { FaPause, FaPlay } from 'react-icons/fa';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import Matter from 'matter-js';
import { FadeContainer, CircleWrapper, CurvedText, IconContainer } from './OpenToWorkCircle.styles';
import UsePersonalData from '../../hooks/usePersonalData';
import { useHoverBlock } from '../../contexts/HoverBlockContext';

const MAX_SPEED = 4; // Control maximum speed
const TIME_CYCLE = 16; // Control time cycle duration in seconds
const ELASTIC_STRENGTH = 0.4; // Control the strength of the spring effect
const SHOW_DEBOUNCE_DELAY = 1000; // Delay before showing the component after the hide state is false

// Define possible states
enum SpinnerStates {
  HIDDEN,
  ANIMATE_IN,
  START_SPIN,
  HOLD_SPIN,
  STOPPED,
  ANIMATE_OUT,
}

interface OpenToWorkCircleProps {
  hide: boolean;
}

// Reducer to manage spinner states
const spinnerReducer = (state: SpinnerStates, action: SpinnerStates): SpinnerStates => {
  return action;
};

export default function OpenToWorkCircle({ hide }: OpenToWorkCircleProps) {
  const { isOpenToWork } = UsePersonalData();
  const [spinnerState, dispatch] = useReducer(spinnerReducer, SpinnerStates.HIDDEN);
  const [isPlaying, setIsPlaying] = useState(true);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const engineRef = useRef<Matter.Engine | null>(null);
  const bodyRef = useRef<Matter.Body | null>(null);
  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { activeSection } = useActiveSection();
  const previousSectionRef = useRef<string | null>(null); // Track the previous section
  const isInitialRender = useRef(true); // Track if it's the initial render
  const { isHovered } = useHoverBlock();

  const diameter = 82;
  const radius = diameter / 2;
  const offset = radius - radius * 0.1;

  const pathDefinition = `
    M 46, 50
    m -${offset}, 0
    a ${radius},${radius} 0 1,1 ${diameter},0
    a ${radius},${radius} 0 1,1 -${diameter},0
  `;

  const isHidden = window.innerWidth < 1390;

  useEffect(() => {
    // Initialize Matter.js engine and world
    engineRef.current = Matter.Engine.create();
    const world = engineRef.current.world;

    bodyRef.current = Matter.Bodies.circle(100, 100, 20, {
      restitution: 0.9,
      frictionAir: 0.02,
    });

    Matter.World.add(world, bodyRef.current);

    return () => {
      if (engineRef.current) {
        Matter.Engine.clear(engineRef.current);
        engineRef.current = null;
        bodyRef.current = null;
      }
    };
  }, []);

  const startSpinning = () => {
    if (!isPlaying || hide || !engineRef.current) return;

    dispatch(SpinnerStates.START_SPIN);
    Matter.Engine.run(engineRef.current);

    gsap.to(wrapperRef.current, {
      rotation: 360,
      duration: TIME_CYCLE / MAX_SPEED,
      ease: 'linear',
      onComplete: () => {
        dispatch(SpinnerStates.HOLD_SPIN);
      },
    });
  };

  const stopSpinning = () => {
    dispatch(SpinnerStates.STOPPED);

    gsap.to(wrapperRef.current, {
      rotation: `+=180`,
      ease: `elastic.out(${ELASTIC_STRENGTH}, 0.3)`,
      duration: 1.5,
      onComplete: () => {
        if (engineRef.current) Matter.Engine.clear(engineRef.current);
      },
    });
  };

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the first render
      isInitialRender.current = false;
      previousSectionRef.current = activeSection;
      return;
    }

    if (hide || isHovered) {
      if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
      dispatch(SpinnerStates.ANIMATE_OUT);
      gsap.to(wrapperRef.current, {
        opacity: 0,
        duration: 0.6,
        ease: 'easeInOut',
        onComplete: () => {
          dispatch(SpinnerStates.HIDDEN);
        },
      });
    } else {
      if (activeSection === 'PERSONAL' && previousSectionRef.current === 'PERSONAL') {
        // Do nothing if we're already in the "PERSONAL" section
        return;
      } else if (activeSection === 'PERSONAL' && previousSectionRef.current !== 'PERSONAL') {
        // Fade out when transitioning to "PERSONAL"
        dispatch(SpinnerStates.ANIMATE_OUT);
        gsap.to(wrapperRef.current, {
          opacity: 0,
          duration: 0.6,
          ease: 'easeInOut',
          onComplete: () => {
            dispatch(SpinnerStates.HIDDEN);
          },
        });
      } else {
        // Debounce logic: wait before showing again
        if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
        showTimeoutRef.current = setTimeout(() => {
          dispatch(SpinnerStates.ANIMATE_IN);
          gsap.to(wrapperRef.current, {
            opacity: 1,
            duration: 0.6,
            ease: 'easeOut',
            onComplete: () => {
              startSpinning();
            },
          });
        }, SHOW_DEBOUNCE_DELAY);
      }
    }

    // Update the previous section tracker
    previousSectionRef.current = activeSection;

    return () => {
      if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
    };
  }, [hide, activeSection, isHovered]);

  const handleMouseEnter = () => {
    stopSpinning();
  };

  const handleMouseLeave = () => {
    if (spinnerState === SpinnerStates.STOPPED) {
      startSpinning();
    }
  };

  const handleCircleClick = () => {
    if (isPlaying) {
      stopSpinning();
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
      startSpinning();
    }
  };

  if (!isOpenToWork) return null;

  return (
    <>
      {!isHidden && (
        <FadeContainer>
          <CircleWrapper
            ref={wrapperRef}
            onClick={handleCircleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ display: spinnerState === SpinnerStates.HIDDEN ? 'none' : 'flex' }}
          >
            <CurvedText viewBox="0 0 100 100">
              <defs>
                <path id="circlePathTop" d={pathDefinition} />
              </defs>
              <text fill="#ffffff90" fontSize={(diameter * 0.1).toFixed(2)} fontWeight="bold" textAnchor="middle">
                <textPath xlinkHref="#circlePathTop" startOffset="25%">
                  #OPEN TO WORK
                </textPath>
                <textPath xlinkHref="#circlePathTop" startOffset="75%">
                  #OPEN TO WORK
                </textPath>
              </text>
            </CurvedText>
            <IconContainer ref={iconRef}>{isPlaying ? <FaPause /> : <FaPlay />}</IconContainer>
          </CircleWrapper>
        </FadeContainer>
      )}
    </>
  );
}
