// ImageGalleryPreview.tsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { usePreview } from '../../contexts/ImageGalleryPreviewContext';
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from 'react-icons/io';
import { useGuide } from '../../contexts/GuideContext';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const CarouselDots = styled.div`
  position: fixed; /* Position fixed to place it relative to the viewport */
  bottom: 20px; /* 20px from the bottom of the screen */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 501; /* Ensure it's above the image but below the close button */
`;

const CarouselDot = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? 'white' : 'rgba(255, 255, 255, 0.5)')};
  margin: 0 5px;
  cursor: pointer;
`;

const Arrow = styled.button`
  position: absolute; /* Position the arrows relative to the Overlay */
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 501;
  padding: 12px;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  svg {
    font-size: 24px;
    color: white;
  }
`;

const LeftArrow = styled(Arrow)`
  left: 12px; /* Position 12px from the left edge */
`;

const RightArrow = styled(Arrow)`
  right: 12px; /* Position 12px from the right edge */
`;

const CloseButton = styled.button`
  position: absolute; /* Position absolutely within the Overlay */
  top: 12px;
  right: 12px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 502;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  svg {
    font-size: 32px;
    color: white;
  }
`;

const ImageGalleryPreview: React.FC = () => {
  const { isPreviewOpen, images, startIndex, closePreview } = usePreview();
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  const { fadeOutGuide } = useGuide();


  useEffect(() => {
    fadeOutGuide();
    if (isPreviewOpen) {
      setCurrentIndex(startIndex);
      const tl = gsap.timeline();
      tl.fromTo('.gallery-preview', { opacity: 0, scale: 0.9 }, { opacity: 1, scale: 1, duration: 0.5 });
    }
  }, [isPreviewOpen, startIndex]);

  if (!isPreviewOpen) return null;

  

  const goToPrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const goToNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <Overlay onClick={closePreview}>
      <CloseButton onClick={closePreview}>
        <IoIosClose />
      </CloseButton>
      {images.length > 1 && (
        <>
          <LeftArrow onClick={goToPrevious}>
            <IoIosArrowBack />
          </LeftArrow>
          <RightArrow onClick={goToNext}>
            <IoIosArrowForward />
          </RightArrow>
        </>
      )}
      <Wrapper className="gallery-preview" onClick={(e) => e.stopPropagation()}>
        <Image src={images[currentIndex]} alt={`Gallery image ${currentIndex + 1}`} className="gallery-image" />
      </Wrapper>
      {images.length > 1 && (
        <CarouselDots>
          {images.map((_, index: number) => (
            <CarouselDot key={index} active={index === currentIndex} onClick={() => setCurrentIndex(index)} />
          ))}
        </CarouselDots>
      )}
    </Overlay>
  );
};

export default ImageGalleryPreview;
