import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { BigCirclesButton, ButtonBlockContainer } from './LinkCircles.styles';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import { GetCircleLinksData } from '../sections/Contact/Contact.data';

interface LinkBlockCirclesProps {
  onHover: (item: string | null) => void;
}

export default function LinkBlockCircles({ onHover }: LinkBlockCirclesProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLAnchorElement[]>([]);
  const { activeSection } = useActiveSection();
  const [ishovered, setishovered] = useState(false);

  const CircleLinksData = GetCircleLinksData();
  useEffect(() => {
    if (containerRef.current) {
      const buttons = buttonsRef.current;

      if (activeSection === 'CONTACT') {
        gsap.set(buttons, { clearProps: 'all' });
        gsap.set(buttons, { opacity: 0, scale: 0 });

        gsap.fromTo(
          buttons,
          {
            opacity: 0,
            scale: 0.5,
          },
          {
            opacity: 1,
            scale: 1.2,
            duration: 0.8,
            delay: 0.3,
            ease: 'power3.inOut',
            stagger: {
              each: 0.1,
              from: 'start',
            },
            onComplete: () => {
              gsap.to(buttons, {
                scale: 1,
                duration: 0.3,
                ease: 'power2.out',
              });
            },
          },
        );
      } else {
        gsap.set(buttons, { opacity: 0, scale: 0 });
      }
    }
  }, [activeSection]);

  const handleMouseEnter = (index: number) => {
    setishovered(true);
    onHover(CircleLinksData[index].item); // Pass the item property to the onHover callback

    gsap.to(buttonsRef.current[index], {
      scale: 1.2,
      ease: 'elastic.out(1, 0.4)',
      duration: 0.5,
      borderColor: '#D9D9D9',
      color: '#D9D9D9',
    });
  };

  const handleMouseLeave = (index: number) => {
    setishovered(false);
    onHover(null); // Reset the hover state in the parent component

    gsap.to(buttonsRef.current[index], {
      scale: 1,
      ease: 'elastic.out(1, 0.4)',
      duration: 0.5,
      borderColor: '',
      color: '',
    });
  };

  const isMobile = window.innerWidth <= 900;

  return (
    <ButtonBlockContainer ref={containerRef} className="hoverable">
      {CircleLinksData.map(
        (
          item: {
            href: string | undefined;
            icon: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
          },
          index: React.Key | null | undefined,
        ) => (
          <BigCirclesButton
            key={index}
            href={item.href}
            target="_blank"
            ref={(el) => {
              if (el && typeof index === 'number') buttonsRef.current[index] = el;
            }}
            onMouseEnter={() => handleMouseEnter(Number(index))}
            onMouseLeave={() => handleMouseLeave(Number(index))}
          >
            {item.icon}
            {index == 3 && isMobile && <>cv</>}
          </BigCirclesButton>
        ),
      )}
    </ButtonBlockContainer>
  );
}
