import React, { useEffect, useState } from 'react';
import errorAnimation from '../../../assets/animations/dead.gif';
import Typography from '../../atoms/Typography';
import { TypographyVariant } from '../../atoms/Typography.autogen';
import { sendEmail } from '../../../utils/emailJsService';
import { ErrorPageWrapper, TextMiddle, ErrorZoneWrapper, ErrorZone, FullPageWrapper, LinksRow, StyledLink } from './ErrorPage.styles';
import { StyledSection } from '../../sections/Welcome/Welcome.styles';
import { FaLinkedin, FaEnvelope, FaFileDownload } from 'react-icons/fa';
import CV from '../../../assets/cv/CV_Michał_Madejski.pdf';

interface ErrorPageProps {
  sourceOfError: string;
  isFullPage?: boolean;
  isError?: boolean;
}

// TODO FE ONLY BACKUP CONTACT THING TO SET UP
const linkedInUrl = 'https://www.linkedin.com/in/michał-madejski-671b60134/';

const errorInfoMessage = 'Something went wrong, please try again later.';

const errorMailSent = "Mail with error report has been sent just now. I'm on my way to fix whatever has crashed";

const ErrorPage: React.FC<ErrorPageProps> = ({ isFullPage, isError, sourceOfError }) => {
  const [crashReportSent, setCrashReportSent] = useState(false);
  const [displayText, setDisplayText] = useState('...');

  const sendErrorEmail = async () => {
    try {
      await sendEmail({
        from_name: 'Website CV Error Notification',
        reply_to: 'N/A',
        phone: 'N/A',
        message: `On website cv.madejski.it occurred an error. Error loading ${sourceOfError} data. Perform an action to fix it!`,
      });
    } catch (error) {
      console.error('Failed to send error email:', error);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!crashReportSent) {
      timeout = setTimeout(() => {
        sendErrorEmail();
        setCrashReportSent(true);
        setDisplayText(errorMailSent);
      }, 3000); // Increased timeout to 3 seconds for visibility
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []); // Empty dependency array, so it only runs once

  // Force re-render of Typography component when displayText changes
  const renderTypography = () => (
    <Typography 
      key={displayText} // Add a key prop
      animated 
      animationType="write" 
      variant={TypographyVariant.sectionDescription} 
      align={'center'}
    >
      {displayText}
    </Typography>
  );

  return (
    <StyledSection>
      {isFullPage ? (
        <FullPageWrapper>
          <ErrorPageWrapper isVisible={true}>
            <img src={errorAnimation} alt="loading..." />
          </ErrorPageWrapper>
          <TextMiddle isVisible={true}>
            <Typography animated animationType="write" variant={TypographyVariant.sectionHeader} align={'center'}>
              {`Error loading ${sourceOfError} data`}
            </Typography>
            <Typography animated animationType="write" variant={TypographyVariant.sectionSubHeader} align={'center'}>
              {errorInfoMessage}
            </Typography>

            {renderTypography()}
          </TextMiddle>
          {/* <BackgroundContainer isVisible={true} /> */}
        </FullPageWrapper>
      ) : (
        <ErrorZoneWrapper>
          <ErrorZone>
            <img src={errorAnimation} alt="error..." />
            <TextMiddle isVisible={true}>
              <Typography animated animationType="write" variant={TypographyVariant.sectionHeader} align={'center'}>
                {`Error loading ${sourceOfError} data`}
              </Typography>{' '}
              <Typography animated animationType="write" variant={TypographyVariant.sectionSubHeader} align={'center'}>
                {errorInfoMessage}
              </Typography>
              {renderTypography()}
              <LinksRow>
                <StyledLink href="mailto:michalmadejski2@gmail.com">
                  <FaEnvelope size={24} />
                </StyledLink>
                <StyledLink href={linkedInUrl} target="_blank" rel="noopener noreferrer">
                  <FaLinkedin size={24} />
                </StyledLink>
                <StyledLink href={CV} download="Michał_Madejski_CV.pdf">
                  <FaFileDownload size={24} />cv
                </StyledLink> 
              </LinksRow>
            </TextMiddle>
          </ErrorZone>
        </ErrorZoneWrapper>
      )}
    </StyledSection>
  );
};

export default ErrorPage;
