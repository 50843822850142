import { useData } from '../contexts/DataContext';

const UsePersonalData = () => {
  const { loading, error, data } = useData('personal');

  if (!data || !data.data || !data.data.attributes) {
    return {
      isOpenToWork: false,
      whoAmIArray: [],
      wordTunnels: {
        tunnel1: [],
        tunnel2: [],
        tunnel3: [],
        tunnel4: [],
        tunnel5: [],
      },
      email: '',
      githubLink: '',
      linkedinLink: '',
      nameSurname: '',
      current_cv: '',
      myface_image: '',
    };
  }

  const {
    attributes: {
      is_currently_open_to_work = false,
      who_am_I_array = {},
      rings_array = {},
      email_address = '',
      github_link = '',
      linkedin_link = '',
      name_surname = '',
      current_cv = '',
      myface_image = '',
    } = {},
  } = data.data;

  const isOpenToWork =
    typeof is_currently_open_to_work === 'boolean'
      ? is_currently_open_to_work
      : typeof is_currently_open_to_work === 'string'
      ? is_currently_open_to_work.toLowerCase() === 'true'
      : Boolean(is_currently_open_to_work);

  const { WhoAmIArray_element = [] } = who_am_I_array;
  const { wordTunnels: { tunnel1 = [], tunnel2 = [], tunnel3 = [], tunnel4 = [], tunnel5 = [] } = {} } = rings_array;
  const my_face_image_url = myface_image?.data?.[0]?.attributes?.url;

  const current_cv_url = current_cv.data.attributes.url;

  return {
    isOpenToWork,
    whoAmIArray: WhoAmIArray_element,
    wordTunnels: {
      tunnel1,
      tunnel2,
      tunnel3,
      tunnel4,
      tunnel5,
    },
    email: email_address,
    githubLink: github_link,
    linkedinLink: linkedin_link,
    nameSurname: name_surname,
    current_cv: current_cv_url,
    my_face_image_url: my_face_image_url,
    loading,
    error,
  };
};

export default UsePersonalData;
