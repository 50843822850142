import styled from 'styled-components';
import { TileWrapper } from '../Experience/Experience.styles';

export const SectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  align-items: baseline;
  gap: 20px;



  @media (max-width: 900px) {
    gap: 12px;
    height: unset;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
  }
`;

export const CertificationContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const StyledTileWrapper = styled(TileWrapper)<{ backgroundImage?: string }>`
  max-height: 850px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch; // Ensure children stretch to fill the height
  overflow: hidden;
  background-color: #0b0b0bff;
  flex: 6; // Allow the StyledTileWrapper to grow and fill available space

  &:hover {
    background-color: #0b0b0bff;
  }

  @media (max-width: 900px) {
    max-height: 28vh;
    overflow: auto;
  }
`;

export const InfoWrapper = styled.div`
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch; // Ensure children stretch to fill the height
  gap: 12px;
  padding: 12px;
  margin-bottom: -24px;

  @media (max-width: 900px) {
    gap: 6px;
  }
`;

export const HeaderWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  min-height: 130px;

  @media (max-width: 900px) {
    min-height: unset;
    gap: 6px;
  }
`;

export const CoverContainer = styled.div`
  margin-top: auto;
  width: 100%;
  position: absolute;
  bottom: -12px;
`;

export const IssuerLogo = styled.img`
  display: block;
  height: fit-content;
  width: 40%;
  margin-bottom: -6px;
  @media (min-width: 900px) {
    width: 60%;
    
  }
`;

export const DescWrapper = styled.div<{ height: string }>`
  overflow: auto; // Ensure overflow is handled
  @media (min-width: 900px) {
    height: ${({ height }) => height};
    min-height: 240px !important;
    z-index: 11;
  }
  @media (max-width: 900px) {
    margin-bottom: 12px;
    overflow: scroll;
  }
`;
