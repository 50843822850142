import { useFrame, useThree } from '@react-three/fiber';
import React, { useRef, useState, useEffect, useCallback, useMemo, useLayoutEffect } from 'react';
import * as THREE from 'three';
import { Text, Billboard } from '@react-three/drei';
import { useAboutMeTiles } from '../../../../contexts/UseAboutTilesContext';

// Constants
const TILE_WIDTH = 2.5;
const TILE_HEIGHT = 1.8;
const TILE_TEXT_COLOR = '#E0E0E0FF';
const TILE_WIREFRAME_COLOR = 'rgb(107, 107, 107)';
const TILE_SELECTED_COLOR = 'rgb(255, 255, 253)';
const SPIN_SPEED_MULTIPLIER = 0.018; // Adjust for auto-spin speed
const CAROUSEL_RADIUS = 6;
const FRONT_ALIGNMENT_OFFSET = -Math.PI / 6; // 90 degrees for front alignment
const HORIZONTAL_SCROLL_MULTIPLIER = 0.003;

// Tile Props Interface
interface TileProps {
  position: [number, number, number];
  name: string;
  scale: number;
  onClick: () => void;
  isSelected?: boolean; // New prop for visual feedback
}

// Tile Component with React.memo
const Tile: React.FC<TileProps> = React.memo(({ position, name, scale, onClick, isSelected }) => {
  return (
    <Billboard position={position} scale={[scale, scale, scale]} onClick={(e) => e.stopPropagation()}>
      <mesh>
        <planeGeometry args={[TILE_WIDTH, TILE_HEIGHT]} />
        <meshBasicMaterial color={isSelected ? TILE_SELECTED_COLOR : TILE_WIREFRAME_COLOR} wireframe />
      </mesh>
      <Text
        color={TILE_TEXT_COLOR}
        fontSize={0.2}
        position={[0, 0, 0.1]}
        anchorX="center"
        anchorY="middle"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        {name}
      </Text>
    </Billboard>
  );
});

// Carousel Component
export const Carousel: React.FC = () => {
  const { data, selectedTileData, handleTileSelect } = useAboutMeTiles();
  const groupRef = useRef<THREE.Group>(null);

  // Rotation States
  const [targetRotation, setTargetRotation] = useState<number | null>(null);
  const [isAutoSpinning, setIsAutoSpinning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { camera } = useThree();
  const totalTiles = useMemo(() => data.length, [data]);

  // Current Tile Index
  const [currentTileIndex, setCurrentTileIndex] = useState<number>(0);

  // Scroll Accumulator (useRef to persist without causing re-renders)
  const scrollAccumulator = useRef<number>(0);

  // Refs to prevent stale closures
  const currentTileIndexRef = useRef<number>(currentTileIndex);
  currentTileIndexRef.current = currentTileIndex;

  // Helper function to normalize angles between 0 and 2π
  const normalizeAngle = (angle: number) => {
    return THREE.MathUtils.euclideanModulo(angle, Math.PI * 2);
  };

  // Calculate angle per tile and scroll threshold
  const anglePerTile = useMemo(() => (Math.PI * 2) / totalTiles, [totalTiles]);
  const deltaXPerTile = useMemo(() => anglePerTile / HORIZONTAL_SCROLL_MULTIPLIER, [anglePerTile]);

  // Reorder data to ensure "About Me" is at index 0
  const reorderedData = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }
    const aboutMeIndex = data.findIndex((tile) => tile?.tile_name === 'About Me');
    if (aboutMeIndex > 0) {
      return [data[aboutMeIndex], ...data.slice(0, aboutMeIndex), ...data.slice(aboutMeIndex + 1)];
    }
    return data;
  }, [data]);

  // Calculate Dynamic Global Rotation Offset
  const GLOBAL_ROTATION_OFFSET = useMemo(() => anglePerTile / 2, [anglePerTile]);

  // Set initial selected tile and rotation
  useLayoutEffect(() => {
    if (reorderedData.length > 0 && !selectedTileData) {
      const initialTileIndex = 0; // "About Me" is at index 0 after reordering
      const initialRotation = normalizeAngle(initialTileIndex * anglePerTile - FRONT_ALIGNMENT_OFFSET + GLOBAL_ROTATION_OFFSET);

      if (groupRef.current) {
        groupRef.current.rotation.y = initialRotation;
      }

      handleTileSelect(reorderedData[initialTileIndex].tile_name);
      setCurrentTileIndex(initialTileIndex);
    }
    setIsLoading(false);
  }, [handleTileSelect, selectedTileData, anglePerTile, GLOBAL_ROTATION_OFFSET, reorderedData]);

  // Spin carousel when selectedTileData changes externally
  useEffect(() => {
    if (selectedTileData) {
      const index = reorderedData.findIndex((tile) => tile?.tile_name === selectedTileData.tile_name);
      if (index !== -1 && index !== currentTileIndexRef.current) {
        spinToTile(index);
        setCurrentTileIndex(index);
      }
    }
  }, [selectedTileData, reorderedData]);

  // Function to smoothly rotate to the target rotation
  const autoSpin = useCallback(() => {
    if (!groupRef.current || targetRotation === null) return;

    const currentYRotation = groupRef.current.rotation.y;
    const delta = getShortestPathRotation(currentYRotation, targetRotation);

    const maxDelta = SPIN_SPEED_MULTIPLIER;
    const appliedDelta = Math.abs(delta) > maxDelta ? Math.sign(delta) * maxDelta : delta;

    groupRef.current.rotation.y += appliedDelta;

    if (Math.abs(delta) < 0.001) {
      groupRef.current.rotation.y = targetRotation;
      setIsAutoSpinning(false);
      setTargetRotation(null);
    }
  }, [targetRotation]);

  // Function to get the shortest rotation path
  const getShortestPathRotation = (currentRotation: number, targetRotation: number) => {
    const normalizedCurrent = normalizeAngle(currentRotation);
    const normalizedTarget = normalizeAngle(targetRotation);
    let delta = normalizedTarget - normalizedCurrent;

    if (delta > Math.PI) {
      delta -= Math.PI * 2;
    } else if (delta < -Math.PI) {
      delta += Math.PI * 2;
    }

    return delta;
  };

  useEffect(() => {
    if (reorderedData[0]?.tile_name) {
      spinToTile(0);
    }
  }, [reorderedData]);

  // Function to spin to a specific tile
  const spinToTile = useCallback(
    (index: number) => {
      // Calculate the target rotation
      const targetRotation = normalizeAngle(index * anglePerTile - FRONT_ALIGNMENT_OFFSET + GLOBAL_ROTATION_OFFSET);

      // Adjust for the camera's rotation and apply the front alignment offset
      const cameraForward = new THREE.Vector3(0, 0, -1).applyQuaternion(camera.quaternion);
      const cameraAngle = Math.atan2(cameraForward.x, cameraForward.z);

      const correctedRotation = normalizeAngle(targetRotation + cameraAngle - FRONT_ALIGNMENT_OFFSET + GLOBAL_ROTATION_OFFSET);

      // Set the target rotation and initiate auto-spinning
      setTargetRotation(correctedRotation);
      setIsAutoSpinning(true);

      // Update the selected tile
      const selectedTile = reorderedData[index]?.tile_name;
      handleTileSelect(selectedTile);
    },
    [anglePerTile, camera.quaternion, handleTileSelect, GLOBAL_ROTATION_OFFSET, reorderedData],
  );

  // Wheel Event Handler
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.deltaX !== 0) {
        event.preventDefault();
        const rotationDelta = event.deltaX * -HORIZONTAL_SCROLL_MULTIPLIER;

        if (groupRef.current) {
          groupRef.current.rotation.y += rotationDelta;
          scrollAccumulator.current += event.deltaX;

          if (Math.abs(scrollAccumulator.current) >= Math.abs(deltaXPerTile)) {
            const direction = scrollAccumulator.current > 0 ? 1 : -1;
            const newIndex = (currentTileIndexRef.current - direction + totalTiles) % totalTiles;
            spinToTile(newIndex);
            setCurrentTileIndex(newIndex);
            scrollAccumulator.current -= direction * deltaXPerTile;
          }
        }
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => window.removeEventListener('wheel', handleWheel);
  }, [deltaXPerTile, totalTiles, spinToTile]);

  // Use useFrame to handle auto spinning
  useFrame(() => {
    if (isAutoSpinning) {
      autoSpin();
    }
  });

  if (isLoading || !data || data.length === 0 || !data.every((tile) => tile?.tile_name)) {
    return null;
  }

  return (
    <group ref={groupRef}>
      {reorderedData.map((tile, i) => {
        const angle = (i / totalTiles) * Math.PI * 2;
        const x = Math.cos(angle) * CAROUSEL_RADIUS;
        const z = Math.sin(angle) * CAROUSEL_RADIUS;
        const isSelected = i === currentTileIndex;

        return <Tile key={i} position={[x, 0, z]} name={tile.tile_name} scale={1} onClick={() => spinToTile(i)} isSelected={isSelected} />;
      })}
    </group>
  );
};
