import React, { useEffect, useRef } from 'react';
import { IoSend } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa';
import { MdOutlineSmsFailed } from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import { gsap } from 'gsap';
import { BigCirclesButton } from '../molecules/LinkCircles.styles';

interface SendButtonProps {
  status: 'idle' | 'sending' | 'success' | 'failed' | 'needToCorrect';
  onClick: () => Promise<void>;
}

export default function SendButton({ status, onClick }: SendButtonProps) {
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (status === 'success') {
      gsap.fromTo(buttonRef.current, { scale: 1, opacity: 1 }, { scale: 1.2, opacity: 1, duration: 0.8, ease: 'elastic.out(1, 0.3)' });
    }
  }, [status]);

  const handleClick = async () => {
    if (status === 'needToCorrect') {
      return; // Don't proceed if the status is 'needToCorrect'
    }

    await onClick();
  };

  const handleMouseEnter = () => {
    gsap.to(buttonRef.current, {
      scale: 1.2,
      ease: 'elastic.out(1, 0.4)',
      duration: 0.5,
      borderColor: '#D9D9D9',
      color: '#D9D9D9',
    });
  };

  const handleMouseLeave = () => {
    gsap.to(buttonRef.current, {
      scale: 1,
      ease: 'elastic.out(1, 0.4)',
      duration: 0.5,
      borderColor: '',
      color: '',
    });
  };

  return (
    <BigCirclesButton ref={buttonRef} status={status} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} as="div" className="hoverable">
      {status === 'idle' && <IoSend size={24} />}
      {status === 'sending' && <ThreeDots visible={true} height="24" width="24" color="#FFFFFF" radius="9" ariaLabel="three-dots-loading" />}
      {status === 'success' && <FaCheck size={24} />}
      {status === 'failed' && <MdOutlineSmsFailed size={24} />}
      {status === 'needToCorrect' && <IoSend size={24} />} {/* Same icon for 'needToCorrect' */}
    </BigCirclesButton>
  );
}
