import React, { createContext, useState, useContext, ReactNode, useEffect, useRef, useCallback } from 'react';
import { useActiveSection } from './ActiveSectionContext';
import { debounce } from 'lodash'; // You can use lodash for debouncing

const SCROLL_OFFSET = 150; // Adjust this value as needed

interface GuideContextType {
  guideMessage: string;
  setGuideMessage: (message: string, delay?: number) => void;
  fadeOutGuide: () => void;
}

const GuideContext = createContext<GuideContextType | undefined>(undefined);

export const useGuide = () => {
  const context = useContext(GuideContext);
  if (!context) {
    throw new Error('useGuide must be used within a GuideProvider');
  }
  return context;
};

export const GuideProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [guideMessage, setGuideMessageState] = useState('');
  const [messageCount, setMessageCount] = useState<{ [key: string]: number }>({});
  const { activeSection } = useActiveSection();
  const initialScrollPosition = useRef<number | null>(null);

  // Update guide message if the current message hasn't appeared too frequently
  const setGuideMessage = useCallback(
    (message: string, delay: number = 0) => {
      if (guideMessage !== message) {
        // Prevent unnecessary updates if message is the same
        const currentCount = messageCount[message] || 0;
        if (currentCount < 2) {
          setTimeout(() => {
            setGuideMessageState(message);
            setMessageCount((prev) => ({
              ...prev,
              [message]: (prev[message] || 0) + 1,
            }));
          }, delay);
        }
      }
    },
    [messageCount, guideMessage],
  );

  const fadeOutGuide = useCallback(() => {
    setGuideMessageState(''); // Fade out the message
  }, []);

  // Debounced scroll handler to avoid frequent state changes on scroll
  const handleScroll = useCallback(
    debounce(() => {
      if (initialScrollPosition.current !== null) {
        const currentScrollPosition = window.scrollY;
        if (Math.abs(currentScrollPosition - initialScrollPosition.current) > SCROLL_OFFSET) {
          fadeOutGuide(); // Only fade out if scroll distance exceeds threshold
        }
      }
    }, 200), // Adjust the debounce delay as needed
    [fadeOutGuide],
  );

  useEffect(() => {
    initialScrollPosition.current = window.scrollY;

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel(); // Cancel the debounce on cleanup
    };
  }, [activeSection, handleScroll]);

  return <GuideContext.Provider value={{ guideMessage, setGuideMessage, fadeOutGuide }}>{children}</GuideContext.Provider>;
};
