import React, { useState, useCallback } from 'react';
import MyNameIntroduction from '../molecules/MyNameIntroduction';
import WhoAmIArray from '../molecules/WhoAmIArray';
import UsePersonalData from '../../hooks/usePersonalData';


export default function NameAndArray() {
  const { whoAmIArray } = UsePersonalData();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleStringTyped = useCallback((index: number) => {
      setActiveIndex(index + 1);
      if (index === whoAmIArray.length - 1) {
        setActiveIndex(0);
      }
      
  }, []);

  return (
    <div>
      <MyNameIntroduction WhoAmIArray={whoAmIArray} onStringTyped={handleStringTyped} />
      <WhoAmIArray WhoAmIArray={whoAmIArray} Active_Index={activeIndex} />
    </div>
  );
}
