import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import { SegmentKey } from '../../types/segments';

// Adjustable parameters
const PARAMETERS = {
  ARROW_COUNT: 5,
  ARROW_WIDTH: 90,
  ARROW_HEIGHT: 90,
  ARROW_THICKNESS: 6,
  MIN_OPACITY: 0.03,
  MAX_OPACITY: 0.15,
  HOVER_OPACITY: 1,
  ANIMATION_DURATION: 1.4, // seconds for entire animation
  ANIMATION_STAGGER: 0.25, // seconds between each arrow's animation start
  Y_MOVEMENT: 30, // pixels
  SCALE_UP: 1.2, // scale factor
  SMALL_CYCLE_PAUSE: 0.5, // seconds to pause between small cycles
  BIG_CYCLE_PAUSE: 3, // seconds to pause between big cycles
  INITIAL_DELAY: 4, // seconds before animation starts
  SMALL_CYCLES: 3, // number of small cycles before a big pause
};

// Hover parameters
const HOVER_PARAMETERS = {
  ARROW_COUNT: 5,
  ARROW_WIDTH: 90,
  ARROW_HEIGHT: 90,
  ARROW_THICKNESS: 6,
  MIN_OPACITY: 0.3,
  MAX_OPACITY: 0.4,
  HOVER_OPACITY: 0.4,
  ANIMATION_DURATION: 1, // seconds for entire animation
  ANIMATION_STAGGER: 0.15, // seconds between each arrow's animation start
  Y_MOVEMENT: 30, // pixels
  SCALE_UP: 1.2, // scale factor
  SMALL_CYCLE_PAUSE: 0.0, // seconds to pause between small cycles
  BIG_CYCLE_PAUSE: 3, // seconds to pause between big cycles
  INITIAL_DELAY: 4, // seconds before animation starts
  SMALL_CYCLES: 3, // number of small cycles before a big pause
};

const ArrowContainer = styled(motion.div)<{ hasSectionChanged: boolean }>`
  position: relative;
  display: ${({ hasSectionChanged }) => (hasSectionChanged ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 30px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out 4s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ArrowHead = styled(motion.div)`
  width: ${PARAMETERS.ARROW_WIDTH}px;
  height: ${PARAMETERS.ARROW_HEIGHT}px;
  border-left: ${PARAMETERS.ARROW_THICKNESS}px solid #fff;
  border-bottom: ${PARAMETERS.ARROW_THICKNESS}px solid #fff;
  transform: rotate(-45deg) !important;
  margin: -30px 0;
`;

const LetsGoText = styled(motion.div)`
  position: absolute;
  width: fit-content;
  font-size: 35px;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  drop-shadow: (0 4px 6px rgba(0, 0, 0, 0.8));
  z-index: 1;
`;

const LetsGoContainer = styled(motion.div)`
  position: relative;
`;

const containerVariants = {
  initial: { opacity: 1 },
  hover: {
    opacity: HOVER_PARAMETERS.HOVER_OPACITY,
    transition: { duration: 1, ease: 'easeInOut' },
  },
};

const textVariants = {
  initial: { opacity: 0 },
  hover: {
    opacity: 1,
    dropShadow: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.8))',
    transition: { duration: 0.8, delay: 0.2 },
  },
};

const arrowVariants = {
  initial: { y: 0, opacity: PARAMETERS.MIN_OPACITY, scale: 1 },
  hover: {
    y: PARAMETERS.Y_MOVEMENT,
    opacity: PARAMETERS.MAX_OPACITY,
    scale: PARAMETERS.SCALE_UP,
    transition: {
      duration: 1,
      delay: 0.2,
      ease: 'easeInOut',
    },
  },
  lightUp: {
    opacity: HOVER_PARAMETERS.HOVER_OPACITY,
    transition: {
      duration: 0.6,
      ease: [0.68, -0.55, 0.27, 1.55], // Elastic ease
    },
  },
};

export default function LetsGo() {
  const { activeSection, setActiveSection } = useActiveSection();
  const [hasSectionChanged, setHasSectionChanged] = useState(false);
  const prevActiveSectionRef = useRef(activeSection);

  useEffect(() => {
    if (activeSection !== prevActiveSectionRef.current) {
      setHasSectionChanged(true);
      prevActiveSectionRef.current = activeSection;
    }
  }, [activeSection]);

  const controls = useAnimation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [ishovered, setishovered] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    const animateArrows = async () => {
      while (isMounted) {
        if (!ishovered) {
          for (let smallCycle = 0; smallCycle < PARAMETERS.SMALL_CYCLES; smallCycle++) {
            if (!isMounted || ishovered) break;
            await controls.start((i) => ({
              y: PARAMETERS.Y_MOVEMENT,
              opacity: PARAMETERS.MAX_OPACITY,
              scale: PARAMETERS.SCALE_UP,
              transition: {
                duration: PARAMETERS.ANIMATION_DURATION / 2,
                delay: i * PARAMETERS.ANIMATION_STAGGER,
                ease: 'easeInOut',
              },
            }));

            if (!isMounted || ishovered) break;
            await controls.start((i) => ({
              y: 0,
              opacity: PARAMETERS.MIN_OPACITY,
              scale: 1,
              transition: {
                duration: PARAMETERS.ANIMATION_DURATION / 2,
                delay: i * PARAMETERS.ANIMATION_STAGGER,
                ease: 'easeInOut',
              },
            }));

            if (smallCycle < PARAMETERS.SMALL_CYCLES - 1 && !ishovered) {
              await new Promise((resolve) => setTimeout(resolve, PARAMETERS.SMALL_CYCLE_PAUSE * 1000));
            }
          }

          if (!ishovered && isMounted) {
            await new Promise((resolve) => setTimeout(resolve, PARAMETERS.BIG_CYCLE_PAUSE * 1000));
          }
        } else {
          await new Promise((resolve) => setTimeout(resolve, 100)); // Small delay to prevent rapid state changes
        }
      }
    };

    animateArrows();
  }, [controls, ishovered, isMounted]);

  const handleHoverStart = () => {
    setishovered(true);
    controls.start({
      y: PARAMETERS.Y_MOVEMENT,
      opacity: HOVER_PARAMETERS.HOVER_OPACITY,
      scale: PARAMETERS.SCALE_UP,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    });
  };

  const handleHoverEnd = () => {
    setishovered(false);
    controls.start({
      y: 0,
      opacity: PARAMETERS.MIN_OPACITY,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    });
  };

  return (
    <LetsGoContainer
      className="hoverable"
      ref={containerRef}
      initial="initial"
      whileHover="hover"
      variants={containerVariants}
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
      onClick={() => setActiveSection(SegmentKey.ABOUT_ME)}
    >
      <LetsGoText variants={textVariants}>
        LET'S GO!
        <br />
        VAMOS
        <br />
        SCROLL
      </LetsGoText>
      <ArrowContainer hasSectionChanged={hasSectionChanged} onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd}>
        {[...Array(PARAMETERS.ARROW_COUNT)].map((_, index) => (
          <ArrowHead key={index} initial={{ y: 0, opacity: PARAMETERS.MIN_OPACITY, scale: 1 }} animate={controls} custom={index} variants={arrowVariants} />
        ))}
      </ArrowContainer>
    </LetsGoContainer>
  );
}
