export const extractJsonEntitiesWithDescription = (technologies_about: any[]): { tech: string; desc: string; url?: string }[] => {
  return technologies_about
    .map((paragraph: any) => {
      const technologyMatch = paragraph.children.find((child: any) => child.type === 'text' && /_(.*?)_/.test(child.text));
      if (technologyMatch) {
        const tech = technologyMatch.text.match(/_(.*?)_/)[1];
        const desc = paragraph.children
          .map((child: any) => {
            return child.text ? child.text.replace(/_.*?_/g, '').trim() : '';
          })
          .join(' ');

        const urlMatch = paragraph.children.find((child: any) => child.type === 'link');
        const url = urlMatch ? urlMatch.url : undefined;

        return { tech, desc, url };
      }
      return null;
    })
    .filter((item: any): item is { tech: string; desc: string; url?: string } => item !== null) as { tech: string; desc: string; url?: string }[];
};
