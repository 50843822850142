import React, { createContext, useState, useContext } from 'react';

const LoadingContext = createContext({
  isLoadingContext: false,
  setLoadingContext: (loading: boolean) => {},
});

export const LoadingProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [isLoadingContext, setIsLoadingContext] = useState<boolean>(false);

  const setLoadingContext = (loading: boolean) => {
    setIsLoadingContext(loading);
  };

  return <LoadingContext.Provider value={{ isLoadingContext, setLoadingContext }}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => useContext(LoadingContext);
