import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import Typography from '../../atoms/Typography';
import { TypographyVariant } from '../../atoms/Typography.autogen';
import Section from '../../organisms/Section';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import { useData } from '../../../contexts/DataContext';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { CoverContainer, HeaderWrapper, InfoWrapper, IssuerLogo, SectionWrapper, StyledTileWrapper, DescWrapper } from './Certifications.styles';
import withMagneticEffect from '../../../hooks/withMagneticEffect';
import { gsap } from 'gsap';
import { useOptimizer } from '../../../contexts/OptimizerContext';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
const Certifications: React.FC = () => {
  const { activeSection } = useActiveSection(); // Get the current active section
  const { data, loading, error } = useData('certificates');
  const [maxHeight, setMaxHeight] = useState('auto');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const tilesRef = useRef<(HTMLDivElement | null)[]>([]); // Refs to all tile elements

  const { performanceLevel } = useOptimizer();

  const CertTileWithMagneticEffect = withMagneticEffect(SectionWrapper);

  const getTransformedImageUrl = (url: string, width: number) => {
    return url.replace('/upload/', `/upload/w_${width},c_scale/`);
  };

  // Handle window resize
  useLayoutEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // GSAP animation for sliding up the tiles
  useLayoutEffect(() => {
    if (performanceLevel === 'low') {
      // For low performance, just set the elements to be visible without animations
      gsap.set(tilesRef.current, { autoAlpha: 1, y: 0 });
    } else if (activeSection === 'CERTIFICATIONS' && data && data.length > 0 && tilesRef.current.length > 0) {
      const timeline = gsap.timeline();

      // Initially hide all tiles (set opacity to 0 and translateY to 50px)
      gsap.set(tilesRef.current, { autoAlpha: 0, y: 50 });

      // Slide up animation with increased duration and smoother easing
      timeline.to(tilesRef.current, {
        autoAlpha: 1,
        y: 0,
        duration: 1.2, // Longer duration for smoother animation
        stagger: {
          each: 0.4,
          // from: 'start', // Staggering from left to right
        },
        ease: 'power3.out', // Smoother easing function
      });
    }
  }, [activeSection, data]); // Trigger the effect when activeSection or data changes

  if (loading) {
    return <LoadingPage isFullPage={false} isLoading={loading} />;
  }
  if (error) {
    return <ErrorPage isFullPage={false} sourceOfError="certifications" />;
  }

  const isSmallWindow = windowWidth < 900;

  // function to make titles same length so it take all names count character s and for those shorter than longets one add blank spaces as character to make it euqal
  const makeTitlesSameLength = (titles: string[]) => {
    const longestTitle = titles.reduce((longest, title) => (title.length > longest.length ? title : longest), titles[0]);
    return titles.map(title => title.padEnd(longestTitle.length, ' '));
  };

  return (
    <Section title="Certifications">
      <SectionWrapper>
        {data &&
          data.map((cert: any, index: number) => (
            <CertTileWithMagneticEffect
              key={index}
              className="section-wrapper-element"
              ref={(el) => {
                tilesRef.current[index] = el;
              }}
              style={{ opacity: 0, transform: 'translateY(50px)' }}
            >
              <StyledTileWrapper
                key={index}
                backgroundImage={windowWidth < 900 || windowHeight >= 800 ? getTransformedImageUrl(cert.cert_cover?.data?.[0]?.attributes?.url, 500) : ''}
              >
                <InfoWrapper>
                  <IssuerLogo src={getTransformedImageUrl(cert.cert_issuer_logo?.data?.[0]?.attributes?.url, 200)} alt="Issuer logo" />
                  <HeaderWrapper>
                    <Typography animated animationType="fade" variant={TypographyVariant.sectionHeader} align={'left'}>
                      {makeTitlesSameLength([cert.cert_name])[0]}
                    </Typography>
                  </HeaderWrapper>
                  <DescWrapper className="desc-wrapper" height={maxHeight} style={{ maxHeight: maxHeight }}>
                    {!isSmallWindow && (
                      <div style={{ marginBottom: '6px' }}>
                        <Typography animated animationType="fade" variant={TypographyVariant.micro} align={'left'}>
                          {format(new Date(cert.cert_date), 'MMM yyyy')}
                        </Typography>
                      </div>
                    )}
                    <Typography animated animationType="fade" variant={TypographyVariant.sectionDescription} align={'left'}>
                      {cert.cert_desc}
                    </Typography>
                  </DescWrapper>
                </InfoWrapper>
                {!isSmallWindow && (
                  <CoverContainer>
                    <img src={getTransformedImageUrl(cert.cert_cover?.data?.[0]?.attributes?.url, 300)} alt="Certificate themed image" style={{ width: '100%', height: 'auto' }} />
                  </CoverContainer>
                )}
              </StyledTileWrapper>
            </CertTileWithMagneticEffect>
          ))}
      </SectionWrapper>
    </Section>
  );
};

export default Certifications;
