import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

interface HoverBlockProps {
  blockAction: string;
  isVisible: boolean;
}

const HoverBlockWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 99;
  backdrop-filter: blur(10px);
  opacity: 0;
  scale: 0.8;
  background-color: ${({ theme }) => theme.colors.greyPrimary20};
`;

const BlockAction = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.whitePrePrimary};
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  z-index: 100;
`;

const HoverBlock: React.FC<HoverBlockProps> = ({ blockAction, isVisible }) => {
  const blockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible) {
      gsap.to(blockRef.current, {
        duration: 0.5,
        opacity: 0.8,
        scale: 1,
        ease: 'power2.inOut',
      });
    } else {
      gsap.to(blockRef.current, {
        duration: 0.5,
        opacity: 0,
        scale: 0.8,
        ease: 'power2.inOut',
      });
    }
  }, [isVisible]);

  return (
    <HoverBlockWrapper ref={blockRef} className="hover-block">
      <BlockAction>{blockAction}</BlockAction>
    </HoverBlockWrapper>
  );
};

export default HoverBlock;
