import React, { useEffect, useRef } from 'react';
import Typography from '../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import Typed from 'typed.js';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import { useOptimizer } from '../../contexts/OptimizerContext';
import UsePersonalData from '../../hooks/usePersonalData';

export interface MyNameIntroductionProps {
  WhoAmIArray: string[];
  onStringTyped: (index: number) => void;
}

const MyNameIntroductionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  opacity: 0; // Initial state for GSAP animation

  @media (max-width: 768px) {
    min-width: 98vw;
    max-width: 98vw;
    min-height: 200px;
    margin-top: -50px;
    scale: 0.9;
    & > div:nth-child(2) {
      min-height: 75px;
    }
  }
`;

const IAmWrapper = styled.div`
  @media (max-width: 768px) {
    height: 86px;
  }
`;

const MyNameIntroduction: React.FC<MyNameIntroductionProps> = ({ WhoAmIArray, onStringTyped }) => {
  const typedRef = useRef<HTMLSpanElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { activeSection } = useActiveSection();
  const { performanceLevel } = useOptimizer();
  const { nameSurname } = UsePersonalData();

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  useEffect(() => {
    if (typedRef.current && WhoAmIArray && WhoAmIArray.length > 0) {
      const typed = new Typed(typedRef.current, {
        strings: WhoAmIArray,
        typeSpeed: 80,
        backSpeed: 23,
        loop: true,
        onStringTyped: (index) => onStringTyped(index),
      });

      return () => {
        typed.destroy(); // Destroy instance on cleanup
      };
    }
  }, [WhoAmIArray, onStringTyped]);

  useEffect(() => {
    if (wrapperRef.current) {
      if (!isSafari) {
        if (activeSection === 'PERSONAL' && performanceLevel !== 'low') {
          gsap.fromTo(
            wrapperRef.current,
            { y: '-100%', opacity: 0 },
            { y: '0%', opacity: 1, duration: 1.2, ease: 'power2.out' }
          );
        } else if (activeSection !== 'PERSONAL' && performanceLevel !== 'low') {
          gsap.to(wrapperRef.current, {
            y: '-100%',
            opacity: 0,
            duration: 0.8,
            ease: 'power2.in',
          });
        } else {
          // For low performance or when not animating, ensure it's visible
          gsap.set(wrapperRef.current, { y: '0%', opacity: 1 });
        }
      } else {
        // For Safari, ensure it's visible without animations
        gsap.set(wrapperRef.current, { y: '0%', opacity: 1 });
      }
    }
  }, [activeSection, performanceLevel, isSafari]);

  return (
    <MyNameIntroductionWrapper ref={wrapperRef}>
      <Typography animated animationType="fade" highlight_level={HighlightLevel.active} variant={TypographyVariant.megaSubHeader}>
        {nameSurname}
      </Typography>
      <IAmWrapper>
        <Typography animated animationType="fade" highlight_level={HighlightLevel.highlighted} variant={TypographyVariant.megaHeader}>
          I'm {WhoAmIArray && WhoAmIArray.length > 0 ? <span ref={typedRef} /> : 'a Developer'}
        </Typography>
      </IAmWrapper>
    </MyNameIntroductionWrapper>
  );
};

export default React.memo(MyNameIntroduction);
