import { keyframes, styled } from 'styled-components';

export const FullPageWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000000;
  z-index: 250;
`;

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const ErrorPageWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s forwards;
`;
export const TextMiddle = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 320;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s forwards;
`;

export const ErrorZoneWrapper = styled.div`
  z-index: 310;
  width: 100%;
  height: 100vh;
  margin-top: -100px;
  @media (max-width: 768px) {
    transform: scale(0.6);
    margin-left: -16px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;

  img {
    border-radius: 50%;
    width: 420px;
    height: 420px;
  }
`;

export const ErrorZone = styled.div`
  z-index: 310;
  width: 420px;
  height: 420px;
  border-radius: 50%;
  box-shadow: 0px 0px 97px 100px rgba(15, 8, 15, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
`;

export const LinksRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: 18px;
  transition: opacity 0.3s ease;
  color: ${({ theme }) => theme.colors.whitePrePrimary};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.colors.whitePrimary};
    transform: scale(1.2);
    transition: transform 0.3s ease;
    opacity: 0.7;
  }

  svg {
    width: 40px;
    height: 40px;
    color: inherit;
  }
`;

