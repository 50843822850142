import styled, { keyframes } from "styled-components";

// Keyframes for the sliding animation
export const slide = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden; /* Hide overflowing content */
  display: flex;
  align-items: center; /* Center the text vertically */
  position: absolute;
  z-index: 1000; /* Ensure it's above other elements */

  /* Apply mask to create fade effect on both sides */
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 95%);
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
`;

export const SliderContent = styled.div<{ opacity: number }>`
  display: flex;
  flex-shrink: 0;
  white-space: nowrap;
  animation: ${slide} 800s linear infinite; // Adjust the duration for speed
  opacity: ${({ opacity }) => opacity}; // Apply the dynamic opacity
`;

export const SliderItem = styled.div`
  display: inline-block;
  flex-shrink: 0;
  padding-right: 3px; // Add space between items
  padding-left: 3px; // Add space between items
`;
