import React, { useRef, useEffect, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { gsap } from 'gsap';
import { PARAMETERS } from './ParticlesWithCarousel.utils';
import Particles from './Particles';
import { Carousel } from './Carousel';

interface ParticlesWithCarouselProps {
  depthForParticles: number;
  mouseXRef: React.RefObject<number>;
  mouseYRef: React.RefObject<number>;
}

// Constants for tilt, zoom, and smoothing
const MAX_TILT_X_DEGREES = 6; // Maximum tilt for X-axis in degrees
const MIN_TILT_X_DEGREES = -6; // Minimum tilt for X-axis in degrees
const MAX_TILT_Y_DEGREES = 6; // Maximum tilt for Y-axis in degrees
const MIN_TILT_Y_DEGREES = -6; // Minimum tilt for Y-axis in degrees
const MAX_TILT_X_RADIANS = THREE.MathUtils.degToRad(MAX_TILT_X_DEGREES);
const MIN_TILT_X_RADIANS = THREE.MathUtils.degToRad(MIN_TILT_X_DEGREES);
const MAX_TILT_Y_RADIANS = THREE.MathUtils.degToRad(MAX_TILT_Y_DEGREES);
const MIN_TILT_Y_RADIANS = THREE.MathUtils.degToRad(MIN_TILT_Y_DEGREES);

const MAX_ZOOM = 0.036; // Maximum zoom level
const MIN_ZOOM = 0.032; // Minimum zoom level
const TILT_SMOOTHING = 0.1; // Smoothing factor for tilt
const ZOOM_SMOOTHING = 0.2; // Smoothing factor for zoom
const SCALE = 0.0002;
const ZOOM_BUFFER = 400; // Buffer in pixels around the center

const ParticlesWithCarousel: React.FC<ParticlesWithCarouselProps> = ({ depthForParticles, mouseXRef, mouseYRef }) => {

  // up from 1400px to 3000make const screenZoom decrease linearly  to 0.6 but from 1400 to 700 make it increase linearly from 1 to 1.5
  const screenZoom = useMemo(() => {
    const screenWidth = window.innerWidth;
    if(screenWidth > 1400){
      return THREE.MathUtils.mapLinear(screenWidth, 1400, 2400, 1, 0.65);
    } else {
      return THREE.MathUtils.mapLinear(screenWidth, 1400, 700, 1, 3.4);
    }
  }, []);

  const groupRef = useRef<THREE.Group>(null);
  const targetZoomRef = useRef(1); // Ref to keep track of the target zoom level


  // Get screen dimensions
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const screenCenter = new THREE.Vector2(screenWidth / 2, screenHeight / 2);

  // Tilt and Zoom effect using mouse coordinates
  useFrame(() => {
    if (!groupRef.current) return;

    // Calculate normalized mouse positions (-1 to 1) relative to screen center
    const mouseX = (mouseXRef.current! - screenCenter.x) / screenCenter.x;
    const mouseY = (mouseYRef.current! - screenCenter.y) / screenCenter.y;

    // Calculate tilt based on mouse position
    const tiltX = THREE.MathUtils.clamp(-mouseY * MAX_TILT_X_RADIANS, MIN_TILT_X_RADIANS, MAX_TILT_X_RADIANS);
    const tiltY = THREE.MathUtils.clamp(mouseX * MAX_TILT_Y_RADIANS, MIN_TILT_Y_RADIANS, MAX_TILT_Y_RADIANS);

    // Smooth tilt transition
    groupRef.current.rotation.x += (tiltX - groupRef.current.rotation.x) * TILT_SMOOTHING;
    groupRef.current.rotation.y += (tiltY - groupRef.current.rotation.y) * TILT_SMOOTHING;

    // Calculate distance from center of screen and apply buffer for zoom
    const distanceFromCenter = Math.sqrt(Math.pow(mouseXRef.current! - screenCenter.x, 2) + Math.pow(mouseYRef.current! - screenCenter.y, 2));
    const maxDistance = Math.sqrt(Math.pow(screenWidth / 2, 2) + Math.pow(screenHeight / 2, 2));
    const normalizedDistance = Math.max(0, distanceFromCenter - ZOOM_BUFFER) / (maxDistance - ZOOM_BUFFER);

    // Calculate new zoom based on distance from center
    const newScale = THREE.MathUtils.mapLinear(
      normalizedDistance,
      1,
      0,
      MIN_ZOOM,
      MAX_ZOOM
    );
    // Smooth zoom transition
    const currentScale = groupRef.current.scale.x; // Assuming uniform scaling
    groupRef.current.scale.set(
      currentScale + (newScale - currentScale) * ZOOM_SMOOTHING,
      currentScale + (newScale - currentScale) * ZOOM_SMOOTHING,
      currentScale + (newScale - currentScale) * ZOOM_SMOOTHING,
    );
  });

  const isMobile = window.innerWidth < 900;

  // Handle zoom animation with gsap (triggered when depthForParticles changes)
  useEffect(() => {
    const newZoom = THREE.MathUtils.mapLinear(depthForParticles, 1, 3, PARAMETERS.MIN_ZOOM, PARAMETERS.MAX_ZOOM);
    gsap.to(targetZoomRef, {
      current: newZoom,
      duration: ZOOM_SMOOTHING,
      ease: 'power2.out',
    });
  }, [depthForParticles]);

  const RESPONSIVE_SCALE = SCALE * screenZoom;

  return (
    <>
      <group ref={groupRef} position={[0, 27, -8]} scale={[RESPONSIVE_SCALE, RESPONSIVE_SCALE, RESPONSIVE_SCALE]}>
        <Particles depthForParticles={depthForParticles} />
      </group>
      {!isMobile && (
        <group position={[0, 22, -1]}>
          <Carousel />
        </group>
      )}
    </>
  );
};

export default ParticlesWithCarousel;
