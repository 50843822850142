import styled from "styled-components";

export const MasterSceneWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const TrapezoidCanvas = styled.div`
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  mask-image: linear-gradient(to bottom, transparent 70%, black 100%);
`;

export const MaskLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent 00%, black 20%);

   
`;

export const MaskRight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  mask-image: linear-gradient(to left, transparent 0%, black 15%);
`;
