// SSGallerySection.styles.ts
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledImage = styled(motion.img)`
  border-radius: 10px;
  object-fit: cover; /* Use cover to fill the available area */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background-color: white;

  @media (min-height: 1080px) {
    object-fit: contain;
  }
`;

export const SSGallerySectionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
`;

export const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 550;
  pointer-events: auto; /* Allow interaction */
  opacity: 0;

  &:hover {
    opacity: 1;
    
  }
`;

export const Arrow = styled.div`
  border: solid rgba(0, 0, 0, 0.5);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s;
  pointer-events: none;
`;

export const GradientOverlayLeft = styled(GradientOverlay)`
  left: 0;  
  background: linear-gradient(to right, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 50px);

  &:hover ${Arrow} {
    animation: fadeIn 0.3s ease-in-out;

    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    opacity: 1;
    pointer-events: auto;
  }
`;

export const GradientOverlayRight = styled(GradientOverlay)`
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 50px);

  &:hover ${Arrow} {
    animation: fadeIn 0.3s ease-in-out;

    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    opacity: 1;
    pointer-events: auto;
  }
`;

export const ArrowLeft = styled(Arrow)`
  transform: rotate(135deg);
`;

export const ArrowRight = styled(Arrow)`
  transform: rotate(-45deg);
`;

export const MiddleClickOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20%;
  right: 20%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
`;


export const FullscreenIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  svg {
    width: 24px;
    height: 24px;
    color: rgba(0, 0, 0, 0.5);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
    position: relative;
  }

  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s;
  pointer-events: none;

  ${MiddleClickOverlay}:hover & {
    opacity: 1;
    pointer-events: auto;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 6px;
    width: 64px;
    height: 64px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 90%);
    border-radius: 50%;
  }
`;
