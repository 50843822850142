import React from 'react';
import { useState, useEffect } from "react";
import { clickables } from "../config/clickables";
import styled from "styled-components";
import AnimatedCursor from "react-animated-cursor";

  const StyledAnimatedCursor = styled(AnimatedCursor)`
    z-index: 999999;
    @media (max-width: 768px) {
      display: none;
    }
  `;


export const CustomAnimatedCursor = () => {
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const handleMouseOver = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest(clickables.join(','))) {
        setIsHovering(true);
      }
    };

    const handleMouseOut = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest(clickables.join(','))) {
        setIsHovering(false);
      }
    };

    document.addEventListener('mouseover', handleMouseOver);
    document.addEventListener('mouseout', handleMouseOut);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
      document.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      {!isMobile && (
        <StyledAnimatedCursor
        innerSize={isMobile ? 0 : 12}
        outerSize={isHovering ? 60 : 45}
        color="245, 56, 56"
        outerAlpha={isMobile ? 0 : 0.3}
        innerScale={isMobile ? 0 : 1.3}
        outerScale={isHovering ? 3 : 2.3}
        showSystemCursor={true}
          clickables={clickables}
        />
      )}
    </>
  );
};
