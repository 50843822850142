import React, { useMemo, useRef, useEffect, useLayoutEffect } from 'react';
import Section from '../../organisms/Section';
import { useData } from '../../../contexts/DataContext';
import withMagneticEffect from '../../../hooks/withMagneticEffect';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import styled from 'styled-components';
import { TypographyVariant } from '../../atoms/Typography.autogen';
import Typography from '../../atoms/Typography';
import { SectionWrapper, EduTilesWrapper, UniLogo, StyledTileWrapper, UniDetailsWrapper, AcronymWrapper, DescWrapper, SeparationLine } from './Education.styles';
import { gsap } from 'gsap';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import { useOptimizer } from '../../../contexts/OptimizerContext';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';

// Ensure the sections are hidden initially with CSS
const StyledSectionWrapper = styled(SectionWrapper)`
  opacity: 0;
`;

const Education: React.FC = () => {
  const { data, loading, error } = useData('education');
  const sections = useMemo(() => data || [], [data]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]); // Refs for each section
  const { activeSection } = useActiveSection();

  const wrapperWidth = wrapperRef.current?.offsetWidth;
  const wrapperHeight = window.innerHeight * 0.86;

  const EntitySectionWithMagneticEffect = withMagneticEffect(StyledSectionWrapper);

  const sectionHeight = `${wrapperHeight! / sections.length - 38}px`;
  const offsetGap = wrapperWidth ? wrapperWidth / sections.length / 3 : 0;
  const sectionMarginRight = (index: number) => `${index * offsetGap}px`;
  const sectionWidth = `${100 - 100 * (1 / sections.length)}%`;
  const { performanceLevel } = useOptimizer();

  useLayoutEffect(() => {
    if (sectionRefs.current.length) {
      const elements = sectionRefs.current;

      if (performanceLevel === 'low') {
        // For low performance, just set the elements to be visible without animations
        gsap.set(elements, { autoAlpha: 1, x: '0%', y: '0%' });
        gsap.set(wrapperRef.current, { autoAlpha: 1 });
      } else {
        // Ensure all elements start off hidden
        gsap.set(elements, { autoAlpha: 0, x: '-30%', y: '10%' });

        if (activeSection === 'EDUCATION') {
          gsap.to(wrapperRef.current, { autoAlpha: 1, duration: 0.3 });
          gsap.to(elements, {
            duration: 1,
            autoAlpha: 1,
            x: '0%',
            y: '0%',
            stagger: 0.3,
            ease: 'power2.out',
          });
        } else {
          gsap.to(elements, {
            autoAlpha: 0,
            x: '-30%',
            y: '10%',
            duration: 0.8,
            ease: 'power2.in',
          });
          gsap.to(wrapperRef.current, { autoAlpha: 0, duration: 0.3 });
        }
      }
    }
  }, [activeSection, performanceLevel]);

  if (loading) {
    return <LoadingPage isFullPage={false} isLoading={loading} />;
  }
  if (error) {
    return <ErrorPage isFullPage={false} sourceOfError="education" />;
  }

  return (
    <Section title="Education">
      <EduTilesWrapper ref={wrapperRef} style={{ opacity: 0 }}>
        {sections.map((item: any, index: number) => (
          <EntitySectionWithMagneticEffect
            ref={(el) => (sectionRefs.current[index] = el)} // Attach each ref to the array
            width={sectionWidth}
            height={sectionHeight}
            marginRight={sectionMarginRight(index)}
            key={index}
            className="section-wrapper-element"
            style={{ marginBottom: '48px' }}
          >
            <UniLogo src={item.uni_logo?.data?.attributes?.url} />
            <StyledTileWrapper>
              <UniDetailsWrapper>
                <AcronymWrapper>
                  <Typography animated animationType="fade" variant={TypographyVariant.sectionAcronymHugeHeader}>
                    {item.uni_name_acronym}
                  </Typography>
                </AcronymWrapper>
                <Typography animated animationType="fade" variant={TypographyVariant.sectionSubHeaderExtraSmallThin} align={'right'}>
                  {item.degree}
                </Typography>
                <Typography animated animationType="fade" variant={TypographyVariant.sectionSubHeaderExtraSmallThin} align={'right'}>
                  {item.uni_name}
                </Typography>
                <Typography animated animationType="fade" variant={TypographyVariant.sectionSubHeaderSmallThick} align={'right'}>
                  {item.major_name}
                </Typography>
              </UniDetailsWrapper>
              <SeparationLine />
              <DescWrapper>
                <Typography animated animationType="fade" variant={TypographyVariant.sectionDescription} align={'left'}>
                  {item.general_about}
                </Typography>
              </DescWrapper>
            </StyledTileWrapper>
          </EntitySectionWithMagneticEffect>
        ))}
      </EduTilesWrapper>
    </Section>
  );
};

export default Education;
