import React, { useState, useEffect, createContext, useContext } from 'react';

type PerformanceLevel = 'low' | 'mid' | 'high';

interface OptimizerContextType {
  originalPerformanceLevel: PerformanceLevel;
  performanceLevel: PerformanceLevel;
  togglePerformance: () => void;
}

const OptimizerContext = createContext<OptimizerContextType | undefined>(undefined);

export const useOptimizer = () => {
  const context = useContext(OptimizerContext);
  if (!context) {
    throw new Error('useOptimizer must be used within an OptimizerProvider');
  }
  return context;
};

export const OptimizerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [performanceLevel, setPerformanceLevel] = useState<PerformanceLevel | null>(null); // Start as `null` to avoid intermediate states
  const [originalPerformanceLevel, setOriginalPerformanceLevel] = useState<PerformanceLevel | null>(null);

  const togglePerformance = () => {
    performanceLevel === 'high' ? setPerformanceLevel('low') : setPerformanceLevel('high');
  };

  useEffect(() => {
    // Development mode: Uncomment the desired performance level
    const devMode = false;
    if (devMode) {
      // setPerformanceLevel('high');
      // setPerformanceLevel('mid');
      // setPerformanceLevel('low');
      return;
    }

    let fpsCount = 0;
    let frameTime = 0;
    const frameRateSamples: number[] = [];

    const calculateFPS = () => {
      return new Promise<void>((resolve) => {
        const fpsCheckDuration = 1000; // Time for FPS check (1 second)
        const fpsStartTime = performance.now();

        const measureFrame = () => {
          const now = performance.now();
          const delta = now - frameTime;
          frameTime = now;
          fpsCount = 1000 / delta;
          frameRateSamples.push(fpsCount);

          if (now - fpsStartTime >= fpsCheckDuration) {
            resolve();
          } else {
            requestAnimationFrame(measureFrame);
          }
        };

        frameTime = performance.now(); // Initialize frameTime
        requestAnimationFrame(measureFrame);
      });
    };

    const getAverageFPS = () => {
      const total = frameRateSamples.reduce((acc, fps) => acc + fps, 0);
      return frameRateSamples.length ? total / frameRateSamples.length : 0;
    };

    const checkPerformance = async () => {
      // 1. CPU Check (cores)
      const cores = navigator.hardwareConcurrency || 4;

      // 2. Memory Check
      const memory = (navigator as any).deviceMemory || 4; // Use 4GB as default if unavailable

      // 3. FPS Check (measure FPS for 1 second before site loads)
      await calculateFPS();
      const avgFPS = getAverageFPS();

      // Weighted score calculation based on CPU, memory, and FPS
      let score = 0;

      // load time param
      const loadTime = performance.now();
      if (loadTime < 1000) score += 2;
      else if (loadTime < 2000) score += 1;
      else score += 0;

      // CPU Cores
      if (cores >= 8) score += 4;
      else if (cores >= 4) score += 2;
      else if (cores >= 2) score += 1;

      // Memory
      if (memory >= 0.5) score += 4;
      else if (memory >=0.2) score += 3;
      else score += 0.1;

      // FPS (dynamic measure of actual performance)
      if (avgFPS >= 90) score += 4;
      else if (avgFPS >= 60) score += 2;
      else score += 1;
      if (avgFPS === Infinity) score += 4;

      // Determine performance level based on score (evaluate once)
      // make high, mid, low
      let finalPerformanceLevel: PerformanceLevel = 'mid';
      if (score >= 9) {
        finalPerformanceLevel = 'high';
      } else if (score <= 8) {
        finalPerformanceLevel = 'low';
      }

      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        finalPerformanceLevel = 'high';
      }

      console.log('Optimizer context logs for animations and 3d object fluency fine-tuning.')
      console.log('🚀 ~ checkPerformance ~ loadTime:', loadTime);
      console.log('🚀 ~ checkPerformance ~ cores:', cores);
      console.log('🚀 ~ checkPerformance ~ memory:', memory);
      console.log('🚀 ~ checkPerformance ~ avgFPS:', avgFPS);
      console.log('🚀 ~ checkPerformance ~ score:', score);
      console.log('🚀 ~ checkPerformance ~ finalPerformanceLevel:', finalPerformanceLevel);
      setPerformanceLevel(finalPerformanceLevel); // Set the determined value once
      setOriginalPerformanceLevel(finalPerformanceLevel); // Set the original value once
    };

    // Perform the check once on initial load
    checkPerformance();
  }, []);

  if (performanceLevel === null) {
    return null; // Return null while the performance is being calculated
  }

  if (originalPerformanceLevel === null) {
    return null; // Return null while the performance is being calculated
  }

  return <OptimizerContext.Provider value={{ performanceLevel, togglePerformance, originalPerformanceLevel }}>{children}</OptimizerContext.Provider>;
};
