import styled from 'styled-components';

import { TileWrapper } from '../sections/Experience/Experience.styles';
export const FormFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TransparentInput = styled.input`
  background: transparent !important;
  border: none;
  outline: none;
  width: 100%;
  color: inherit;
  font-size: 20px;
  padding: 8px;
  height: auto;
  overflow-y: hidden; /* Avoid scrolling in shortField */
`;

export const TransparentTextarea = styled.textarea`
  background: transparent !important;
  border: none;
  outline: none;
  width: -webkit-fill-available;
  color: inherit;
  font-size: 18px;
  padding: 8px;
  height: 100%;
  resize: none; /* Prevent resizing */
  overflow-y: auto; /* Allow scrolling in longField */
  line-height: 1.5;
`;

export const ShortFieldsWrapper = styled(TileWrapper)`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 900px) {
    width: calc(100% - 16px);
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const LongFieldWrapper = styled(TileWrapper)`
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-height: 900px) {
    height: 18vh;
  }
`;

export const InfoLabelWrapper = styled.div`
  margin: 4px;
  height: 18px;

  @media (max-width: 900px) {
    height: 12px;
  }
`;

export const LabelWrapper = styled.div`
  margin: 4px;
`;
