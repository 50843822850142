export const mriScansArrayOfImages: string[] = [
   'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-1_gv2jdl.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-2_vqkgyn.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-3_oyyewk.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-4_ikourj.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-5_gsqt7s.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-6_ojtyec.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-7_prwxy1.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-8_veo8pf.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-9_a7kumt.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-10_wxzixv.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-11_kp2q89.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-12_q2vn4i.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-13_p7de9k.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-14_fscjdi.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-15_lqsch7.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-16_hrsuw1.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-17_ikfykh.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-18_xv3gvw.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-19_ffnz3w.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-20_insove.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-21_vbvbxn.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-22_zsnaj4.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-23_exmg5a.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-24_xbqhh1.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-25_kuw5yx.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-26_hszzjx.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-27_fz8xz0.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-28_jqvjka.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-29_jwmugz.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-30_ucfsiu.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-31_ia6bf8.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-32_c3lbq5.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-33_rdgp2q.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-34_dubac7.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-35_ghzcv6.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-36_xw61rz.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-37_mmrhyv.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-38_t474e1.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-39_i4n5dy.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-40_stbyi0.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-41_ls11za.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-42_n3ypcm.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-43_stusdg.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-44_ouvhyl.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-45_ebpmtg.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-46_nfjfnk.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-47_sgzdzz.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-48_bp5cmq.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-49_bvmmxd.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-50_fep5jk.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-51_x8pok5.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-52_dmmdye.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-53_q0wk4g.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-54_jztpep.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-55_howvp4.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-56_k0pvjj.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-57_zqcmfj.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-58_cfx2mf.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-59_iivk2k.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-60_owqaus.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-61_vvhjan.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-62_ptfeoz.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-63_wgdub2.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-64_m1zhbs.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-65_txuun9.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-66_jyc3mn.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-67_lkkasi.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-68_t6elxc.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-69_yftjfr.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-70_mhxfn4.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-71_inxd5z.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-72_ct97ks.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-73_phhaml.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-74_s0nyf6.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-75_pbqa51.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-76_wwwrv4.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-77_aeijfa.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-78_htvwsv.jpg',
  'https://res.cloudinary.com/dyux0lw71/image/upload/v1723750822/top-mri-79_egciwf.jpg'
];
