import React, { useEffect, useState } from 'react';
import Section from '../../organisms/Section';
import { useData } from '../../../contexts/DataContext';
import { getTechList } from '../../../utils/getTechList';
import WordCloud from '../../scene/elemets/WordCloud/WordCloud';
import styled from 'styled-components';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import { useGuide } from '../../../contexts/GuideContext';
import { useOptimizer } from '../../../contexts/OptimizerContext';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';

export const FullPageWidthWrapper = styled.div`
  width: 100vw !important;
  height: 100vh !important;
  padding: 0 !important;
`;

const HalfScreenScrollTransparentDiv = styled.div`
  position: absolute;
  height: 100vh;
  width: 30vw;
  margin-left: 70vw;
  background-color: transparent;
`;

const HalfScreenPreventScrollForMobile = styled.div`
  position: absolute;
  height: 100vh;
  width: 30vw;
  margin-left: 70vw;
  background-color: transparent;
  overflow: hidden;
`;

const TechStack: React.FC = () => {
  const { data, loading, error } = useData('techstack');
  const techList = getTechList(data);
  const { activeSection } = useActiveSection();
  const [isActive, setIsActive] = useState(false);
  const { setGuideMessage } = useGuide();

  useEffect(() => {
    if (activeSection === 'TECH_STACK') {
      !isMobile ? setGuideMessage('Use WASD↑↓ / click on the words / drag by mouse. ', 1000) : setGuideMessage('', 1000);
    } else {
      setGuideMessage('');
    }
  }, [activeSection, setGuideMessage]);

  useEffect(() => {
    setIsActive(activeSection === 'TECH_STACK');
  }, [activeSection]);

  if (loading) {
    return <LoadingPage isFullPage={false} isLoading={loading} />;
  }
  if (error) {
    return <ErrorPage isFullPage={false} sourceOfError="tech stack" />;
  }

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  return (
    <>
      {isMobile && <HalfScreenPreventScrollForMobile onTouchMove={(e) => e.preventDefault()} />}
      <HalfScreenScrollTransparentDiv />
    </>
  );
};

export default TechStack;
