import styled, { keyframes } from 'styled-components';
import Typography from '../atoms/Typography';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimatedTitle = styled(Typography)`
  width: 100%;
  animation: ${fadeIn} 0.5s ease-out;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  height: 60%;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  display: flex;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CarouselItem = styled.div`
  flex: 0 0 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin: 20px;
  position: relative;
`;

export const TitleContainer = styled.div`
  padding: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  margin-bottom: -30px;
  z-index: 10;
  position: relative;
  height: 40px;
  margin-right: 12px;
  margin-left: 12px;

  @media (min-width: 768px) {
    width: 50vw;
  }
`;

export const StyledArrowButton = styled.button`
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
  z-index: 300;
`;

export const CarouselDots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const CarouselDot = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? 'white' : 'rgba(255, 255, 255, 0.5)')};
  margin: 0 5px;
`;
