// Docs.tsx

import React, { useState, useMemo, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import gsap from 'gsap';
import { TransitionGroup, Transition } from 'react-transition-group';
import Section from '../../organisms/Section';
import { useData } from '../../../contexts/DataContext';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import Typography from '../../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../../atoms/Typography.autogen';
import MarkdownBlock from '../../organisms/MarkdownBlock';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import {
  DocInfoWrapper,
  DocPreviewWrapper,
  DocsGalleryWrapper,
  ExpandableOnHover,
  MarkdownBlockWrapper,
  SectionWrapper,
  Spacer,
  StyledTileWrapper,
  TagWrapper,
} from './Docs.styles';
import NavArrow from '../../molecules/NavArrow';
import DocFilters from '../../molecules/DocFilters';
import { useOptimizer } from '../../../contexts/OptimizerContext';
import { useGuide } from '../../../contexts/GuideContext';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import { useLocation, useNavigate } from 'react-router-dom';

interface Doc {
  doc_name: string;
  doc_desc: string;
  doc_type: string;
  docs_md: string;
  importance_factor: number;
}

const Docs: React.FC = () => {
  const { data, loading, error } = useData('docs');
  const { activeSection, setActiveSection } = useActiveSection();
  const [docs, setDocs] = useState<Doc[]>([]);
  const [isGalleryExpanded, setIsGalleryExpanded] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [filteredDocs, setFilteredDocs] = useState<Doc[]>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>(['all']);
  const [selectedDoc, setSelectedDoc] = useState<Doc | null>(null);
  const { performanceLevel } = useOptimizer();
  const { setGuideMessage, fadeOutGuide } = useGuide();
  const location = useLocation();
  const navigate = useNavigate();

  const galleryRef = useRef<HTMLDivElement | null>(null);
  const previewRef = useRef<HTMLDivElement | null>(null);
  const markdownBlockRef = useRef<HTMLDivElement | null>(null);

  // Ensure docs are fetched and set
  useEffect(() => {
    if (data) {
      setDocs(data);
    }
  }, [data]);

  const sortedDocs = useMemo(() => {
    return [...docs].sort((a, b) => b.importance_factor - a.importance_factor);
  }, [docs]);

  // Generate the filter list with 'all' and ensure 'project' comes after 'all'
  const FilterList = useMemo(() => {
    const allTypes = Array.from(new Set(sortedDocs.map((doc) => doc.doc_type)));
    const projectIndex = allTypes.indexOf('project');
    if (projectIndex !== -1) {
      allTypes.splice(projectIndex, 1);
      return ['all', 'project', ...allTypes];
    }
    return ['all', ...allTypes];
  }, [sortedDocs]);

  // Update filteredDocs whenever activeFilters or docs change
  useEffect(() => {
    const newFilteredDocs = activeFilters.includes('all') ? sortedDocs : sortedDocs.filter((doc) => activeFilters.includes(doc.doc_type));
    setFilteredDocs(newFilteredDocs);
    setSelectedIndex(0);
    setSelectedDoc(newFilteredDocs[0] || null);
  }, [activeFilters, sortedDocs]);

  // New useEffect to keep URL in sync with selected document
  useEffect(() => {
    if (selectedDoc && activeSection === 'DOCS') {
      const docNameForUrl = selectedDoc.doc_name.replace(/ /g, '_');
      const newUrl = `/DOCS#${encodeURIComponent(docNameForUrl)}`;

      // Only update if the URL is different
      if (window.location.pathname + window.location.hash !== newUrl) {
        window.history.replaceState(null, '', newUrl);
      }
    }
  }, [selectedDoc, activeSection]);

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const handleInitialDocLoad = useCallback(() => {
    if (docs.length > 0 && activeSection === 'DOCS' && !initialLoadComplete) {
      const docNameFromHash = decodeURIComponent(location.hash.slice(1)).replace(/_/g, ' ');

      if (docNameFromHash) {
        const matchingDoc = docs.find((doc) => doc.doc_name.toLowerCase() === docNameFromHash.toLowerCase());
        if (matchingDoc) {
          const index = docs.indexOf(matchingDoc);
          setSelectedDoc(matchingDoc);
          setSelectedIndex(index);
        } else {
          setSelectedDoc(docs[0]);
          setSelectedIndex(0);
        }
      } else {
        setSelectedDoc(docs[0]);
        setSelectedIndex(0);
      }
      setInitialLoadComplete(true);
    }
  }, [docs, activeSection, location.hash, initialLoadComplete]);

  useEffect(() => {
    handleInitialDocLoad();
  }, [handleInitialDocLoad, activeSection]);

  // Update this useEffect to scroll to the correct document after filteredDocs are updated
  useEffect(() => {
    if (selectedDoc) {
      const index = filteredDocs.findIndex((doc) => doc.doc_name === selectedDoc.doc_name);
      if (index !== -1) {
        setSelectedIndex(index);
        scrollDocIntoView(index);
        if (index !== -1) {
          setTimeout(() => {
            scrollDocIntoView(index);
          }, 300); // Delay to ensure DOM is ready
        }
      }
    }
  }, [filteredDocs, selectedDoc]);

  const scrollDocIntoView = (index: number) => {
    if (galleryRef.current) {
      const docElements = galleryRef.current.querySelectorAll('.doc-info-wrapper');
      if (docElements[index]) {
        setTimeout(() => {
          const element = docElements[index] as HTMLElement;
          const gallery = galleryRef.current;
          if (gallery) {
            const elementRect = element.getBoundingClientRect();
            const galleryRect = gallery.getBoundingClientRect();

            const elementTop = elementRect.top - galleryRect.top + gallery.scrollTop;
            const elementBottom = elementTop + elementRect.height;

            const viewportHeight = galleryRect.height;
            const scrollTop = gallery.scrollTop;
            const scrollBottom = scrollTop + viewportHeight;

            if (elementTop < scrollTop || elementBottom > scrollBottom) {
              const targetScroll = elementTop - (2 * viewportHeight) / 4; // Position element 1/4 down from the top
              gallery.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
              });
            }
          }
        }, 100); // Small delay to ensure the DOM has updated
      }
    }
  };

  const handleSelectDoc = (doc: Doc, index: number) => {
    setSelectedDoc(doc);
    setSelectedIndex(index);
    scrollDocIntoView(index);
    setInitialLoadComplete(true); // Ensure initial load doesn't override user selection
  };

  // Entry animation for first load and resize animations for expanding/collapsing
  useLayoutEffect(() => {
    if (galleryRef.current && previewRef.current) {
      const galleryItems = galleryRef.current.querySelectorAll('.doc-info-wrapper');
      const navArrow = document.querySelector('.nav-arrow');

      if (activeSection === 'DOCS') {
        // Initially hide elements if not already hidden
        gsap.set([galleryItems, previewRef.current, navArrow], { autoAlpha: 0 });

        if (performanceLevel === 'low') {
          // For low performance, just set the elements to be visible without animations
          gsap.set(galleryItems, { autoAlpha: 1, scaleY: 1, height: 'auto', opacity: 1 });
          gsap.set(previewRef.current, { autoAlpha: 1, scale: 1, x: '0%', height: 'auto' });
          gsap.set(navArrow, { autoAlpha: 1, x: '0%' });
        } else {
          // Animate in the docs content
          gsap.to(previewRef.current, {
            duration: 1.8,
            height: 'auto',
            autoAlpha: 1,
            scale: 1,
            x: '0%',
            ease: 'power2.out',
          });
          gsap.to(galleryItems, {
            duration: 1.8,
            autoAlpha: 1,
            scaleY: 1,
            height: 'auto',
            y: '0%',
            stagger: 0.1,
            ease: 'power2.out',
            clearProps: 'height',
          });
          gsap.to(navArrow, {
            duration: 1.8,
            autoAlpha: 1,
            x: '0%',
            ease: 'power2.out',
          });
        }
      } else {
        // Hide elements immediately if not already hidden
        gsap.set([galleryItems, previewRef.current, navArrow], { autoAlpha: 0 });
      }
    }
  }, [activeSection, performanceLevel]);

  useLayoutEffect(() => {
    if (galleryRef.current && previewRef.current && activeSection === 'DOCS') {
      if (isGalleryExpanded) {
        // Expand gallery and shift preview back to its original position
        gsap.to(previewRef.current, {
          width: 'calc(100%)',
          marginLeft: '0px',
          duration: 0.5,
          ease: 'power2.out',
        });
        gsap.to(galleryRef.current, {
          x: '0%',
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
          delay: 0.2,
        });
      } else {
        gsap.to(previewRef.current, {
          width: '140%',
          marginLeft: '-150px',
          duration: 0.5,
          ease: 'power2.out',
          delay: 0.2,
        });
        // Collapse gallery and center the preview
        gsap.to(galleryRef.current, {
          x: 1,
          width: '0%',
          opacity: 0,
          duration: 0.5,
          ease: 'power2.out',
        });
      }
    }
  }, [isGalleryExpanded, activeSection]);

  const handleHighlight = (index: number) => {
    if (selectedIndex === index) {
      return HighlightLevel.active;
    }
  };

  const handleFilter = (filter: string) => {
    setActiveFilters((prevFilters) => {
      if (filter === 'all') {
        return ['all'];
      } else {
        const isFilterActive = prevFilters.includes(filter);
        let newFilters = isFilterActive ? prevFilters.filter((f) => f !== filter) : [...prevFilters.filter((f) => f !== 'all'), filter];

        if (newFilters.length === 0) {
          newFilters = ['all'];
        }

        return newFilters;
      }
    });
  };

  const handleNavArrowHover = () => {
    setGuideMessage('Enter zen mode to focus on the document');
    setTimeout(() => {
      fadeOutGuide();
    }, 4500);
  };

  if (loading) {
    return <LoadingPage isFullPage={false} isLoading={loading} />;
  }

  if (error) {
    return <ErrorPage isFullPage={false} sourceOfError="docs" />;
  }

  const DocItem = ({ doc }: { doc: Doc }) => {
    const itemRef = useRef<HTMLDivElement>(null);
    const index = filteredDocs.findIndex((d) => d.doc_name === doc.doc_name);

    const isSelected = selectedDoc ? selectedDoc.doc_name === doc.doc_name : false;
    const highlightLevel = isSelected ? HighlightLevel.active : undefined;

    return (
      <DocInfoWrapper ref={itemRef} onClick={() => handleSelectDoc(doc, index)} className="doc-info-wrapper hoverable">
        <StyledTileWrapper is_row_section_active={isSelected}>
          <Typography variant={TypographyVariant.sectionHeader} highlight_level={highlightLevel}>
            {doc.doc_name}
          </Typography>
          <TagWrapper isActive={false} ishovered={false}>
            <Typography variant={TypographyVariant.sectionDescription} highlight_level={highlightLevel}>
              {doc.doc_type}
            </Typography>
          </TagWrapper>
          <ExpandableOnHover>
            <Typography variant={TypographyVariant.sectionDescription} highlight_level={highlightLevel}>
              {doc.doc_desc}
            </Typography>
          </ExpandableOnHover>
        </StyledTileWrapper>
      </DocInfoWrapper>
    );
  };

  return (
    <Section title="Docs">
      <SectionWrapper style={{ display: activeSection === 'DOCS' ? 'flex' : 'none' }}>
        <div className="nav-arrow">
          <NavArrow
            mounted={galleryRef.current !== null ? true : false}
            isFlipped={isGalleryExpanded ? true : false}
            onClick={() => setIsGalleryExpanded(!isGalleryExpanded)}
            onMouseEnter={handleNavArrowHover}
          />
        </div>
        <DocsGalleryWrapper ref={galleryRef}>
          <Spacer />
          <TransitionGroup component={null}>
            {filteredDocs.map((doc, index) => (
              <Transition
                key={doc.doc_name}
                timeout={500}
                mountOnEnter
                unmountOnExit
                onEnter={(node: gsap.TweenTarget) => {
                  gsap.fromTo(
                    node,
                    { height: 0, opacity: 0 },
                    {
                      height: 'auto',
                      opacity: 1,
                      duration: 0.5,
                      ease: 'power2.out',
                      clearProps: 'height',
                      onComplete: () => {
                        if (index === selectedIndex) {
                          scrollDocIntoView(index);
                        }
                      },
                    },
                  );
                }}
                onExit={(node) => {
                  gsap.to(node, {
                    height: 0,
                    opacity: 0,
                    duration: 0.5,
                    ease: 'power2.in',
                  });
                }}
              >
                <DocItem doc={doc} />
              </Transition>
            ))}
          </TransitionGroup>
          <Spacer />
        </DocsGalleryWrapper>

        {/* Document Preview Section */}
        <DocPreviewWrapper ref={previewRef}>
          <DocFilters FilterList={FilterList} activeFilters={activeFilters} handleFilter={handleFilter} hideFilters={!isGalleryExpanded} />
          <MarkdownBlockWrapper ref={markdownBlockRef}>{selectedDoc && <MarkdownBlock extendedpadding markDownContent={selectedDoc.docs_md} />}</MarkdownBlockWrapper>
        </DocPreviewWrapper>
      </SectionWrapper>
    </Section>
  );
};

export default Docs;
