import styled from 'styled-components';

export const ShortFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    gap: 0px;
  }
`;

export const SingleFIeldWrapper = styled.div`
  width: 100%;
  min-width: 200px;
`;

export const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 900px) {
    form {
      max-height: 20vh !important;
    }
  }
`;

export const ButtonAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  gap: 44px;

  @media (max-width: 768px) {
  margin-top: -12px;
  margin-left: auto;
  }

@media (max-height: 710px) {
  // margin-top: -70px;
}

`;
