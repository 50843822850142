export interface TileData {
  tile_name: string;
  middle_priority: number;
  tile_content: string;
  tile_image?: {
    data: Array<{
      attributes: {
        url: string;
      };
    }>;
  };
}
// **Updated configuration constants for yaw and pitch**
export const PITCH_OFFSET = -4;
export const YAW_OFFSET = 0;
export const HEIGHT_OFFSET = 24;
export const CAMERA_OFFSET_X = 0;

export const MIN_YAW = -14;
export const MAX_YAW = 14;
export const MIN_PITCH = -30;
export const MAX_PITCH = 30;
export const CAMERA_LERP_FACTOR = 0.2;

export const YAW_STRENGTH = 3;
export const PITCH_STRENGTH = 1.6;

export const zDepthOffset = 10;
export const scrollMultiplierConstant = 2.8;

