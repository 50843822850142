import styled from 'styled-components';

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px;
  height: 100%;
`;

export const Line = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: height 0.5s ease;
`;

export const ArrowHead = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  margin-bottom: -12px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  &::before {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    border-left: none;
    border-bottom: none;
  }

  &::after {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    width: 8px;
    height: 8px;
    border-left: none;
    border-top: none;
  }
`;
