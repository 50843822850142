// Contact.tsx

import React, { useEffect, useRef, useState, useCallback } from 'react';
import Section from '../../organisms/Section';
import LinkBlockCircles from '../../molecules/LinkBlockCircles';
import ContactForm from '../../organisms/ContactForm';
import Typography from '../../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../../atoms/Typography.autogen';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useActiveSection } from '../../../contexts/ActiveSectionContext';
import { FormWrapper, SectionWrapper } from './Contact.styles';
import { ContextInfoSet, useOffsetMessages, useContextInfo, useMessageChange } from './Contact.utils';
import LinkCircles from '../../molecules/LinkCircles';
import { useData } from '../../../contexts/DataContext';

const PlaceholderWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

const Contact: React.FC = () => {
  const { activeSection } = useActiveSection();
  const { error } = useData('personal');
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isNonOffsetHovered, setIsNonOffsetHovered] = useState(false);
  const [hasProvidedName, setHasProvidedName] = useState(false);
  const [formFieldActive, setFormFieldActive] = useState(false);
  const [isGibberish, setIsGibberish] = useState(false);

  const updateOffsetMessage = useOffsetMessages();
  const { contextInfo, key, updateContextInfo } = useContextInfo();
  const { startInitialTimeout, startHoverTimeout, changeTimeoutRef, initialLoadTimeoutRef, hoverTimeoutRef } = useMessageChange(
    updateOffsetMessage,
    updateContextInfo,
    isNonOffsetHovered,
    hasProvidedName,
    formFieldActive,
  );

  const handleWritingStateChange = useCallback(
    (writing: boolean) => {
      if (writing !== formFieldActive) {
        setFormFieldActive(writing);

        if (writing && !hasProvidedName && !isGibberish) {
          updateContextInfo("Great! I'm excited to hear what you have to say. Your message is important to me!");
          if (changeTimeoutRef.current) {
            clearTimeout(changeTimeoutRef.current);
          }
        }
      }
    },
    [formFieldActive, hasProvidedName, updateContextInfo, changeTimeoutRef, isGibberish],
  );

  const handleNameChange = useCallback(
    (name: string) => {
      if (name && !hasProvidedName && !isGibberish) {
        setHasProvidedName(true);
        updateContextInfo(`Hello ${name}, I'm really looking forward to your thoughts. Whatever you've got to share.`);
        if (changeTimeoutRef.current) {
          clearTimeout(changeTimeoutRef.current);
        }
      }
    },
    [hasProvidedName, updateContextInfo, changeTimeoutRef, isGibberish],
  );

  const handleGibberish = useCallback(() => {
    // updateContextInfo(ContextInfoSet.find((context) => context.item === 'gibberish')?.info || 'Oops, that seems like gibberish! Please try again with real words. 😅');
  }, [updateContextInfo]);

  useEffect(() => {
    if (isGibberish) {
      handleGibberish();
    } else if (!formFieldActive && !hasProvidedName && !isNonOffsetHovered) {
      // Optionally reset context info when not gibberish and not writing
      startHoverTimeout();
    }
  }, [isGibberish, handleGibberish, formFieldActive, hasProvidedName, isNonOffsetHovered, startHoverTimeout]);

  const handleHover = useCallback(
    (item: string | null) => {
      if (formFieldActive || hasProvidedName || isGibberish) return;

      if (item === 'offset' || item === null) {
        setIsNonOffsetHovered(false);
        startHoverTimeout();
      } else {
        if (changeTimeoutRef.current) {
          clearTimeout(changeTimeoutRef.current);
        }
        const newInfo = ContextInfoSet.find((context) => context.item === item)?.info || '';
        updateContextInfo(newInfo);
        setIsNonOffsetHovered(true);
      }
    },
    [formFieldActive, hasProvidedName, updateContextInfo, startHoverTimeout, changeTimeoutRef, isGibberish],
  );

  useEffect(() => {
    if (activeSection === 'CONTACT') {
      startInitialTimeout();
    } else {
      if (initialLoadTimeoutRef.current) {
        clearTimeout(initialLoadTimeoutRef.current);
      }
      if (changeTimeoutRef.current) {
        clearTimeout(changeTimeoutRef.current);
      }
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    }
  }, [activeSection, startInitialTimeout, initialLoadTimeoutRef, changeTimeoutRef, hoverTimeoutRef]);

  useEffect(() => {
    const elements = sectionRef.current?.querySelectorAll('.animate-typography');

    if (!elements) return;

    if (activeSection === 'CONTACT') {
      gsap.to(sectionRef.current, {
        autoAlpha: 1,
        duration: 0.5,
        ease: 'power3.out',
        onComplete: () => {
          gsap.fromTo(
            elements,
            { autoAlpha: 0, y: 50 },
            {
              autoAlpha: 1,
              y: 0,
              duration: 0.6,
              stagger: 0.2,
              ease: 'power3.out',
            },
          );
        },
      });
    } else {
      gsap.to(elements, {
        autoAlpha: 0,
        y: 50,
        duration: 0.6,
        stagger: 0.2,
        ease: 'power3.in',
      });

      gsap.to(sectionRef.current, {
        autoAlpha: 0,
        delay: 0.6,
        duration: 0.5,
        ease: 'power3.in',
      });
    }
  }, [activeSection]);

  const isMobile = window.innerWidth <= 900;

  const handleMessageSent = useCallback(() => {
    updateContextInfo('Great job! You will receive a response soon.');
  }, [updateContextInfo]);

  return (
    <Section title="Contact">
      <SectionWrapper ref={sectionRef}>
        <div className="animate-typography">
          <Typography animated animationType="write" highlight_level={HighlightLevel.neutral} variant={isMobile ? TypographyVariant.bigHeader : TypographyVariant.megaSubHeader}>
            Contact Me
          </Typography>
        </div>
        {!error && <LinkBlockCircles onHover={handleHover} />}
        {!isMobile && (
          <div className="animate-typography">
            <PlaceholderWrapper>
              <Typography key={key} animated animationType="write" highlight_level={HighlightLevel.neutral} variant={TypographyVariant.sectionHeader}>
                {contextInfo}
              </Typography>
            </PlaceholderWrapper>
          </div>
        )}
        <FormWrapper onMouseEnter={() => handleHover('form')} onMouseLeave={() => handleHover('offset')}>
          <ContactForm onWritingStateChange={handleWritingStateChange} onNameChange={handleNameChange} onMessageSent={handleMessageSent} setIsGibberish={setIsGibberish} />
        </FormWrapper>
        {activeSection === 'CONTACT' && <LinkCircles />}
      </SectionWrapper>
    </Section>
  );
};

export default Contact;
