import styled from 'styled-components';

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // padding-top: 9px;
  padding-left: 4px;
  // padding-bottom: 9px;

  @media (max-width: 768px) {
    padding-left: 2px;
    margin-right: -6px;
  }
`;

export const TechLogoImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    border-radius: 10px;
  }
`;

export const TechDescBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    max-width: 150px;
  }
`;

export const TechContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  opacity: 0; /* Start with opacity 0 for animation */
`;

export const EntityWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
`;

export const DescTechWrapper = styled.div<{ condensed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: ${({ condensed }) => (condensed ? '36px' : '8px')};
  padding-bottom: ${({ condensed }) => (condensed ? '36px' : '8px')};
  width: 100%;

  @media (max-width: 768px) {
    padding-bottom: 6px;
  }

`;

export const TechWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const LineConnector = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: white;
  width: 0%; /* Start with width 0 for animation */
`;
