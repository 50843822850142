import { useState } from 'react';
import { gsap } from 'gsap';
import { useHoverBlock } from '../contexts/HoverBlockContext';

interface MagnifyingEffectProps {
  numSections: number; // Add the numSections prop to the interface
}

const useMagnifyingEffect = (props: MagnifyingEffectProps) => {
  const { numSections } = props; // Destructure numSections from the props
  const [hoveredSection, setHoveredSection] = useState<number | null>(null);
  const { setIsHovered } = useHoverBlock();

  const handleMouseEnter = (index: number) => {
    index == 0 && setIsHovered(true);
    setHoveredSection(index);

    // Zoom in the hovered section's image wrapper
    gsap.to(`.entity-row-${index} .image-wrapper`, {
      scale: 1.5,
      x: 100,
      duration: 0.3,
      filter: 'grayscale(0%)',
      opacity: 1,
    });

    // Apply grayscale and opacity to other sections
    for (let i = 0; i < numSections; i++) {
      // Use numSections here
      if (i !== index) {
        gsap.to(`.entity-row-${i} .image-wrapper`, {
          filter: 'grayscale(100%)',
          opacity: 0.6,
          duration: 0.3,
        });
      }
    }
  };

  const handleMouseLeave = (index: number) => {
    setHoveredSection(null);
    index == 0 && setIsHovered(false);

    // Reset the hovered section's image wrapper
    gsap.to(`.entity-row-${index} .image-wrapper`, {
      scale: 1,
      x: 0,
      duration: 0.3,
    });

    // Reset other sections
    for (let i = 0; i < numSections; i++) {
      // Use numSections here
      if (i !== index) {
        gsap.to(`.entity-row-${i} .image-wrapper`, {
          filter: 'grayscale(0%)',
          opacity: 1,
          duration: 0.3,
        });
      }
    }
  };

  return { hoveredSection, handleMouseEnter, handleMouseLeave };
};

export default useMagnifyingEffect;
