import React, { useState, useRef, useEffect } from 'react';
import { TbRocket, TbRocketOff } from 'react-icons/tb';
import styled from 'styled-components';
import { gsap } from 'gsap';
import ToolTip from './ToolTip';
import { useOptimizer } from '../../contexts/OptimizerContext';
import { useGuide } from '../../contexts/GuideContext';

const DotContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 9999;
  opacity: 0.1;

  &:hover {
    opacity: 0.6;
  }
`;

interface CircleButtonProps {
  isFullMotion: boolean;
}

const CircleButton = styled.div<CircleButtonProps>`
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  opacity: ${({ isFullMotion }) => (isFullMotion ? 1 : 0.8)};
`;

export default function MotionDot() {
  const { performanceLevel, togglePerformance, originalPerformanceLevel } = useOptimizer();
  const { fadeOutGuide, setGuideMessage } = useGuide();
  const isPerformanceHigh = performanceLevel === 'high';
  const [isFullMotion, setIsFullMotion] = useState(isPerformanceHigh);
  const iconRef = useRef<HTMLDivElement>(null);

  const showInfo = () => {
    fadeOutGuide();
    setGuideMessage(
      `Your computer scored ${originalPerformanceLevel} on the performance test. So I set the motion settings to ${
        originalPerformanceLevel ? 'Full' : 'Reduced'
      } by default. You can change it manually, just click the rocket.`,
    );
  };

  const toggleMotion = () => {
    const newMotionState = !isFullMotion;
    setIsFullMotion(newMotionState);
    togglePerformance();

    fadeOutGuide();
    setGuideMessage(
      `Now motion is set to ${newMotionState ? 'Full' : 'Reduced'}. Some of UI elements motion and textures quality have changed, and this should impact the performance.`,
    );

    if (iconRef.current) {
      if (newMotionState) {
        // Animation for switching to full motion
        gsap.fromTo(
          iconRef.current,
          { x: 0, y: 0, rotation: 10 },
          {
            x: 100,
            y: -100,
            rotation: -10,
            duration: 0.5,
            onComplete: () => {
              gsap.fromTo(iconRef.current, { x: -100, y: 100 }, { x: 0, y: 0, duration: 0.5 });
            },
          },
        );
      } else {
        // Animation for switching to reduced motion
        gsap.to(iconRef.current, { scale: 0.8, opacity: 0.4, duration: 0.5 });
      }
    }

    console.log('Motion toggled:', newMotionState ? 'Full Motion' : 'Reduced Motion');
  };


  const isMobile = window.innerWidth <= 768;

  if (isMobile) {
    return null;
  }

  return (
    <DotContainer onMouseEnter={showInfo} onMouseLeave={fadeOutGuide}>
      <ToolTip info={isFullMotion ? 'Full Motion' : 'Reduced Motion'} maxWidth="100px" position="right">
        <CircleButton
          key={isFullMotion ? 'full' : 'reduced'} // Key forces re-render to ensure only one icon is mounted
          className="circle-button"
          onClick={toggleMotion}
          isFullMotion={isFullMotion}
          ref={iconRef}
        >
          {isFullMotion ? <TbRocket color="white" size={44} /> : <TbRocketOff color="white" size={44} />}
        </CircleButton>
      </ToolTip>
    </DotContainer>
  );
}
