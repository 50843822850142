import React, { useRef, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { GRID_DIVISIONS, GRID_SIZE } from './ArenaMovingGrid.config';
import { useOptimizer } from '../../../../contexts/OptimizerContext';

export const ArenaMovingGrid: React.FC = () => {
  const gridRef = useRef<THREE.GridHelper>(null);
  const { scene } = useThree();

  useEffect(() => {
    if (gridRef.current) {
      scene.add(gridRef.current);
    }
    return () => {
      if (gridRef.current) {
        scene.remove(gridRef.current);
      }
    };
  }, [scene]);

  return <gridHelper ref={gridRef} args={[GRID_SIZE, GRID_DIVISIONS]} />;
};
