import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import { gsap } from 'gsap';
import { mriScansArrayOfImages } from '../../assets/filenames';
import { useOptimizer } from '../../contexts/OptimizerContext';

const ImageContainer = styled.div`
  position: fixed;
  height: 100vh !important;
  margin-left: -63vh;
  z-index: 1;
  box-shadow: 0px 0px 187px 140px rgba(1, 1, 1, 1);
`;

const Image = styled.img`
  height: 100%;
  object-fit: contain;
  position: relative;
  left: 0;
  opacity: 0.7;
`;

interface MRIImageDisplayProps {
  imagesSet: string;
}

function MRIImageDisplay({ imagesSet }: MRIImageDisplayProps) {
  const [imagesCount] = useState(79); // Fixed count of images
  const [currentIndex, setCurrentIndex] = useState(1);
  const { activeSection } = useActiveSection();
  const mriContainerRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false); // Control component visibility
  const { performanceLevel } = useOptimizer();

  const isSmallWidth = window.innerWidth < 1300;
  const isSmallHeight = window.innerHeight < 800;
  const circuitSizer = window.innerWidth + window.innerHeight;
  const screenProportion = window.innerWidth / window.innerHeight;
  const isProportionInvalid = screenProportion < 1.5;
  
  const isHidden = isSmallWidth || isSmallHeight || isProportionInvalid;
  
  const handleMouseMove = (event: MouseEvent) => {
    const mouseY = event.clientY / window.innerHeight;
    const newIndex = Math.floor(mouseY * (imagesCount - 1));
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    if (activeSection === 'EDUCATION' && !isMounted) {
      setIsMounted(true); // Mount the component when entering 'EDUCATION'
    }
  }, [activeSection, isMounted]);

  useEffect(() => {
    if (isMounted && activeSection === 'EDUCATION') {
      // Add mouse event listener when in EDUCATION
      window.addEventListener('mousemove', handleMouseMove);

      // Animate in when entering EDUCATION
      gsap.fromTo(mriContainerRef.current, { opacity: 0 }, { opacity: 1, duration: 1.5, ease: 'power2.out' });

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    } else if (isMounted && activeSection !== 'EDUCATION') {
      // Animate out when leaving EDUCATION
      gsap.to(mriContainerRef.current, {
        opacity: 0,
        duration: 1,
        ease: 'power2.in',
        onComplete: () => setIsMounted(false), // Unmount after the animation
      });
    }
  }, [activeSection, isMounted]);

  // Do not render anything until the component is mounted
  if (!isMounted || performanceLevel === 'low') {
    return null;
  }

  return (
    <>
      { !isHidden && (
        <ImageContainer ref={mriContainerRef}>
          <Image src={`${mriScansArrayOfImages[currentIndex]}`} alt="MRI Scan" />
        </ImageContainer>
      )}
    </>
  );
}

export default MRIImageDisplay;
