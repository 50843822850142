import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import { gsap } from 'gsap';
import { useActiveSection } from '../../../../contexts/ActiveSectionContext';
import { useOptimizer } from '../../../../contexts/OptimizerContext';
import UsePersonalData from '../../../../hooks/usePersonalData';

// Customizable variables
const baseNumRings = 70;
const ringRadius = 4;
const ringWidth = 0.02;
const wordDistance = 6;
const initialCentralizationFactor = 1.8;
const initialMaxOpacity = 0;
const minOpacity = 0.01;
const transparencyThreshold = 1;

// Function to animate variables
const animateVariables = (setCentralizationFactor: (value: number) => void, setMaxOpacity: (value: number) => void, direction: 'to' | 'from') => {
  const context = {
    centralizationFactor: direction === 'to' ? initialCentralizationFactor : 0.8,
    maxOpacity: direction === 'to' ? initialMaxOpacity : 1,
  };

  gsap.to(context, {
    duration: 1.5,
    centralizationFactor: direction === 'to' ? 0.8 : initialCentralizationFactor,
    maxOpacity: direction === 'to' ? 1 : initialMaxOpacity,
    onUpdate: () => {
      setCentralizationFactor(context.centralizationFactor);
      setMaxOpacity(context.maxOpacity);
    },
  });
};

type TunnelKey = string | number | symbol;

const calculateCyclicDistribution = (numRings: number, tunnel: TunnelKey, wordDistance: number): { word: string; zPosition: number }[] => {
  const positions = [];

  const { wordTunnels } = UsePersonalData();
  const words = wordTunnels[tunnel as keyof typeof wordTunnels];
  const numWords = words.length;

  for (let i = 0; i < numRings; i++) {
    const wordIndex = i % numWords;
    const zPosition = -i * wordDistance;
    positions.push({ word: words[wordIndex], zPosition });
  }

  return positions;
};

const getOptimizedValues = (performanceLevel: 'low' | 'mid' | 'high') => {
  switch (performanceLevel) {
    case 'low':
      return {
        numRings: Math.floor(baseNumRings * 0.5),
        wordSpeed: 0.01,
        opacityDecreaseFactor: 4,
        ringSegments: 32,
      };
    case 'mid':
      return {
        numRings: baseNumRings,
        wordSpeed: 0.01,
        opacityDecreaseFactor: 3.3,
        ringSegments: 64,
      };
    case 'high':
      return {
        numRings: Math.floor(baseNumRings * 0.8),
        wordSpeed: 0.01,
        opacityDecreaseFactor: 4,
        ringSegments: 128,
      };
  }
};

const AnimatedWord = ({
  word,
  tunnelPosition,
  zPosition,
  xOffset,
  yOffset,
  cycleLength,
  opacityDecreaseFactor,
  maxOpacity,
  performanceLevel,
}: {
  word: string;
  tunnelPosition: [number, number, number];
  zPosition: number;
  xOffset: number;
  yOffset: number;
  cycleLength: number;
  opacityDecreaseFactor: number;
  maxOpacity: number;
  performanceLevel: 'low' | 'mid' | 'high';
}) => {
  const ref = useRef<THREE.Mesh>(null);
  const materialRef = useRef<THREE.MeshBasicMaterial | null>(null);
  const { wordSpeed } = getOptimizedValues(performanceLevel);

  useFrame(() => {
    if (ref.current) {
      ref.current.position.z += wordSpeed;

      if (ref.current.position.z > cycleLength / 2) {
        ref.current.position.z = -cycleLength / 2;
      }

      const relativePosition = Math.abs(ref.current.position.z / (cycleLength / 2));

      if (!materialRef.current) {
        materialRef.current = ref.current.material as THREE.MeshBasicMaterial;
      }

      const baseOpacity = Math.max(minOpacity, maxOpacity - relativePosition * opacityDecreaseFactor);

      const fadeDistance = 2;
      const fadeStart = transparencyThreshold;
      const fadeEnd = fadeStart + fadeDistance;
      let fadeOpacity = maxOpacity;

      if (ref.current.position.z >= fadeEnd) {
        fadeOpacity = 0;
      } else if (ref.current.position.z >= fadeStart) {
        const fadeFactor = (fadeEnd - ref.current.position.z) / fadeDistance;
        fadeOpacity = maxOpacity * fadeFactor;
      }

      materialRef.current.opacity = Math.max(minOpacity, Math.min(baseOpacity, fadeOpacity));
    }
  });

  useEffect(() => {
    return () => {
      if (materialRef.current) {
        materialRef.current.dispose();
      }
    };
  }, []);

  return (
    <Text
      ref={ref}
      position={[tunnelPosition[0] + xOffset, tunnelPosition[1] + yOffset, zPosition]}
      fontSize={0.75}
      maxWidth={ringRadius * 2 - ringWidth * 2}
      color="#FFFFFF"
      anchorX="center"
      anchorY="middle"
    >
      {word}
    </Text>
  );
};

const StackedRings = ({
  position,
  tunnel,
  numRings,
  xOffset,
  yOffset,
  ringDistance,
  centralizationFactor,
  maxOpacity,
  performanceLevel,
}: {
  position: [number, number, number];
  tunnel: TunnelKey;
  numRings: number;
  xOffset: number;
  yOffset: number;
  ringDistance: number;
  mousePosition: { x: number; y: number };
  centralizationFactor: number;
  maxOpacity: number;
  performanceLevel: 'low' | 'mid' | 'high';
}) => {
  const { opacityDecreaseFactor, ringSegments } = getOptimizedValues(performanceLevel);
  const cycleLength = numRings * wordDistance;

  const ringGeometryRef = useRef<THREE.RingGeometry | null>(null);
  const ringMaterialRef = useRef<THREE.MeshStandardMaterial | null>(null);

  useEffect(() => {
    ringGeometryRef.current = new THREE.RingGeometry(ringRadius - ringWidth, ringRadius, ringSegments);
    ringMaterialRef.current = new THREE.MeshStandardMaterial({
      color: '#FFFFFF',
      transparent: true,
      side: THREE.DoubleSide,
    });

    return () => {
      if (ringGeometryRef.current) {
        ringGeometryRef.current.dispose();
      }
      if (ringMaterialRef.current) {
        ringMaterialRef.current.dispose();
      }
    };
  }, [ringSegments]);

  const rings = useMemo(() => {
    if (!ringGeometryRef.current || !ringMaterialRef.current) {
      return []; // Return empty array if geometry or material is not ready
    }

    const newRings = [];
    const wordDistribution = calculateCyclicDistribution(numRings, tunnel, wordDistance);

    for (let i = 0; i < numRings; i++) {
      const { word, zPosition } = wordDistribution[i];

      const ringZPosition = -i * ringDistance;
      const scale = 1 - Math.abs(ringZPosition / (numRings * ringDistance * 2)) * centralizationFactor;

      let opacity = maxOpacity - (i / numRings) * opacityDecreaseFactor;
      opacity = Math.max(minOpacity, Math.min(maxOpacity, opacity));

      newRings.push(
        <mesh key={i} position={[0, 0, ringZPosition]} scale={[scale, scale, 1]} castShadow receiveShadow>
          <ringGeometry args={[ringRadius - ringWidth, ringRadius, ringSegments]} />
          <meshStandardMaterial color="#FFFFFF" transparent opacity={opacity} side={THREE.DoubleSide} />
        </mesh>,
      );

      if (word) {
        newRings.push(
          <AnimatedWord
            key={`${String(tunnel)}-${word}-${i}`}
            word={word}
            tunnelPosition={position}
            zPosition={zPosition}
            xOffset={xOffset}
            yOffset={yOffset}
            cycleLength={cycleLength}
            opacityDecreaseFactor={opacityDecreaseFactor * 3.5}
            maxOpacity={maxOpacity}
            performanceLevel={performanceLevel}
          />,
        );
      }
    }
    return newRings;
  }, [numRings, tunnel, position, xOffset, yOffset, ringDistance, centralizationFactor, maxOpacity, performanceLevel, opacityDecreaseFactor, ringSegments]);

  return <group position={position}>{rings}</group>;
};

const Scene = ({ mousePosition, scrollProportionsForRings }: { onMaxScrollReached: () => void; mousePosition: { x: number; y: number }; scrollProportionsForRings: number }) => {
  const groupRef = useRef<THREE.Group>(null);
  const [centralizationFactor, setCentralizationFactor] = useState(initialCentralizationFactor);
  const [maxOpacity, setMaxOpacity] = useState(initialMaxOpacity);

  const { activeSection } = useActiveSection();
  const previousActiveSection = useRef<string | null>(null);

  const { performanceLevel } = useOptimizer();

  useEffect(() => {
    if (performanceLevel !== 'low') {
      if (activeSection === 'PERSONAL' && previousActiveSection.current !== 'PERSONAL') {
        animateVariables(setCentralizationFactor, setMaxOpacity, 'to');
      } else if (activeSection !== 'PERSONAL' && previousActiveSection.current === 'PERSONAL') {
        animateVariables(setCentralizationFactor, setMaxOpacity, 'from');
      }
    } else {
      // For low performance, set the values directly without animation
      setCentralizationFactor(activeSection === 'PERSONAL' ? 0.8 : initialCentralizationFactor);
      setMaxOpacity(activeSection === 'PERSONAL' ? 1 : initialMaxOpacity);
    }

    previousActiveSection.current = activeSection;
  }, [activeSection, performanceLevel]);

  const shift = 5 * centralizationFactor;
  const tunnels: TunnelKey[] = ['tunnel1', 'tunnel2', 'tunnel3', 'tunnel4', 'tunnel5'];

  const tunnelConfigs = [
    { xOffset: 0, yOffset: -3 },
    { xOffset: -3, yOffset: 0 },
    { xOffset: 3, yOffset: 0 },
    { xOffset: 0, yOffset: 3.5 },
    { xOffset: 0, yOffset: 0 },
  ];

  const positions: [number, number, number][] = [
    [0, shift, 0],
    [shift, 0, 0],
    [-shift, 0, 0],
    [0, -shift, 0],
    [0, 0, 0],
  ];

  return (
    <group ref={groupRef}>
      {positions.map((pos, index) => (
        <StackedRings
          key={index}
          position={pos}
          tunnel={tunnels[index]}
          numRings={getOptimizedValues(performanceLevel).numRings}
          xOffset={tunnelConfigs[index].xOffset}
          yOffset={tunnelConfigs[index].yOffset}
          ringDistance={scrollProportionsForRings}
          mousePosition={mousePosition}
          centralizationFactor={centralizationFactor}
          maxOpacity={maxOpacity}
          performanceLevel={performanceLevel}
        />
      ))}
    </group>
  );
};

export const SpatialRings: React.FC<{
  onMaxScrollReached: () => void;
  mousePosition: { x: number; y: number };
  scrollProportionsForRings: number;
  subPosition: [number, number, number];
}> = ({ onMaxScrollReached, mousePosition, scrollProportionsForRings, subPosition }) => {
  return (
    <group position={subPosition}>
      <ambientLight intensity={0.6} />
      <pointLight position={[10, 10, 10]} castShadow />
      <directionalLight position={[5, 5, 5]} intensity={1.0} />
      <Scene onMaxScrollReached={onMaxScrollReached} mousePosition={mousePosition} scrollProportionsForRings={scrollProportionsForRings} />
    </group>
    // </Canvas>
  );
};
