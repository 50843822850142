import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import ToolTip from '../atoms/ToolTip';

import OpenToWorkCircle from './OpenToWorkCircle';
import { ButtonContainer, CircleButton } from './LinkCircles.styles';
import { GetCircleLinksData } from '../sections/Contact/Contact.data';
import { useData } from '../../contexts/DataContext';

interface CircleButtonProps {
  forMobile?: boolean;
}

export default function LinkCircles({ forMobile }: CircleButtonProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLAnchorElement[]>([]);
  const openToWorkCircleRef = useRef<HTMLDivElement>(null);
  const { activeSection } = useActiveSection();
  const [ishovered, setishovered] = useState(false);
  const [wasContactActive, setWasContactActive] = useState(false);

  const CircleLinksData = GetCircleLinksData();
  const isSpinCircleHidden = ishovered && activeSection !== 'PERSONAL';

  const handleMouseEnter = (index: number) => {
    setishovered(true); // Set hover state to true

    // Animate the OpenToWorkCircle with scale down and fade out
    if (openToWorkCircleRef.current) {
      gsap.to(openToWorkCircleRef.current, {
        scale: 0.8,
        opacity: 0,
        duration: 0.4,
        ease: 'power2.out',
      });
    }

    gsap.to(buttonsRef.current[index], {
      scale: 1.2,
      ease: 'elastic.out(1, 0.4)',
      duration: 0.5,
      borderColor: '#D9D9D9',
      color: '#D9D9D9',
    });
  };

  const handleMouseLeave = (index: number) => {
    setishovered(false); // Set hover state to false

    gsap.to(buttonsRef.current[index], {
      scale: activeSection === 'PERSONAL' ? 1 : 0.85,
      ease: 'elastic.out(1, 0.4)',
      duration: 0.5,
      borderColor: '',
      color: '',
    });
  };

  useEffect(() => {
    if (containerRef.current) {
      gsap.fromTo(
        containerRef.current.children,
        {
          x: 100,
          opacity: 0,
          scale: 0.8,
        },
        {
          duration: 0.8,
          x: 0,
          opacity: 1,
          scale: 1,
          stagger: 0.2,
          ease: 'power1.out',
        },
      );
    }
  }, []);

  useEffect(() => {
    const buttons = buttonsRef.current;

    if (activeSection === 'PERSONAL') {
      gsap.to(buttons, {
        delay: 0.8,
        scale: 1,
        margin: 8,
        duration: 0.8,
        ease: 'power3.InOut',
        width: '60px',
        height: '60px',
        stagger: {
          each: 0.1,
          from: 'start',
        },
      });
    } else if (activeSection === 'CONTACT') {
      // Scale down and fade out with perspective
      gsap.to(buttons, {
        scale: 0.5,
        opacity: 0,
        y: 50, // Moves down as it fades out
        duration: 0.8,
        ease: 'power3.inOut',
        perspective: 400, // Perspective effect
        stagger: {
          each: 0.1,
          from: 'start',
        },
        onComplete: () => setWasContactActive(true), // Mark CONTACT section as active
      });
    } else if (wasContactActive) {
      // Reverse the animation when leaving the CONTACT section
      gsap.fromTo(
        buttons,
        {
          scale: 0.5,
          opacity: 0,
          // y: 50,
        },
        {
          scale: 0.85,
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: 'power3.inOut',
          perspective: 400, // Perspective effect
          stagger: {
            each: 0.1,
            from: 'start',
          },
          onComplete: () => setWasContactActive(false), // Reset flag after animation
        },
      );
    } else {
      gsap.to(buttons, {
        scale: 0.85,
        margin: 4,
        duration: 0.8,
        ease: 'elastic.out(1, 0.4)',
        width: '45px',
        height: '45px',
        stagger: {
          each: 0.1,
          from: 'start',
        },
      });
    }
  }, [activeSection, wasContactActive]);

  const { loading, error } = useData('personal');

  return (
    <div>
      {loading && error ? null : (
        <ButtonContainer locationTop={!forMobile} ref={containerRef} top={10} right={6}>
          {CircleLinksData.map((item, index) => (
            <ToolTip key={index} info={item.info} maxWidth="100px" alwaysVisible={forMobile}>
              <CircleButton
                key={index}
                href={item.href}
                target={index === 1 || index === 2 ? '_blank' : undefined}
                rel={index === 1 || index === 2 ? 'noopener noreferrer' : undefined}
                download={item.download} // This will now trigger a download with the specified filename
                ref={(el) => {
                  if (el) buttonsRef.current[index] = el;
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
                className="hoverable"
              >
                {item.icon}
              </CircleButton>
            </ToolTip>
          ))}
        </ButtonContainer>
      )}
      <OpenToWorkCircle hide={isSpinCircleHidden} />
    </div>
  );
}
