import React from 'react'
import { styled } from 'styled-components';

interface ButtonProps {
    children: React.ReactNode;
    mode: 'primary' | 'secondary' | 'tertiary';
    onClick: () => void;
}

const ButtonWrapper = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px 24px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.bgGreyDark};
  background-color: ${({ theme, mode }) => (mode === 'primary' ? theme.colors.whitePrePrimary : theme.colors.greyDarkMidOpacity)};
  color: ${({ theme, mode }) => (mode === 'primary' ? theme.colors.greyDark : theme.colors.whitePrePrimary)};
  flex-grow: ${({ mode }) => (mode === 'primary' ? '3' : '1')};
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-top: auto;
  height: fit-content;
  max-height: 46px;

  &:hover {
    background-color: ${({ theme, mode }) => (mode === 'primary' ? theme.colors.whiteMidPrimary : theme.colors.grey)};
    color: ${({ theme, mode }) => (mode === 'primary' ? theme.colors.greyDark : theme.colors.whiteMidPrimary)};
    // add aniamtion ease
    transition: background-color 0.3s ease, color 0.3s ease;
  }
`;

export default function CustomButton({ mode, onClick, children }: ButtonProps) {
  return (
    <ButtonWrapper className={"hoverable"} onClick={onClick} mode={mode}>{children}</ButtonWrapper>
  )
}
