export const OpenToWorkData = {
  translations: {
    English: 'Open to work',
    Spanish: 'Abierto a trabajar',
    French: 'Ouvert à travailler',
    German: 'Bereit zu arbeiten',
    Italian: 'Disponibile a lavorare',
    Russian: 'Открыт для работы',
    Polish: 'Gotowy do pracy',
    'Chinese (Simplified)': '愿意工作',
  },
};
