import React, { useState, useRef, useEffect } from 'react';
import Typography from '../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { debounce } from 'lodash';
import { gsap } from 'gsap';
import { StyledArrowButton, TitleContainer, AnimatedTitle, CarouselContainer, CarouselItem, CarouselDots, CarouselDot, ArrowContainer } from './MobileCarousel.styles';
import { TileData } from '../scene/MasterScene.config';
import { useAboutMeTiles } from '../../contexts/UseAboutTilesContext';

const MobileCarousel: React.FC = () => {
  const { data, selectedTileData, handleTileSelect } = useAboutMeTiles();
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isChanging, setIsChanging] = useState(false);
  const [titleKey, setTitleKey] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (carouselRef.current && !isChanging) {
      const scrollPosition = carouselRef.current.scrollLeft;
      const slideWidth = carouselRef.current.clientWidth;
      const newSlide = Math.round(scrollPosition / slideWidth);
      if (newSlide !== currentSlide) {
        changeSlide(newSlide, newSlide > currentSlide ? 'right' : 'left');
      }
    }
  };

  const changeSlide = (newSlide: number, direction: 'left' | 'right') => {
    setIsChanging(true);
    setCurrentSlide(newSlide);
    handleTileSelect(data[newSlide].tile_name);
    setTitleKey((prevKey) => prevKey + 1);

    const xOffset = direction === 'right' ? 100 : -100;

    // Animate out current content
    gsap.to(contentRef.current, {
      opacity: 0,
      x: -xOffset,
      duration: 0.3,
      onComplete: () => {
        // Update content
        gsap.set(contentRef.current, { x: xOffset });
        // Animate in new content
        gsap.to(contentRef.current, {
          opacity: 1,
          x: 0,
          duration: 0.3,
          onComplete: () => setIsChanging(false),
        });
      },
    });
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const debounceHandleScroll = debounce(handleScroll, 100);
      carousel.addEventListener('scroll', debounceHandleScroll);
      return () => carousel.removeEventListener('scroll', debounceHandleScroll);
    }
  }, [currentSlide, isChanging]);

  const scrollToSlide = (index: number) => {
    if (carouselRef.current && !isChanging) {
      const direction = index > currentSlide ? 'right' : 'left';
      carouselRef.current.scrollTo({
        left: index * carouselRef.current.clientWidth,
        behavior: 'smooth',
      });
      changeSlide(index, direction);
    }
  };

  const handlePrevSlide = () => {
    if (!isChanging) {
      const prevSlide = (currentSlide - 1 + data.length) % data.length;
      scrollToSlide(prevSlide);
    }
  };

  const handleNextSlide = () => {
    if (!isChanging) {
      const nextSlide = (currentSlide + 1) % data.length;
      scrollToSlide(nextSlide);
    }
  };

  return (
    <>
      <ArrowContainer>
        <StyledArrowButton onClick={handlePrevSlide}>
          <FaChevronLeft />
        </StyledArrowButton>
        <TitleContainer>
          <AnimatedTitle key={titleKey} variant={TypographyVariant.bigHeader} highlight_level={HighlightLevel.highlighted} align="center">
            {data[currentSlide].tile_name}
          </AnimatedTitle>
        </TitleContainer>
        <StyledArrowButton onClick={handleNextSlide}>
          <FaChevronRight />
        </StyledArrowButton>
      </ArrowContainer>
      <CarouselContainer ref={carouselRef}>
        <CarouselItem>
          <div ref={contentRef} style={{ margin: '20px' }}>
            <Typography variant={TypographyVariant.sectionDescription} highlight_level={HighlightLevel.highlighted}>
              {data[currentSlide].tile_content}
            </Typography>
          </div>
        </CarouselItem>
      </CarouselContainer>
      <CarouselDots>
        {data.map((_, index: number) => (
          <CarouselDot key={index} active={index === currentSlide} onClick={() => scrollToSlide(index)} />
        ))}
      </CarouselDots>
    </>
  );
};

export default MobileCarousel;
