import { motion } from "framer-motion";
import { styled } from "styled-components";

export const CircleWrapper = styled(motion.div)`
  position: fixed;
  top: 24px;
  right: 66px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 24px solid #6ae24555;
    z-index: 1;
  }
`;

export const CurvedText = styled(motion.svg)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`;

export const IconContainer = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.6);
  font-size: 40px;
  width: 40px;
  height: 40px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0; /* Initially hidden */
`;

export const FadeContainer = styled.div`
  position: relative;

  @media (max-height: 880px) {
    display: none;
  }
  @media (max-width: 999px) {
    display: none;
  }
`;
