import React, { createContext, useContext, ReactNode } from 'react';
import {
  useQueries,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { fetchData } from '../api/fetchers';
import { endpoints } from '../config/endpoints';
import { transformData } from '../utils/transformData';

interface DataContextType {
  [key: string]: {
    data: any;
    loading: boolean;
    error: any;
  };
}

const DataContext = createContext<DataContextType | undefined>(undefined);

const queryClient = new QueryClient();

export const MultiDataProvider = ({ children }: { children: ReactNode }) => {
  const queryOptions = Object.entries(endpoints).map(([key, endpoint]) => ({
    queryKey: ['data', key],
    queryFn: () => fetchData(endpoint),
  }));

  const queries = useQueries({ queries: queryOptions });

  const dataContextValue = Object.fromEntries(
    Object.keys(endpoints).map((key, index) => {
      let data = queries[index].data;
      data = transformData(key, data);
      return [
        key,
        {
          data,
          loading: queries[index].isLoading,
          error: queries[index].error,
        },
      ];
    }),
  );

  return (
    <DataContext.Provider value={dataContextValue}>
      {children}
    </DataContext.Provider>
  );
};
export const useData = (key: string) => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a MultiDataProvider');
  }
  return context[key];
};

export const AppProvider = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

// Export DataContext for use in other components
export { DataContext };

