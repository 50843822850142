import React, { createContext, useContext, useState } from 'react';
import HoverBlock from '../components/molecules/HoverBlock';

interface HoverBlockContextProps {
  showHoverBlock: (action: string) => void;
  hideHoverBlock: () => void;
  setIsHovered: (isHovered: boolean) => void;
  isHovered: boolean;
}

const HoverBlockContext = createContext<HoverBlockContextProps | undefined>(undefined);

export const HoverBlockProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [action, setAction] = useState('');

  const showHoverBlock = (action: string) => {
    setAction(action);
    setIsVisible(true);
  };

  const hideHoverBlock = () => {
    setIsVisible(false);
  };

  const [isHovered, setIsHovered] = useState(false);
  

  return (
    <HoverBlockContext.Provider value={{ showHoverBlock, hideHoverBlock, setIsHovered, isHovered }}>
      {children}
      <HoverBlock blockAction={action} isVisible={isVisible} />
    </HoverBlockContext.Provider>
  );
};

export const useHoverBlock = () => {
  const context = useContext(HoverBlockContext);
  if (!context) {
    throw new Error('useHoverBlock must be used within a HoverBlockProvider');
  }
  return context;
};