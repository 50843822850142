import { useEffect } from 'react';
import { gsap } from 'gsap';
import { useOptimizer } from '../contexts/OptimizerContext';

const useMagneticEffect = (ref: React.RefObject<HTMLElement>) => {
  const { performanceLevel } = useOptimizer();
  const isMobile = window.innerWidth <= 726;

  useEffect(() => {
    if (!ref.current || isMobile || performanceLevel === 'low') return;

    const handleMouseMoveMagnetic = (e: MouseEvent) => {
      if (!ref.current) return;
      const { clientX, clientY } = e;
      const rect = ref.current.getBoundingClientRect();
      const x = ((clientX - rect.left) / rect.width - 0.5) * 30;
      const y = ((clientY - rect.top) / rect.height - 0.5) * 30;

      gsap.to(ref.current, {
        x,
        y,
        ease: 'power3.out',
        duration: 0.3,
      });
    };

    const handleMouseLeaveMagnetic = () => {
      if (!ref.current) return;

      gsap.to(ref.current, { x: 0, y: 0, ease: 'power3.out', duration: 0.3 });
    };

    const currentElement = ref.current;

    currentElement.addEventListener('mousemove', handleMouseMoveMagnetic);
    currentElement.addEventListener('mouseleave', handleMouseLeaveMagnetic);

    return () => {
      currentElement.removeEventListener('mousemove', handleMouseMoveMagnetic);
      currentElement.removeEventListener('mouseleave', handleMouseLeaveMagnetic);
    };
  }, [ref, performanceLevel, isMobile]);
};

export default useMagneticEffect;
