import { ReactComponent as PersonalIcon } from "../assets/icons/personal_icon.svg";
import { ReactComponent as ChronologyIcon } from "../assets/icons/chronology_icon.svg";
import { ReactComponent as GalleryIcon } from "../assets/icons/gallery_icon.svg";
import { ReactComponent as EduIcon } from "../assets/icons/edu_icon.svg";
import { ReactComponent as ListIcon } from "../assets/icons/list_icon.svg";
import { ReactComponent as DocsIcon } from "../assets/icons/docs_icon.svg";
import { ReactComponent as Techstack } from "../assets/icons/techstack_icon.svg";
import { ReactComponent as TextIcon } from "../assets/icons/text_icon.svg";
import { ReactComponent as ContactIcon } from "../assets/icons/envelope_icon.svg";

// Define the enum for segment keys
export enum SegmentKey {
  PERSONAL = "PERSONAL",
  ABOUT_ME = "ABOUT_ME",
  EXPERIENCE = "EXPERIENCE",
  EDUCATION = "EDUCATION",
  // PROJECTS = "PROJECTS",
  DOCS = "DOCS",
  CERTIFICATIONS = "CERTIFICATIONS",
  TECH_STACK = "TECH_STACK",
  CONTACT = "CONTACT",
}

// Define the enum for segment types
export enum SegmentType {
  LIST = "LIST",
  TEXT = "TEXT",
  CHRONOLOGY = "CHRONOLOGY",
  STACK = "STACK",
}

// Define the mapping of segment keys to details including names, icons, and types
export const segmentDetails = {
  [SegmentKey.PERSONAL]: {
    name: 'Personal',
    icon: PersonalIcon,
    type: SegmentType.TEXT,
  },
  [SegmentKey.ABOUT_ME]: {
    name: 'About Me',
    icon: TextIcon,
    type: SegmentType.TEXT,
  },
  [SegmentKey.EXPERIENCE]: {
    name: 'Experience',
    icon: ChronologyIcon,
    type: SegmentType.CHRONOLOGY,
  },
  [SegmentKey.EDUCATION]: {
    name: 'Education',
    icon: EduIcon,
    type: SegmentType.CHRONOLOGY,
  },
  // [SegmentKey.PROJECTS]: {
  //   name: 'Projects',
  //   icon: GalleryIcon,
  //   type: SegmentType.STACK,
  // },
  [SegmentKey.DOCS]: {
    name: 'Docs',
    icon: DocsIcon,
    type: SegmentType.STACK,
  },
  [SegmentKey.CERTIFICATIONS]: {
    name: 'Certificates',
    icon: ListIcon,
    type: SegmentType.LIST,
  },
  [SegmentKey.TECH_STACK]: {
    name: 'Tech Stack',
    icon: Techstack,
    type: SegmentType.STACK,
  },
  [SegmentKey.CONTACT]: {
    name: 'Contact',
    icon: ContactIcon,
    type: SegmentType.TEXT,
  },
};
