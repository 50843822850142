import { motion } from 'framer-motion';
import styled from 'styled-components';

const TRANSITION_DURATION = 1.2; // Transition duration in seconds
const GAP_SIZE = 12; // Gap size between tiles

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${GAP_SIZE}px;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  gap: ${GAP_SIZE}px;
`;

export const Tile = styled.div`
  background: #ffffff30;
  transition: flex ${TRANSITION_DURATION}s ease-in-out;

  border-radius: 10px;
  background-color: ${({ theme, is_row_section_active }: { theme: any; is_row_section_active?: boolean }) =>
    is_row_section_active ? theme.colors.greyPrimary35 : theme.colors.greyPrimary15};
  position: relative; /* Required for the pseudo-element */

  transition: background-color 0.3s ease-in-out;
  // scroll
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme, is_row_section_active }: { theme: any; is_row_section_active?: boolean }) =>
      is_row_section_active ? theme.colors.greyPrimary35 : theme.colors.greyPrimary20};
    transition: background-color 0.3s ease-in-out;
  }

  
`;

export const TileImageWrapper = styled.div<{ imgLink?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 20px;
  padding: 22px;

  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.imgLink});
  background-size: contain;
  background-position: center;

  // the child is getting background shadow
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 37px 30px rgba(0, 0, 0, 1);
  &:hover {
    & > * {
      background-color: none;
      box-shadow: none;
    }
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const HorizontalWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
